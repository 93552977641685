// material
import { Badge, Menu, Box, Stack, AppBar, Toolbar, IconButton, Typography, Divider, Switch } from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useState } from 'react'

import { useLocation, useNavigate } from 'react-router-dom';
import { setUserStandby } from 'src/redux/slices/user';
import { useSelector, useDispatch } from 'src/redux/store';
import { verifyRules } from 'src/utils/functions';

import Logo from 'src/components/Logo';

import { BsFillBarChartFill, BsFillMegaphoneFill, BsClockFill, BsFillGearFill, BsChatTextFill, BsGridFill, BsFillKanbanFill, BsXCircleFill } from "react-icons/bs";
import { MdHelpCenter } from "react-icons/md";
import { FaTasks } from "react-icons/fa";
import { FiMonitor } from "react-icons/fi";

import { RiTeamFill } from "react-icons/ri";
import useAuth from 'src/hooks/useAuth';
import NotificationsPopover from './NotificationsPopover';
import Iconify from 'src/components/Iconify';
import ButtonMenuNavbar from 'src/components/header/ButtonMenuNavbar';

import { setChatInfo } from 'src/redux/slices/chat';
import { setMessages } from 'src/redux/slices/staff';
import Label from 'src/components/Label';
import v2_actionEditarUsuario from 'src/actions/v2/usuarios/v2_actionEditarUsuario';

import { DropdownMenuDemo2 } from 'src/@/components/use/dropdownMenu/DropdownMenu2';
import { DropdownAccountNav } from 'src/@/components/use/dropdownMenu/DropdownAccountNav';

import { Button } from 'src/@/components/ui/button';
import { HelpComponent } from 'src/sections/@global/help';


// ----------------------------------------------------------------------


const APPBAR_MOBILE = 53;
const APPBAR_DESKTOP = 55;


const RootStyle = styled(AppBar)(({ theme }) => ({
  //boxShadow: 1,
  // elevation: 0,
//  backdropFilter: 'blur(6px)',
 // WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  //backgroundColor: '#141a21',
  zIndex: 1,
  backgroundColor: '#fff',
  display: 'flex',
  boxShadow: 'none',
  //boxShadow: '0px 0px 5px 1px rgba(0,0,0,0.)',
  color: '#fff',
  //borderBottom: `solid 1px #e2d9d9`
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({

  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0.1, 3)
  }
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func
};

export default function DashboardNavbar() {
  const { user } = useAuth()
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { roomsWithNewMessages } = useSelector((state) => state.staff);
  const { isUserStandby } = useSelector((state) => state.user);
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const soundNotification = localStorage.getItem('soundNotification');

  let location = useLocation();
  let path = location.pathname.substring(1); // Remove o "/" inicial


  const changeSoundNotification = (value) => {
    localStorage.setItem('soundNotification', value);
    window.location.reload();
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const ItemMenuComponent = ({ icon, titulo, onClick }) => {
    return (
      <Button onClick={onClick} sx={{ backgroundColor: theme => theme.palette.grey[200], p: 2 }}>
        <Stack flexDirection={'row'} alignItems={'center'}>
          {icon}
          <Typography sx={{ ml: 2, color: '#333', fontWeight: '500', fontSize: 12 }}>{titulo}</Typography>
        </Stack>
      </Button>
    );
  }

  async function handleEditStandbyUser(event) {
    let check = event.target.checked;
    dispatch(setUserStandby(check));
    let data = {
      action: "update_standby_users",
      scope: { userStandby: check }
    }
    await v2_actionEditarUsuario(user.id, data)
  }

  const menuItems = [
    {
      title: "Chats",
      icon: <BsChatTextFill size={22} />,
      rule: "chats",
      action: () => navigate('/chats')
    },
    {
      title: "Funil",
      icon: <BsFillKanbanFill size={18} />,
      rule: "funil_vendas",
      action: () => navigate('/dashboard/negocios')
    },
    {
      title: "Dashboard",
      icon: <Iconify icon="ant-design:dashboard-filled" sx={{ width: 22, height: 22 }} />,
      rule: "dashboard",
      action: () => navigate('/dashboard/home')
    },
    {
      title: "Chat Interno",
      icon: <RiTeamFill size={22} />,
      rule: "chat_interno",
      action: () => navigate('/chats/staff')
    },
    {
      title: "Relatórios",
      icon: <BsFillBarChartFill size={22} />,
      rule: "relatorios",
      action: () => navigate('/dashboard/relatorios')
    },
    {
      title: "Ajuda",
      icon: <MdHelpCenter size={22} />,
      rule: null,
      action: () => navigate('/dashboard/configuracoes/suporte')
    },
    {
      title: "Campanhas",
      icon: <BsFillMegaphoneFill size={18} />,
      rule: "campanhas",
      action: () => navigate('/dashboard/campanhas')
    },
    {
      title: "Agendamentos",
      icon: <BsClockFill size={18} />,
      rule: "agendamentos",
      action: () => navigate('/chats/agendamentos')
    },
    {
      title: "Configurações",
      icon: <BsFillGearFill size={18} />,
      rule: "configuracoes",
      action: () => navigate('/dashboard/conta/perfil')
    },
    {
      title: "Supervisionar",
      icon: <FiMonitor size={18} />,
      rule: "supervisionar",
      action: () => navigate('/dashboard/supervisao/lista')
    },
    {
      title: "Chamados",
      icon: <FaTasks size={18} />,
      rule: "chamados",
      action: () => navigate('/dashboard/chamados')
    },
    {
      title: "ZapMassa",
      icon: <FaTasks size={18} />,
      rule: "zapmassa",
      action: () => window.open("https://zapmassa.com.br", '_blank')
    },
  ];


  return (
    <RootStyle className='border-b'>
      <ToolbarStyle>

        <Stack direction={'row'} spacing={1.5}>
          <Logo />
          <Divider orientation="vertical" flexItem />

          <Stack direction="row" alignItems="center" spacing={{ xs: 0.2, sm: 0.2, md: 0.2 }}>

            {/* <MenubarDemo /> */}

            {/* <MenuNavigation /> */}


            {verifyRules(user.rules, "chats") && (
              <ButtonMenuNavbar
                nome="Conversas"
                path={path}
                slug={'chats/chat'}
                to={() => {
                  navigate('/chats');
                  dispatch(setChatInfo(null))
                  dispatch(setMessages([]))
                }}
                badge={0}
                icon={"ph:chats-circle-duotone"}
              />
            )}

            {verifyRules(user.rules, "chamados") && (
              <>
                <ButtonMenuNavbar
                  path={path}
                  slug={'dashboard/negocios'}
                  nome="Funil"
                  to={() => navigate('/dashboard/negocios')}
                  badge={0}
                  icon={"iconamoon:funnel-thin"}
                />
              </>
            )}


            {verifyRules(user.rules, "chat_interno") && (
              <ButtonMenuNavbar
                path={path}
                slug={'chats/staff'}
                nome="Chat Interno"
                to={() => navigate('/chats/staff')}
                badge={roomsWithNewMessages.length}
                icon={"iconoir:group"}
              />
            )}


            {verifyRules(user.rules, "chamados") && (
              <>
                <ButtonMenuNavbar
                  path={path}
                  slug={'dashboard/chamados'}
                  nome="Chamados"
                  to={() => navigate('/dashboard/chamados')}
                  badge={0}
                  icon={"clarity:tasks-line"}
                />
              </>

            )}
            <ButtonMenuNavbar
              path={path}
              slug={'dashboard/contatos'}
              nome="Agenda de contatos"
              to={() => navigate('/dashboard/contatos')}
              badge={0}
              icon={"iconamoon:funnel-thin"}
            />

            {/* <Button
            path={path}
            sx={{pl:2}}
            slug={'dashboard/configuracoes/suporte'}
              aria-controls={openMenu ? 'basic-menu' : undefined}
              size="small"
              onClick={handleClick}
              startIcon={openMenu ? <BsXCircleFill size={16} color="#333" /> : <HiViewGrid size={16} color="#333" />}
            >
              <Typography variant='subtitle2' color="#333">Menu</Typography>
            </Button> */}
          </Stack>
        </Stack>

        <DropdownMenuDemo2 />


        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={openMenu}
          onClose={() => setAnchorEl(null)}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <Box
            sx={{
              m: 1,
              borderRadius: 1,
              p: 1,
              display: 'grid',
              gap: 1,
              gridTemplateColumns: {
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(1, 1fr)',
                md: 'repeat(3, 1fr)',
                lg: 'repeat(3, 1fr)',
              },
            }}
          >
            {menuItems.map(item =>
              (!item.rule || verifyRules(user.rules, item.rule)) && (
                <ItemMenuComponent
                  titulo={item.title}
                  icon={item.icon}
                  onClick={item.action}
                />
              )
            )}
          </Box>
        </Menu>

        <Box sx={{ flexGrow: 1 }} />

        <Stack spacing={1} alignItems={"center"} direction="row" >
          <Label sx={{ backgroundColor: !isUserStandby ? "#d0f3d0" : "#ffd8d8" }} variant='contained' color={isUserStandby ? "error" : "success"} >
            <Stack direction={'row'} alignItems={"center"} spacing={1}>
              {/* <Iconify icon={'fluent:channel-share-28-filled'} width={10} height={10} /> */}
              <Typography sx={{ textTransform: 'uppercase', fontSize: 8 }}> {isUserStandby ? "Intervalo" : "Online"}</Typography>
              <Switch onClick={handleEditStandbyUser} color={isUserStandby ? "error" : "success"} checked={isUserStandby} size="small" />
            </Stack>
          </Label>

          <Stack direction={"row"} spacing={0}>
          {soundNotification === "true" ?
            <Button
              size="icon"
              variant="ghost"
              onClick={() => changeSoundNotification("false")} >
              <Iconify
                color="black"
                icon="lets-icons:sound-max-fill"
              />
            </Button>
            :
            <Button
              size="icon"
              variant="ghost"
              onClick={() => changeSoundNotification("true")}>
              <Iconify color={"black"} icon="akar-icons:sound-off" />
            </Button>
          }
          <NotificationsPopover />
          <HelpComponent />
          </Stack>
          <Divider sx={{ borderStyle: 'dashed' }} orientation="vertical" flexItem />
          <DropdownAccountNav />
          {/* <AccountPopover /> */}
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}

<Button size="icon" className="text-gray-900" variant='outline'>
  <Iconify color="black" icon="mdi:account-outline" />
</Button>
