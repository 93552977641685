import React, {useState} from 'react';

import "./Create.css";
import {
    getListaStaffsWithoutIsGroup,
    getStaff,
    setPanel
  } from 'src/redux/slices/staff';

import editGroup from "../../../../../../actions/chats/staff/editGroup";

import {useDispatch, useSelector} from "react-redux";



import { PATH_CHAT } from '../../../../../../routes/paths';

import {useNavigate} from "react-router-dom";
import {Button, CircularProgress, TextField, Box} from '@mui/material';

const EditGroup2 = ({chat}) => {
    const dispatch = useDispatch();
    /* eslint-disable no-unused-vars */
    const {newGroupUsers, search, groupTitle, searchResults} = useSelector((state) => state.staff); 
    const title = groupTitle
    /* eslint-enable no-unused-vars */
    

    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const edit = async e => {
        setLoading(true);
        e.preventDefault();
       // if (!title || title.length === 0) return setError(true);
        setError(null);
        const res = await editGroup({people: newGroupUsers, nome:title, groupId:chat._id});
        dispatch(setPanel('standard'));
        navigate(PATH_CHAT.staff.root);
        dispatch(getListaStaffsWithoutIsGroup())
        dispatch(getStaff(res.data._id));
        navigate(`${PATH_CHAT.staff.root}/${res.data._id}`);
    
      
    }

    return (
        <Box sx={{p:1}}>

        
            <Button fullWidth  disabled={loading ? true : false} variant="contained" onClick={edit}>{loading ? <CircularProgress size={15} /> : "Concluir"}</Button>
            
            <div className="selection-text error" hidden={!error}>
                Nome do grupo é obrigatório!
            </div>
            {/* <input
                className="file-input"
                type="file"
                ref={fileInput}
                accept="image/*"
                onChange={e => changePicture(e.target.files[0])}
            />
            <div style={{marginTop: 15}} className="picture" onClick={() => fileInput && fileInput.current && fileInput.current.click()}>
                <GroupPicture picture={groupPicture} />
                <div className="overlay">
                    <div className="text"><FiEdit2/></div>
                </div>
            </div> */}
            <TextField fullWidth sx={{mt:3}} className="group-name" type="text" label="Nome do Grupo" placeholder="Crie um nome ..." value={title} onChange={e => setTitle(e.target.value)} />
        </Box>
    );
}

export default EditGroup2;
