import { FormControl, InputBase, List, MenuItem, Select, Stack, Typography, styled } from '@mui/material';
import PropTypes from 'prop-types';
import { useLayoutEffect, useRef, useState } from 'react';
import getActionMarcarNaoLido from 'src/actions/chats/getActionMarcarNaoLido';

import {
  getChatsAtendimento,
  setSelectedMessage,
  getCountsChats,
  getMensagensWhatsappDb,
  setChatInfo,
  resetMensagensEnviar,
  setPushChatList,
} from 'src/redux/slices/chat';
import { useDispatch, useSelector } from 'src/redux/store';
import ChatAtendimentoItem from 'src/sections/@chats/chat/ChatsListItem/ChatAtendimentoItem';
import Iconify from 'src/components/Iconify';
import v2_actionFinalizarMultiplosChats from 'src/actions/v2/chats/v2_actionFinalizarMultiplosChats';
import { useSnackbar } from 'notistack';
import { verifyRules } from 'src/utils/functions';
import getActionMensagemLida from 'src/actions/chats/getActionMensagemLida';
import useAuth from 'src/hooks/useAuth';

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "src/@/components/ui/accordion";
import { ButtonLoading } from 'src/@/components/use/ButtonLoading';
import { Checkbox } from "src/@/components/ui/checkbox";

import { Button } from 'src/@/components/ui/button';
import v2_actionListaByStatus from 'src/actions/v2/services/chats/v2_actionListaByStatus';

// eslint-disable-next-line no-unused-vars
export default function ChatConversationList({
  cliente,
  isOpenSidebar,
  listaFunilReduce,
  listaTagsReduce,
  tagsSelecionadas,
  funisSelecionadas,
  dataFiltered,
  changeTags,
  changeFunis,
  expanded,
  changeAccordion,
  limparFiltro,
  isFiltered,
  chatsNaoLidos,
  changeChatsNaoLidos,
  ...other
}) {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { user } = useAuth();
  const { loadingChat } = useSelector((state) => state.chat);
  const [loadingLimparChats, setLoadingLimparChats] = useState(false);
  const [visibleItems, setVisibleItems] = useState(10);
  const listRef = useRef(null);
  const [listaMoreChats, setListaMoreChats] = useState([]);

  const mergeChats = dataFiltered?.concat(listaMoreChats);

  async function getMoreChats(limit, offset) {
    console.log('offset'  , offset);
    console.log('limit'  , limit);

    try {
      
      const result = await v2_actionListaByStatus(user.clienteId, user.id, "Atendendo", user.setorId, limit, offset);
      if(result.status === 200) {
        setListaMoreChats(prev => [...prev, ...result.data.data.chats]);
      }
    
    } catch (error) {
      console.log(error);
    }
    
  }

  const loadMoreItems = () => {
    setVisibleItems((prev) => {
      console.log('loadMoreItems 1', dataFiltered.length);
      console.log('loadMoreItems prev', prev);
  
      const newVisibleItems = prev + 10;
  
      if (newVisibleItems >= 100 && (newVisibleItems - 100) % 20 === 0) {
        console.log('loadMoreItems 2', newVisibleItems);
        const offset = newVisibleItems;
  
        getMoreChats(20, offset);
      }
  
  
      return newVisibleItems;
    });
  };

  const scrollPosition = useRef(0);

  // Captura a posição do scroll antes de atualizar a lista
  useLayoutEffect(() => {
    const listElement = listRef.current;
    if (listElement) {
      scrollPosition.current = listElement.scrollTop;
    }
  }, [dataFiltered]);

  // Restaura a posição do scroll logo após a atualização do DOM
  useLayoutEffect(() => {
    const listElement = listRef.current;
    if (listElement && scrollPosition.current !== 0) {
      listElement.scrollTop = scrollPosition.current;
    }
  }, [dataFiltered]);

  async function limparChats() {
    try {
      let data = {
        usuarioId: user.id,
        status: 'Atendendo',
        clienteId: cliente._id,
      };
      setLoadingLimparChats(true);
      const result = await v2_actionFinalizarMultiplosChats(data);
      if (!result.data.error) {
        dispatch(getChatsAtendimento(user.clienteId, user.id, user.setorId, 10, true));
        dispatch(getCountsChats(user.clienteId, user.id, user.setorId));
      }
      setLoadingLimparChats(false);
      enqueueSnackbar('Chats finalizados', { variant: 'success' });
    } catch (error) {
      setLoadingLimparChats(false);
      console.log(error);
      enqueueSnackbar('Erro ao limpar chats', { variant: 'error' });
    }
  }

  const handleMarcarNaoLido = (chat) => {
    getActionMarcarNaoLido({ chatId: chat._id })
      .then(() => {
        dispatch(getChatsAtendimento(user.clienteId, user.id, user.setorId, 10, true));
      })
      .catch((err) => console.log('err', err));
  };

  const openWhatsappChat = async (chat) => {
    getActionMensagemLida({ chatId: chat._id });
    dispatch(setChatInfo(chat));
    dispatch(getMensagensWhatsappDb(chat.sessao, chat.numeroCliente));

    setTimeout(() => {
      dispatch(getChatsAtendimento(user.clienteId, user.id, user.setorId, 10, false));
    }, 600);
    dispatch(setSelectedMessage({}));
  };

  return (

    <List ref={listRef}>

        <Accordion type="single" collapsible className="w-full">
          <AccordionItem value="item-1">
            <AccordionTrigger>
              <Stack direction={"row"} alignItems={"center"}>
                <Iconify icon="mdi:filter-outline" width={18} />
                Filtros
              </Stack>
              {isFiltered && (
                <Button
                  onClick={limparFiltro}
                  sx={{ width: '50%', height: 25 }}
                  size="small"
                  color="info"
                  startIcon={<Iconify width={10} icon="zondicons:close-solid" />}
                >
                  Limpar filtro
                </Button>
              )}
            </AccordionTrigger>
            <AccordionContent>
              <Stack alignItems={'center'} direction="column" spacing={2}>
                <FormControl sx={{ width: '100%' }}>
                  <Stack alignItems={'center'} spacing={2} direction={'row'}>
                    <Typography variant='caption'>Tag</Typography>
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      multiple
                      fullWidth
                      size="small"
                      value={tagsSelecionadas}
                      onChange={changeTags}
                      renderValue={(selected) => selected.join(', ')}
                      input={<BootstrapInput />}
                    >
                      {listaTagsReduce.map((name) => (
                        <MenuItem key={name} value={name}>
                          <div className="flex items-center space-x-2">
                            <Checkbox checked={tagsSelecionadas.indexOf(name) > -1} />
                            <label
                              htmlFor="terms"
                              className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                            >
                              {name}
                            </label>
                          </div>
                        </MenuItem>
                      ))}
                    </Select>
                  </Stack>
                </FormControl>

                <FormControl sx={{ width: '100%' }}>
                  <Stack alignItems={'center'} spacing={2} direction={'row'}>
                    <Typography variant='caption'>Chat</Typography>
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      multiple
                      fullWidth
                      size="small"
                      value={chatsNaoLidos}
                      onChange={changeChatsNaoLidos}
                      input={<BootstrapInput />}
                      renderValue={(selected) => selected.join(', ')}
                    >
                      {["Chats não lidos", "Chats lidos"].map((name) => (
                        <MenuItem key={name} value={name}>
                          <div className="flex items-center space-x-2">
                            <Checkbox checked={chatsNaoLidos.indexOf(name) > -1} />
                            <Typography>{name}</Typography>
                          </div>
                        </MenuItem>
                      ))}
                    </Select>
                  </Stack>
                </FormControl>

                {verifyRules(user.rules, "finalizar_chat_massa") && (
                  <ButtonLoading
                    className="w-full bg-red-100"
                    variant='secondary'
                    onClick={limparChats}
                    loading={loadingLimparChats}
                  >
                    <Iconify icon="icon-park-solid:clear-format" />
                    Finalizar chats em massa
                  </ButtonLoading>
                )}
              </Stack>
            </AccordionContent>
          </AccordionItem>
        </Accordion>

        {mergeChats.slice(0, visibleItems).map((chat) => (
          <ChatAtendimentoItem
            disabled={loadingChat ? true : false}
            key={chat._id}
            chat={chat}
            isOpenSidebar={isOpenSidebar}
            onSelectConversation={() => openWhatsappChat(chat)}
            actionMarcarNaoLido={() => handleMarcarNaoLido(chat)}
          />
        ))}

        {visibleItems < mergeChats.length && (
          <Button className="w-full" onClick={loadMoreItems} fullWidth>
            Carregar Mais
          </Button>
        )}
    </List>

  );
}

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(1.5),
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 10,
    padding: '4px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
  },
}));
