import {
    Box,
    Grid,
    Typography,
    Stack,
    Button,
    Switch,
    IconButton,
    Divider,
    Container
} from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';


import Documents from '../agentesIa/components/training/views';
import { HeaderPageComponentConfig } from 'src/components/_dashboard/configuracoes';
import DocumentStoreDetails from '../agentesIa/components/training/views/DocumentStoreDetail';

// ----------------------------------------------------------------------

BaseDadosAi.propTypes = {
    canais: PropTypes.array,
};

export default function BaseDadosAi() {

    const [label, setLabel] = useState('');

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const code = queryParams.get('database_id');
    
      }, [location]);
    

    return (
        <Container maxWidth="lg">
    
          <HeaderPageComponentConfig
            titulo="Base de Dados de IA"
            descricao={'Gere, treine e gerencie os dados de IA'}
          //  actions={<Stack spacing={1} direction={"row"}>
          //   <Button onClick={() => setOpenTemplates(true)} startIcon={<Iconify icon="fluent:calendar-template-20-filled" />} variant="outlined">templates</Button>
          //   <Button onClick={() => setOpenCriarFlow(true)} startIcon={<Iconify icon="fluent:bot-sparkle-24-filled" />} variant='contained'>novo fluxo</Button>
          //   </Stack>}
          />
          <Divider sx={{my:3, borderStyle:'dashed'}} />
          <Box sx={{ flex: 1, px: 3 }}>

          <Documents />
        
    
          </Box>
        </Container>
    );
}

