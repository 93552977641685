import React from 'react';
import { Container, Box, TextField, Button, Typography, Link, Stack, Card, Divider, IconButton, FormControlLabel, Checkbox } from '@mui/material';
import { styled } from '@mui/material/styles';
import bgLogin from 'src/assets/bg-login.png'
import logo from 'src/assets/logos/logo-lais.png'
import { RowInput } from 'src/components/forms/RowInput'
import InputStyle from 'src/components/InputStyle'
import Iconify from 'src/components/Iconify';
import useAuth from 'src/hooks/useAuth';

////

import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from "react-router-dom";
// hooks
import { LoadingButton } from '@mui/lab';

const Root = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  height: '100%',
  backgroundColor: theme.palette.primary.main, // Fundo vermelho
}));

const ImageContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.primary.main,
  padding: '20px',
  width: '100%',
}));

const FormContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  height: '100vdh',
  width: '30%',
  backgroundColor: '#fff',
  borderTopLeftRadius: 30,
  borderBottomLeftRadius: 30,
  boxShadow: theme.shadows[5],
  maxWidth: '600px',
  minWidth: '600px',
  padding: 50,

}));

const Logo = styled(Box)({
  width: '100%',
  marginBottom: '16px', // ou use o valor de espaçamento do seu tema
  display: 'flex',
  justifyContent: 'center',
});

const Input = styled(TextField)({
  marginBottom: '16px', // ou use o valor de espaçamento do seu tema
  width: '100%',
});

const SubmitButton = styled(LoadingButton)({
  marginTop: '16px', // ou use o valor de espaçamento do seu tema
  width: '100%',
  color: "#fff",
  height: 50,
  boxShadow: 'none',
  textTransform: 'none'
});

const LinkContainer = styled(Stack)({
  display: 'flex',
  justifyContent: 'space-around',
  width: '100%',
  marginTop: '16px', // ou use o valor de espaçamento do seu tema
});

const LinkContainerStyle = styled(Button)({
  padding: 10,
  width: '100%',
  //backgroundColor: '#faf9f9',
  border: '0.5px solid #f0f0f0',
  borderRadius: 10
});

const LinkStyle = ({ title, icon, onClick }) => {
  return (
    <LinkContainerStyle onClick={onClick}>
      <Stack
        justifyContent={"center"}
        alignContent={"center"}
        alignItems={"center"}
        direction="row"
        spacing={0.5}
      >
        <Iconify
          icon={icon}
          width={25}
          height={25}
          color="#aaa"
        />
        <Typography sx={{ fontSize: 12 }} color="textSecondary">
          {title}
        </Typography>
      </Stack>
    </LinkContainerStyle>
  )
}

const Login2 = () => {
  const navigate = useNavigate();
  const { login } = useAuth();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState('');
  const [senha, setSenha] = useState('');
  const [checkManterLogado, setCheckManterLogado] = React.useState(false);


  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const handleKeyUp = (event) => {
    if (!event.shiftKey && event.key === 'Enter') {
      onLogin();
    }
  };


  useEffect(() => {
    const storedCredenciais = JSON.parse(localStorage.getItem('credenciais'));

    if (storedCredenciais) {
      setEmail(storedCredenciais.email);
      setSenha(storedCredenciais.password);
      setCheckManterLogado(storedCredenciais.checkManterLogado)
    }
  }, []);


  async function onLogin() {
    setLoading(true);

    try {
      const resLogin = await login(email, senha, checkManterLogado);

      if (resLogin.data.password === 'Wrong password.') {
        setLoading(false);
        enqueueSnackbar('Sennha Incorreta', {
          variant: 'error',
          action: (key) => (
            <IconButton size="small" onClick={() => closeSnackbar(key)}>
              <Iconify icon="mdi:close" />
            </IconButton>
          )
        });
      }


    }
    catch (e) {
      setLoading(false);
      enqueueSnackbar('Sennha Incorreta', {
        variant: 'error',
        action: (key) => (
          <IconButton size="small" onClick={() => closeSnackbar(key)}>
            <Iconify icon="mdi:close" />
          </IconButton>
        )
      });
      console.log(e)
    }
  };

  return (
    <Root>
      <Stack sx={{ height: '100vh' }} direction="row">
        {/* <ImageContainer>
          <img src={bgLogin} alt="Lais" style={{ maxWidth: '100%', height: 'auto' }} />
        </ImageContainer> */}
        <FormContainer>
          <Stack spacing={3} direction={"column"}>
            <Stack direction={"column"} spacing={2}>
              {/* <Logo>
                <img src={logo} alt="Lais" style={{ width: '150px' }} />
              </Logo> */}

              <Typography sx={{ fontWeight: '400', fontSize: 12 }} gutterBottom>
                Faça o login para acessar a plataforma.
              </Typography>

            </Stack>


  
         <Stack direction={"column"} spacing={1}>
            <Stack spacing={2} direction={"column"} sx={{ mt: 3, width: '100%' }}>
              <RowInput
                label={'email'}
                input={<InputStyle
                  fullWidth
                  value={email}
                  onKeyPress={handleKeyUp}
                  autoComplete="email"
                  type="email"
                  remember={true}
                  onChange={event => setEmail(event.target.value)}

                />}
              />
              <RowInput
                label={'senha'}
                input={<InputStyle
                  fullWidth
                  value={senha}
                  onKeyPress={handleKeyUp}
                  autoComplete="current-password"
                  type={showPassword ? 'text' : 'password'}
                  onChange={event => setSenha(event.target.value)}
                />}
              />
            </Stack>
            

            <Stack sx={{ mt: 0.5 }} alignItems={'center'} justifyContent={'space-between'} direction="row">
              <FormControlLabel
                sx={{ fontSize: 10, fontWeight: '500' }}
                control={<Checkbox
                  checked={checkManterLogado}
                  onChange={event => setCheckManterLogado(event.target.checked)}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
                }
                label={<Typography sx={{ fontSize: 12 }} >Lembrar senha</Typography>}
              />
            </Stack>
            </Stack>

            <Stack spacing={3} direction="column">
              <SubmitButton onClick={onLogin} loading={loading} variant="contained" color="success">
                entrar na plataforma
              </SubmitButton>
              <Divider sx={{ borderStyle: 'dashed' }} />
              <LinkContainer direction={"row"} spacing={1.5}>
                <LinkStyle onClick={() => navigate('/auth/reset-password')} title="Esqueceu a senha?" icon="jam:help-f" />
                {/* <LinkStyle title="Suporte" icon="fluent:person-support-28-regular" /> */}
              </LinkContainer>


            </Stack>

          </Stack>
        </FormContainer>
      </Stack>
    </Root>
  );
};


export default Login2;
