import { List } from '@mui/material';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import getActionDeletarGrupo from 'src/actions/chats/getActionDeletarGrupo';

import { getStaff, getListaStaffsWithoutIsGroup } from '../../../redux/slices/staff';
import { useDispatch, useSelector } from '../../../redux/store';
import { PATH_CHAT } from '../../../routes/paths';
import ChatStaffItem from './ChatStaffItem';
//
ChatConversationList.propTypes = {
  isOpenSidebar: PropTypes.bool,
  activeConversationId: PropTypes.string
};

const Contacts = ({
  chats,
  user,
  onlineUsers,
  roomsWithNewMessages,
  isOpenSidebar,
  onSelectConversation,
  deletarGrupo
}) => {
  console.log('chats',chats)
  return (
    <div className="contacts p-2 flex-1 overflow-y-scroll">
      {chats?.map((chat, index) => (
        <ChatStaffItem 
                  key={chat._id}
          chat={chat}
          user={user}
          onlineUsers={onlineUsers}
          deletarGrupo={() => handleDeletarGrupo(chat)}
          roomsWithNewMessages={roomsWithNewMessages}
          isOpenSidebar={isOpenSidebar}     
         // isSelected={activeConversationId === conversationId}
           onSelectConversation={() => onSelectConversation(chat)}
        />
      ))}
    </div>
  );
};

export default function ChatConversationList({ chats, user, isOpenSidebar, ...other }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { roomsWithNewMessages,  onlineUsers } = useSelector((state) => state.staff);


  const handleChoose = (chat) => {
      const target = `/chats/interno/${chat._id}`;
      navigate(target);
      dispatch(getStaff(chat._id))
  }


  const handleDeletarGrupo = (chat) => {
    getActionDeletarGrupo({chatId:chat._id}).then(() => {
      dispatch(getListaStaffsWithoutIsGroup())
    })
  }

 
return (
  <Contacts 
  chats={chats} 
  user={user}
  onlineUsers={onlineUsers}
  roomsWithNewMessages={roomsWithNewMessages}
  isOpenSidebar={isOpenSidebar}
  onSelectConversation={handleChoose}
  deletarGrupo={handleDeletarGrupo}

  />
)

  // return (
  //   <List {...other}>
  //     {chats.map((chat) => (
    
  //       <ChatStaffItem
  //         key={chat._id}
  //         chat={chat}
  //         user={user}
  //         onlineUsers={onlineUsers}
  //         deletarGrupo={() => handleDeletarGrupo(chat)}
  //         roomsWithNewMessages={roomsWithNewMessages}
  //         isOpenSidebar={isOpenSidebar}     
  //        // isSelected={activeConversationId === conversationId}
  //          onSelectConversation={() => handleChoose(chat)}
  //       />
  //     ))}
  //   </List>
  // );
}
