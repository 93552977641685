import React, {useState, useRef, useEffect} from 'react';
import { Lightbox } from "react-modal-image";

import Config from "../../../../config";

import './Messages.css';
import { useSelector} from "react-redux";

import Message from "./Message";
import useAuth from 'src/hooks/useAuth';
//import getMoreMessages from "../../../actions/getMoreMessages";

const Messages = () => {
    const {user} = useAuth();
    //const messages = useSelector(state => state.io.messages) || [];

    const [loading, setLoading] = useState(false);

    const { mensagensStaff, staff } = useSelector((state) => state.staff);
    const mensagens = mensagensStaff;
    const chat = useRef(null);

    const [open, setOpen] = useState(null);
 
    // eslint-disable-next-line no-unused-vars
    let other = {
        firstName: 'A', lastName: 'A'
    };

    if (!staff.isGroup && staff.people) {
        staff.people.forEach(person => {
            if (person._id !== user.id) other = person;
        });
    }

    const Messages = mensagens.map((message, index) => {
        return <Message
            key={index}
            message={message}
            previous={mensagens[index - 1]}
            next={mensagens[index + 1]}
            onOpen={setOpen}
        />;
    });

    const onScroll = () => {
        if (chat.current.scrollTop === 0) {
            if (loading) return;
            setLoading(true);
            // getMoreMessages({roomID: room._id, firstMessageID: messages[0]._id}).then(res => {
            //     console.log(res.data.messages)
            //     // dispatch({type: Actions.MORE_MESSAGES, messages: res.data.messages});
            //     setLoading(false);
            // }).catch(err => {
            //     setLoading(false);
            // });
        }
    };


    useEffect(() => {
        chat.current.scrollTop = chat.current.scrollHeight;
    }, [mensagens.length]);

    return (
        <div className="messages-wrapper" ref={chat} onScroll={onScroll}>
            <div className="messages-container">
                {open && (
                    <Lightbox
                        medium={`${Config.urlWs || ''}/api/files/${open.content}`}
                        large={`${Config.urlWs || ''}/api/files/${open.content}`}
                        alt="Lightbox"
                        hideDownload={false}
                        onClose={() => setOpen(null)}
                    />
                )}
                {Messages}
            </div>
        </div>
    );
};

export default Messages;
