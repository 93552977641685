import { LoadingButton } from '@mui/lab';
import {
    Stack,
    Typography,
    Container,
    Box,
    Grid,
    Divider,
    LinearProgress
} from '@mui/material';
import { set } from 'lodash';
import { enqueueSnackbar, useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, AlertDescription, AlertTitle } from 'src/@/components/ui/alert';
import { Card, CardContent, CardTitle, CardHeader, CardDescription } from 'src/@/components/ui/card';
import { ButtonLoading } from 'src/@/components/use/ButtonLoading';
import actionStatusConnectionWhatsapp from 'src/actions/clientes/actionCheckConnectionWhatsapp';
import actionFecharSessaoWhatsapp from 'src/actions/clientes/actionFecharSessaoWhatsapp';
import actionStartSessionWhatsapp from 'src/actions/clientes/actionStartSessionWhatsapp';
import actionCheckConnectionWhatsapp from 'src/actions/clientes/actionStatusConnectionWhatsapp';
import { RowInput } from 'src/components/forms/RowInput';
import Iconify from 'src/components/Iconify';
import { setLoadingQrcodeWhatsapp, setQrcode, setStatusQrcode } from 'src/redux/slices/global';
// @mui

DialogWhatsappConexao.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func,
};

export default function DialogWhatsappConexao({ channel }) {
    const { enqueueSnackbar } = useSnackbar();
    const { qrcode, loadingQrcodeWhatsapp, statusQrcodeRead } = useSelector((state) => state.global);
    //const [isSessionStart, setIsSessionStart] = useState(null)
    let isSessionStart;

    const [seconds, setSeconds] = useState(60);
    const [isRunning, setIsRunning] = useState(false);
    const dispatch = useDispatch();
    const [isCounting, setIsCounting] = useState(false); // Estado para controlar se a contagem está ativa



    useEffect(() => {

        if (statusQrcodeRead === 'qrReadSuccess') {
        }

        if (statusQrcodeRead === 'qrReadError') {
            dispatch(setQrcode(null));
        }

        if (qrcode) {
            setIsCounting(true);
        }

    }, [statusQrcodeRead, qrcode]);

    async function closeSessionWhatsapp() {
        try {
            await actionFecharSessaoWhatsapp(`?sessao=${channel?.sessao}&canal=whatsapp`);
        } catch (error) {
            
        }
    }

    useEffect(() => {
        let timer;
        if (isCounting && seconds > 0) {
            timer = setInterval(() => {
                setSeconds(prevSeconds => prevSeconds - 1);
            }, 1000);
        } else if (seconds === 0) {
            setIsCounting(false);
            dispatch(setQrcode(null));
            setIsRunning(true);
            setSeconds(60);
            dispatch(setLoadingQrcodeWhatsapp(false));
            closeSessionWhatsapp();
        }
        return () => clearInterval(timer);
    }, [isCounting, seconds]);



    async function statusSessionWhatsapp() {
        try {
            let querys = `?sessao=${channel?.sessao}&canal=whatsapp`;
            const response = await actionStatusConnectionWhatsapp(querys);
            console.log(response.data.data.qrcode)

            if (response.data.data.status === 'QRCODE') {
                dispatch(setStatusQrcode('notLogged'));
                dispatch(setQrcode(response.data.data.qrcode));
                dispatch(setLoadingQrcodeWhatsapp(false));

            } else if (response.data.data.status === 'CONNECTED') {
                window.location.href = "/chats/chat"
            } if (response.data.data.status === 'CLOSED') {
                console.log('sessão encerrada')
            }
            return response.data.data.status

        } catch (error) {

        }
    }


    async function startSessionWhatsapp() {
        try {
            // setQrcode(null);
            // setLoadingQrcodeWhtsapp(true);
            dispatch(setLoadingQrcodeWhatsapp(true))

            let querys = `?sessao=${channel?.sessao}&canal=whatsapp&deletar_sessao=1&fechar_sessao=1`;
            const response = await actionCheckConnectionWhatsapp(querys);
            setIsRunning(true);

            // if (response.data.data.status) {
            //     return alert('O número já está em uso');
            // }

            try {
                let data = {
                    "webhook": null,
                    "waitQrCode": true,
                    "closeSession": true
                };
                await actionStartSessionWhatsapp(querys, data);
                isSessionStart = 1;

                setTimeout(() => {
                    dispatch(setQrcode(null));
                    setIsRunning(true);
                    setSeconds(60);
                    dispatch(setLoadingQrcodeWhatsapp(false))
                }, 120000);


            } catch (error) {
                dispatch(setLoadingQrcodeWhatsapp(false))
                enqueueSnackbar('Erro ao iniciar sessão, tente novamente', { variant: 'error' });
                console.log(error);
            }

        } catch (error) {
            console.log(error);
            alert('Erro ao iniciar sessão, se persistir contate o suporte');
        }
    }


    return (

        <>

            <Stack direction="row" spacing={2}>
                <Stack className='w-full'>
                    <Card>
                        <CardHeader>
                            <CardTitle>   {channel?.nome}</CardTitle>
                            <CardDescription className="pt-2">Assita a nossa aula <a href="https://rebrand.ly/colhendoqrcode" target="_blank" rel="noreferrer" className="text-violet-900 font-bold">clicando aqui</a> e siga os passos abaixo para conectar seu whatsapp.
                            </CardDescription>
                        </CardHeader>
                        <CardContent>
                            <ul className="my-3 ml-6 list-disc [&>li]:mt-1 mb-3">
                                <li>Abra seu aplicativo whatsapp</li>
                                <li>Abra o leitor de qrcode</li>
                                <li>Clique no botao e aguarde o qrcode na tela</li>
                                <li>Scaneie o Qrcode e aguarde</li>
                            </ul>
                            <Alert className="mt-3">
                              <Iconify icon="mdi:alert-circle" color="red" />
                                <AlertTitle>Atenção</AlertTitle>
                                <AlertDescription>
                                    O Qrcode expira em 60 segundos, caso não consiga ler, clique em gerar qrcode novamente.
                                </AlertDescription>
                            </Alert>

                            <Stack className='w-full mt-10' justifyContent={"center"}>
                                {statusQrcodeRead !== 'notLogged' && (
                                    statusQrcodeRead === 'qrReadSuccess' && (
                                        <Box sx={{ width: '50%' }}>
                                            <LinearProgress />
                                            <Typography variant='caption'>leitura feita com sucesso, carregando mensagens...</Typography>
                                        </Box>
                                    )
                                )}
                                <Stack direction={"column"} justifyContent={"center"} spacing={2}>

                                    {isCounting && (
                                        <small className="text-sm font-medium leading-none">Qrcode expira em {seconds} segundos</small>

                                    )}
                                    <Box sx={{ display: 'grid' }}>
                                        {!qrcode ? (
                                            <Box sx={{ height: 200, justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                                                <ButtonLoading loading={loadingQrcodeWhatsapp} onClick={() => startSessionWhatsapp()} color="primary">Gerar Qrcode </ButtonLoading>
                                            </Box>
                                        ) : (
                                            statusQrcodeRead === "notLogged" && qrcode && (
                                                <img
                                                    style={{ height: 250, width: 250 }}
                                                    src={`${qrcode}`}
                                                    loading={"lazy"}

                                                />
                                            )
                                        )}
                                    </Box>

                                </Stack>

                            </Stack>
                        </CardContent>

                    </Card>

                </Stack>

            </Stack>



        </>
    );
}


