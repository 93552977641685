// material ui
import { MenuItem, IconButton, Menu, Typography, Box, Stack, Divider, Tooltip, CircularProgress, LinearProgress, Alert, Icon, Link } from "@mui/material"

import PropTypes from "prop-types";
import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
//slices
import { getMensagensWhatsappDb } from 'src/redux/slices/chat'
//utils
import { soNumeros, verifyRules } from '../../../../../../utils/functions';
import { AlertDialogLoading } from "../AlertDialogLoading";
import AudioComponent from "../AudioComponent";
import LocationPreview from "../LocationPreview";
import MarkdownWrapper from './MarkdownWrapper';
import { QuotedMessage } from "./QuotedMessage";
import {
  ChatLayout,
  DocumentComponent,
  ImageContainer,
  MessageContainer,
  MessageContent,
  MessageContentText,
} from "./style";
import ModalEncaminharMensagem from "../ModalEncaminharMensagem";
import useAuth from "src/hooks/useAuth";
import { downloadFromBase64 } from "src/utils/helpers";
import Iconify from "src/components/Iconify";

import MenuPopover from "src/components/MenuPopover";
import { useSnackbar } from "notistack";
import SkeletonChatImage from "src/components/skeleton/chats/SkeletonChatImage";
import moment from "moment";
const defaultImage = "https://zapchat.nyc3.digitaloceanspaces.com/images/not-picture.png";

import { Button } from "src/@/components/ui/button";

//actions
import v1_actionDownloadMediaWhatsapp from "src/actions/v1/whatsapp/v1_actionDownloadMediaWhatsapp";
import v1_actionDeletarMensagemWhatsapp from "src/actions/v1/whatsapp/v1_actionDeletarMensagemWhatsapp";
import v1_actionEncaminharMensagemWhatsapp from "src/actions/v1/whatsapp/v1_actionEncaminharMensagemWhatsapp";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuPortal,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from "src/@/components/ui/dropdown-menu"
import Scrollbar from "src/components/Scrollbar";
import { ZuiTooltip } from "src/@/components/use/tooltip";
const mimeTypesImages = ["image/jpeg", "image/png", "image/jpg", "image/gif", "image/webp"];

const ChatComponent = ({
  message,
  config,
  chat,
  isMe,
  isWarning,
  selectMessageId,
  callbackGpt,
  isDesktop,
  key,
  onCallbakOpenModal,
  onDeleteNote
}) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { user, cliente } = useAuth()
  const imageRef = useRef(null);
  const audioRef = useRef(null);
  const [audioUrl, setAudioUrl] = useState(undefined);
  const [display, setDisplay] = useState("block");
  const [openModalImage, setOpenModalImage] = useState(false);
  const textRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openEncaminharDialog, setOpenEncaminharDialog] = useState(false);
  const [loadingDeletarMensagem, setLoadingDeletarMensagem] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [loadingDownloadVideo, setLoadingDownloadVideo] = useState(false);
  const [loadingDownloadVideoId, setLoadingDownloadVideoId] = useState(null);
  const [isDownloadImage, setIsDownloadImage] = useState(false);
  const typesIgnore = ["notification_template", "e2e_notification", "call_log", "gp2"];
  const [openMenu, setOpenMenuActions] = useState(null);
  const idMenu = openMenu ? 'simple-popover' : undefined;

  const [loadingEncaminharMensagem, setLoadingEncaminharMensagem] = useState(null);



  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };


  const handleClose = () => {
    setAnchorEl(null);
  };

  function validaChatId(val) {
    if (typeof val.chatId === 'string') {
      return soNumeros(val.chatId);
    } else {
      return val.chatId.user;
    }
  }


  const excluirMensagem = async (message) => {
    try {
      const querys = `?sessao=${chat.sessao}&canal=whatsapp`;
      let data = { phone: validaChatId(message), messageId: message.id }
      await v1_actionDeletarMensagemWhatsapp(querys, data)
      dispatch(getMensagensWhatsappDb(chat.sessao, chat.numeroCliente));
    } catch (error) {
      console.log('Erro ao apagar mensagem do Chat', error)
    }
  };

  async function encaminharMesagemWhatsapp(phone) {
    try {
      setLoadingEncaminharMensagem(true);
      let data = {
        phone: phone,
        messageId: message.id
      };
      const querys = `?sessao=${chat.sessao}&canal=whatsapp`;
      await v1_actionEncaminharMensagemWhatsapp(querys, data);
      setLoadingEncaminharMensagem(false);
      setOpenEncaminharDialog(false)

      enqueueSnackbar('Mensagem encaminhada com sucesso', { variant: 'success' });

    } catch (error) {
      enqueueSnackbar('Erro ao encaminhar mensagem', { variant: 'error' });
      setLoadingEncaminharMensagem(false);
      console.log(error);
    }
  }

  async function baixarMidiaDevice() {
    console.log('baixando midia device')
    let querys = `?sessao=${chat.sessao}&msgId=${message.id}&canal=whatsapp`
    const response = await v1_actionDownloadMediaWhatsapp(querys);
    const a = document.createElement("a");
    console.log('response', response.data.data.base64)
    a.href = `data:${message.mimetype};base64, ${response.data.data.base64}`;
    a.download = `lais_file-${moment(new Date()).format("YYYY-MM-DD:HH:mm")}`;
    a.click();
    ///downloadFromBase64(response.data.data.base64, `lais_${(new Date).toISOString()}`, validaAudio())
  }

  async function baixarAudioDevice() {
    function validaAudio() {
      if (message.type === "audio" || message.type === "ptt") {
        return 'audio/mp3';
      } else {
        return message.mimetype;
      }
    }
    let querys = `?sessao=${chat.sessao}&msgId=${message.id}&canal=whatsapp`
    const response = await v1_actionDownloadMediaWhatsapp(querys);
    downloadFromBase64(response.data.data.base64, `lais_file-${moment(new Date()).format("YYYY-MM-DD:HH:mm")}`, validaAudio())
  }


  const onClickDownload = async (type, option) => {
    try {
      setLoadingDownload(true)
      let querys = `?sessao=${chat.sessao}&msgId=${message.id}&canal=whatsapp`
      if (type === "video") {
        setLoadingDownloadVideo(true)
        setLoadingDownloadVideoId(message.id)
      }
      const response = await v1_actionDownloadMediaWhatsapp(querys);
      if (type === "video" && response.data.status === "error") {
        setLoadingDownloadVideo(false)
        setLoadingDownloadVideoId(null);
        enqueueSnackbar('Não foi possível baixar a mídia', { variant: 'error' });
      }
      setLoadingDownloadVideo(false)
      setLoadingDownloadVideoId(null)
      setLoadingDownload(false)

      if (type === "image") {
        imageRef.current.src = `data:image/jpeg;base64, ${response.data.data.base64}`;
        setIsDownloadImage(true);
        setDisplay("none");
        setLoadingDownload(false)
      } else if (type === "video") {
        imageRef.current.src = `data:video/webm;base64, ${response.data.data.base64}`;
        setDisplay("none");
      } else if (type === "ptt") {
        setAudioUrl(`data:audio/ogg;base64, ${response.data.data.base64}`);
      } else if (type === "audio") {
        setAudioUrl(`data:audio/ogg;base64, ${response.data.data.base64}`);
      } else if (type === "document") {
        const a = document.createElement("a");
        a.href = `data:${option.mimetype};base64, ${response.data.data.base64}`;
        a.download = `${option.filename}`;
        a.click();
      }
    } catch (error) {
      setLoadingDownloadVideo(null)
      setLoadingDownloadVideoId(false);
      setLoadingDownload(false)
      enqueueSnackbar('Não foi possível baixar', { variant: 'error' });
    }

  };


  const handleOnOpenModalImage = () => {
    if (isDownloadImage) {
      onCallbakOpenModal(imageRef.current.src);
    } else {
      onClickDownload("image")
    }
  };


  function getMessageTime(m) {
    const date = new Date(m.timestamp * 1000);
    const lang = navigator.language || navigator.languages[0];
    const date_locale = date.toLocaleDateString(lang, {
      day: "numeric",
      month: "numeric",
      year: "numeric",
    });
    const time_locale = date.toLocaleTimeString(lang);
    const formatted = `${date_locale} ${time_locale}`;
    return (
      <div style={{ marginTop: 5 }}>
        <small>
          <Stack spacing={1} direction="row">
            <Typography sx={{ fontSize: 10 }}>{formatted}</Typography>
            {isMe && (
              <Stack>
                {/* {m.ack == 0 && <Iconify icon="fluent:send-clock-20-filled" />} */}
                {m.preSend && <CircularProgress size={10} />}
                {m.ack === -1 && <Iconify icon="bsx:message-x" />}
                {m.ack === 2 && <Iconify icon="ri:check-double-fill" />}
                {m.self === "out" && m.ack == 1 && <Iconify icon="material-symbols:check" />}
                {m.ack === 3 && <Iconify color="#1919FF" icon="ri:check-double-fill" />}
                {m.ack === 4 && <Iconify color="#1919FF" icon="ri:check-double-fill" />}
              </Stack>
            )}

          </Stack>

        </small>
      </div>
    );
  }

  function getSender(m) {
    let sender = m?.sender?.id?.user;
    if (m.sender) {
      const key = [
        "name",
        "shortName",
        "pushname",
        "verifiedName",
        "formattedName",
      ].find((n) => {
        if (n in m.sender && !!String(m.sender[n]).trim()) {
          return m.sender[n];
        }
      });
      sender = m.sender[key];
    }
    return sender;
  }

  function getReason(m) {
    try {
      const sender = getSender(m);
      if (m.type === "sticker") return `${sender} enviou uma figurinha`;
      if (m.type === "location") return `${sender} enviou uma localização`;
      if (m.type === "revoked") return `${sender} apagou a mensagem`;
      if (m.type === "gp2") {
        let users = [];
        if (m.recipients && Array.isArray(m.recipients)) {
          users = m.recipients.reduce(
            (c, acc, i) => c + (i > 0 ? ", " : "") + acc.user,
            ""
          );
        }
        if (m.subtype === "leave") return `${m?.recipients[0]?.user} saiu`;
        if (m.subtype === "remove") return `${sender} removeu \n${users}`;
        if (m.subtype === "add") return `${sender} adicionou \n${users}`;
      }
    } catch (error) {
      return <p>Erro</p>;
    }
  }

  const validaVCard = message => {
    let array = message.body.split("\n");
    let numero = "";
    let contact = "";
    for (let index = 0; index < array.length; index++) {
      const v = array[index];
      let values = v.split(":");
      for (let ind = 0; ind < values.length; ind++) {
        if (values[ind].indexOf("+") !== -1) {
          numero = values[ind]
        }
        if (values[ind].indexOf("FN") !== -1) {
          contact = values[ind + 1];
        }
      }
    }

    return (
      <Box>
        <Typography sx={{ mb: 2, fontSize: 10, fontStyle: 'italic' }}>Contato Encaminhado</Typography>
        <Typography>{contact}</Typography>
        <Typography>{numero}</Typography>
      </Box>
    );
  }


  const validaMediaMessage = (message) => {
    if (message.type === "ptt") {
      if (message.preSend) {
        return (
          <Box
            sx={{ width: 200, height: 100, alignItems: 'center', justifyContent: 'center', display: 'flex' }}
          >
            <Stack>
              <Typography sx={{ mb: 2, fontSize: 12, fontStyle: 'italic' }}>Enviando Audio ...</Typography>
              <LinearProgress size={50} color="secondary" />
            </Stack>
          </Box>
        )
      } else if (message.type === "revoked") {
        return (
          <Box>
            <Typography sx={{ mb: 2, fontSize: 12, fontStyle: 'italic' }}>Mensagem de voz apagada</Typography>
          </Box>
        )
      } else {
        return (
          <AudioComponent
            url={audioUrl}
            loading={loadingDownload}
            isMe={message.fromMe}
            profileImage={!message?.sender?.profilePicThumbObj?.eurl ? defaultImage : message?.sender?.profilePicThumbObj?.eurl}
            audioRef={audioRef}
            downloadAudio={onClickDownload}
            id={message.id}
          />
        )
      }



    } else if (message.type === "audio") {
      if (message.preSend) {
        return (
          <Box
            sx={{ width: 200, height: 100, alignItems: 'center', justifyContent: 'center', display: 'flex' }}
          >
            <Stack>
              <Typography sx={{ mb: 2, fontSize: 12, fontStyle: 'italic' }}>Enviando Audio ...</Typography>
              <LinearProgress size={50} color="secondary" />
            </Stack>
          </Box>
        )
      } else if (message.type === "revoked") {
        return (
          <Box>
            <Typography sx={{ mb: 2, fontSize: 12, fontStyle: 'italic' }}>Audio apagado</Typography>
          </Box>
        )
      } else {
        return (
          <AudioComponent
            url={audioUrl}
            isMe={message.fromMe}
            profileImage={!message?.sender?.profilePicThumbObj?.eurl ? defaultImage : message?.sender?.profilePicThumbObj?.eurl}
            audioRef={audioRef}
            downloadAudio={onClickDownload}
          />
        )
      }


    } else if (message.type === "location") {
      return (
        <LocationPreview
          base64={`data:image/jpg;base64, ${message.body}`}
          link={`https://www.google.com/maps/place/${message.lat},${message.lng}`}
        />
      )
    } else if (message.type === "video") {
      if (message.preSend) {
        return (
          <Box
            sx={{ width: 200, height: 200, alignItems: 'center', justifyContent: 'center', display: 'flex' }}
          >
            <Stack>
              <Typography sx={{ mb: 2, fontSize: 12, fontStyle: 'italic' }}>Enviando Arquivo ...</Typography>
              <LinearProgress size={50} color="secondary" />
            </Stack>
          </Box>
        )
      } else if (loadingDownloadVideo && message.id === loadingDownloadVideoId) {
        return (
          <Box
            sx={{ width: 200, height: 200, alignItems: 'center', justifyContent: 'center', display: 'flex' }}
          >
            <Stack>
              <Typography sx={{ mb: 2, fontSize: 12, fontStyle: 'italic' }}>Baixando Video ...</Typography>
              <LinearProgress size={50} color="secondary" />
            </Stack>
          </Box>
        )
      } else {
        if (message.type === "revoked") {
          return (
            <Box>
              <Typography sx={{ mb: 2, fontSize: 12, fontStyle: 'italic' }}>Video apagado</Typography>
            </Box>
          )
        } else {
          return (
            <ImageContainer>
              <>
                <video
                  ref={imageRef && imageRef}
                  src={`data:image/png;base64, ${message.body}`}
                  controls
                />
                <div
                  className={"download"}
                  style={{ display: display }}
                  onClick={() => onClickDownload("video")}
                />
              </>
            </ImageContainer>
          )
        }
      }




    } else if (mimeTypesImages.includes(message.mimetype)) {
      if (message.type === "revoked") {
        return (
          <Box>
            <Typography sx={{ mb: 2, fontSize: 12, fontStyle: 'italic' }}>Imagem apagada</Typography>
          </Box>
        )
      } else {
        return (
          <ImageContainer>
            {loadingDownload ? (
              <SkeletonChatImage />
            ) : (
              <>
                <img
                  style={{ borderRadius: 5 }}
                  ref={imageRef}
                  src={`data:image/png;base64, ${message.body}`}
                  loading={"lazy"}
                  alt={message.caption}
                  onClick={handleOnOpenModalImage}
                />
                <div
                  className={"download"}
                  style={{ display: display }}
                  onClick={() => onClickDownload("image")}
                />
              </>

            )}
          </ImageContainer>
        )
      }

    }
  }

  function getBodyMessage() {
    // Early return for chat messages with a body
    if (message?.body && message.type === "chat") {
      return <MarkdownWrapper>{message.body}</MarkdownWrapper>;
    } else if (message.type === "ciphertext") {
      return (
        <div className="bg-yellow-50 border border-yellow-100 p-2 rounded-sm flex flex-row gap-2 items-center">
          <p className="text-sm text-muted-foreground italic">Aguardando mensagem. Consulte a mensagem no aparelho</p>
          <Button
          onClick={() => {
            window.open('https://faq.whatsapp.com/835452491239734/?helpref=hc_fnav&locale=pt_BR', '_blank')
          }}
          variant="text"
           className="text-black h-5 underline"
           >
          Saiba mais
          </Button>
        </div>
      )
    }

    // Group conditions for media messages
    const isMediaMessage = message.type === 'location' && message.isMedia === false ||
      ['ptt', 'image', 'audio', 'video'].includes(message.type) ||
      message.mimetype === "image/jpeg";

    if (isMediaMessage) {
      return validaMediaMessage(message);
    }

    // Handle cases without a message body
    if (!message?.body) {
      return getReason(message);
    }
  }


  const optionsPrelude = [
    {
      label: "Encaminhar",
      method() {
        setAnchorEl(null);
        setOpenEncaminharDialog(true);

      },
    },
    {
      label: "Responder",
      method() {
        setAnchorEl(null);
        selectMessageId();
      },
    },
    {
      label: "Apagar",
      method() {
        setAnchorEl(null);
        setOpenDeleteDialog(true);
      },
    },


  ];

  const options = chat.statusRoom === "Atendendo" ? optionsPrelude : optionsPrelude.filter(option => option.label !== "Encaminhar" && option.label !== "Responder");



  if (message.type === "video" || message.type === "image") {
    options.push({
      label: "Baixar mídia",
      method() {
        setAnchorEl(null);
        baixarMidiaDevice();
      },
    });
  }



  if (message.type === "ptt" || message.type === "audio") {
    options.push({
      label: "Baixar Audio",
      method() {
        setAnchorEl(null);
        baixarAudioDevice();
      },
    });
  }

  let gatilhosCopilot = config?.global.chatgpt_gatilhos;

  if (message.type === "note") {
    return (
      <Stack key={key} direction={'row'}>
        <ChatLayout side={isMe}>
          <Stack sx={{ mt: 3 }}>
            <div
              className={`
        ${isDesktop ? 'w-fit' : 'w-full'} 
        text-sm max-w-full h-auto relative 
        shadow-sm flex flex-col flex-wrap 
         rounded-md 
        min-w-[200px]
        bg-slate-50
      `}
            >
              <div className="w-full bg-yellow-100 rounded-t-sm flex flex-row justify-between px-1 min-h-6 items-center">
                <div className="flex flex-row gap-1 items-center">
                  <Iconify className="text-yellow-600 " icon="fluent:note-16-filled" />
                  <p className="text-violet-800 p-1 text-xs">{message.userName}</p>
                </div>

                <Button
                  onClick={onDeleteNote}
                  size="icon"
                  className="h-6 w-6"
                  variant="ghost"
                >
                  <Iconify color="red" icon="mi:delete" />
                </Button>

              </div>
              <div className="msg-title-top mb-2.5 p-2 w-fit text-slate-700">
                <div ref={textRef}>{message.body}</div>
              </div>

            </div>

            <Stack spacing={0.5} alignItems={"center"} direction={"row"}>

              {getMessageTime(message)}
            </Stack>
          </Stack>
        </ChatLayout>
      </Stack>
    )
  }

  return (
    <Stack key={key} direction={'row'}>
      <ChatLayout side={isMe}>

        <Stack sx={{ mt: 3 }}>
          <MessageContainer isDesktop={isDesktop} side={isMe} isWarning={isWarning}>
            <Stack sx={{ p: 0.5, borderRadius: 0.5, justifyContent: "end" }} direction={"row"} alignItems={"center"} >
              {!message.fromMe ? (
                <Typography variant="extraSmall" sx={{ minWidth: 150 }}> {message.sender?.name || message.sender?.id?.user} - {""} {message?.sender?.pushname}</Typography>
              ) : (<Box></Box>)}
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button className="w-4 h-4 text-[10px] flex- items-center bg-green-50" size="icon">
                    <Iconify color="grey" icon="fe:arrow-down" />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent className="w-3">


                  {!typesIgnore.includes(message.type) && options.map((option) => (
                    <DropdownMenuItem key={option.label} onClick={() => option.method()}>
                      {option.label}
                    </DropdownMenuItem>
                  ))}



                  {message.type === "chat" && verifyRules(user.rules, 'chatgpt') && (
                    <>
                      <Divider />
                      <DropdownMenuSub>
                        <DropdownMenuSubTrigger>Copilot</DropdownMenuSubTrigger>
                        <DropdownMenuPortal>
                          <DropdownMenuSubContent className="w-[300px] mb-16">
                            <Scrollbar>
                              {gatilhosCopilot?.length > 0 && gatilhosCopilot.map(gatilho => {
                                return (
                                  <DropdownMenuItem
                                    onClick={() => {
                                      let data = {
                                        message: message.body,
                                        input: gatilho.input
                                      }

                                      callbackGpt(data);
                                      handleCloseMenu()
                                      handleClose();

                                    }}>
                                    <Stack alignItems={"center"} spacing={1} direction={"row"}>
                                      {gatilho.nome}
                                    </Stack>

                                  </DropdownMenuItem>
                                )
                              })}
                            </Scrollbar>
                          </DropdownMenuSubContent>
                        </DropdownMenuPortal>
                      </DropdownMenuSub>
                    </>
                  )}
                </DropdownMenuContent>
              </DropdownMenu>
            </Stack>

            <MessageContent sx={{ elevation: 1 }} side={isMe} isWarning={isWarning}>
              {message.type === "document" ? (
                message.preSend ? (
                  <Box
                    sx={{ width: 200, height: 100, alignItems: 'center', justifyContent: 'center', display: 'flex' }}
                  >
                    <Stack>
                      <Typography sx={{ mb: 2, fontSize: 12, fontStyle: 'italic' }}>Enviando Audio ...</Typography>
                      <LinearProgress size={50} color="secondary" />
                    </Stack>
                  </Box>
                ) : (
                  <DocumentComponent
                    side={isMe}
                    isWarning={isWarning}
                    onClick={() =>
                      onClickDownload("document", {
                        mimetype: message.mimetype,
                        filename: message.filename,
                        mediadata: message.mediadata,
                      })
                    }
                  >
                    <Typography variant="extraSmall">{message.filename}</Typography>
                    {loadingDownload ? (
                      <CircularProgress size={20} color="secondary" />
                    ) : (
                      <Iconify icon="mage:file-download-fill" className="icon" />
                    )
                    }

                  </DocumentComponent>
                )
              ) : message.type === "vcard" ? (
                <MessageContentText >
                  {validaVCard(message)}

                </MessageContentText>
              ) : message.type === "e2e_notification" ? (
                <MessageContentText >
                  <Alert variant="outlined" severity="info">Mensagem de criptografia do Whatsapp</Alert>
                </MessageContentText>
              ) : message.type === "notification_template" ? (
                <MessageContentText >
                  <Alert variant="outlined" severity="info">Mensagem do Whatsapp</Alert>
                </MessageContentText>
              ) : message.type === "protocol" ? (
                <MessageContentText >
                  <Alert variant="outlined" severity="info">Mensagem do Whatsapp</Alert>
                </MessageContentText>
              ) : message.type === "call_log" ? (
                <MessageContentText >
                  <Alert variant="outlined" severity="error">Ligação de Whatsapp perdida</Alert>
                </MessageContentText>
              ) : (
                <MessageContentText isWarning={isWarning}>
                  {!!message.quotedMsg && <QuotedMessage message={message} />}
                  <div ref={textRef}>{getBodyMessage()}</div>
                </MessageContentText>
              )}
            </MessageContent>

            {message.caption && <span className="caption">{message.caption}</span>}

          </MessageContainer>
          <Stack spacing={0.5} alignItems={"center"} direction={"row"}>
            {message.isDeleted && (
              <Tooltip title="Mensagem Apagada">
                <IconButton disable>
                  <Iconify width={18} icon="material-symbols:delete" color="red" />
                </IconButton>
              </Tooltip>

            )}
            {getMessageTime(message)}
          </Stack>
        </Stack>


        {openDeleteDialog && (
          <AlertDialogLoading
            loading={loadingDeletarMensagem}
            title="Confirma Apagar?"
            content="Se for possível será apagado para todos, senão, somente para você"
            confirm={async () => {
              try {
                setLoadingDeletarMensagem(true);
                await excluirMensagem(message);
              } catch (error) {
                enqueueSnackbar('Não foi possível apagar', { variant: 'error' });

              } finally {
                setOpenDeleteDialog(false);
                setLoadingDeletarMensagem(false);
              }
            }}
            close={() => setOpenDeleteDialog(false)}
          />
        )}

        {openEncaminharDialog && (
          <ModalEncaminharMensagem
            user={user}
            open={openEncaminharDialog}
            loading={loadingEncaminharMensagem}
            onSubmit={data => {
              encaminharMesagemWhatsapp(data);
            }}
            onClose={() => setOpenEncaminharDialog(false)}
          />
        )}
      </ChatLayout>
    </Stack>

  );
};

ChatComponent.propTypes = {
  message: PropTypes.any.isRequired,
  session: PropTypes.string.isRequired,
  isMe: PropTypes.string.isRequired,
};

export default ChatComponent;
