import axios from "axios";

import Config from "../../../config";

const editGroup = data => {
    return axios({
        method: "post",
        url: (Config.urlWs || '') + "/api/rooms/groups/edit",
        data
    });
};

export default editGroup;
