import React from 'react';
import {Stack} from '@mui/material';
import {FiArrowLeft} from 'react-icons/fi';

import Picture from "../../../../components/Picture";
import { setPanel } from 'src/redux/slices/staff';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'src/@/components/ui/button';

const TopBar = () => {
    const dispatch = useDispatch()
    let room = {title: 'Grupo', isGroup: true};
    const {groupTitle} = useSelector((state) => state.staff);
    const title = groupTitle;

    return (
        <Stack spacing={2} alignItems={"center"} direction="row" sx={{mt:2}}>
            <Button size-="icon" variant="outline" onClick={() => dispatch(setPanel('standard'))}>
                <FiArrowLeft/>
            </Button>
            <small className="text-sm font-medium leading-none">Voltar</small>
        </Stack>
            // <div  className="top-bar-group">
            //     <div className="nav">
            //         <div className="button" onClick={() => dispatch(setPanel('standard'))}>
            //             <FiArrowLeft/>
            //         </div>
            //         <div className="profile conversation">
            //             <Picture user={room} group={room.isGroup} picture={room.picture} title={room.title}/>
            //         </div>
            //         <div className="text">
            //             <div className="title">Criar Grupo</div>
            //             <div className="message">{title}</div>
            //         </div>
            //     </div>
            // </div>
    );
}

export default TopBar;
