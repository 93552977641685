import { createSlice } from '@reduxjs/toolkit';

// utils
import getActionListaChatsFunil from 'src/actions/chats/getActionListaChatsFunil';
import getActionListaColunasFunil from 'src/actions/chats/getActionListaColunasFunil';

import axios from '../../utils/axios';
import actionUpdateConfigFunil from 'src/actions/chats/funil/actionUpdateConfigFunil';
import actionBuscarConfigFunil from 'src/actions/chats/funil/actionBuscarConfigFunil';
import v2_actionListaChatsFunil from 'src/actions/v2/chats/funil/v2_actionListaChatsFunil';
import v2_actionEditarChat from 'src/actions/v2/chats/v2_actionEditarChat';
import v2_actionListaColunasChatsFunil from 'src/actions/v2/chats/funil/v2_actionListaColunasChatsFunil';

// ----------------------------------------------------------------------

function objFromArray(array, key = 'id') {
  return array.reduce((accumulator, current) => {
    accumulator[current[key]] = current;
    return accumulator;
  }, {});
}

function objFromArrayId(array, key = '_id') {
  return array.reduce((accumulator, current) => {
    accumulator[current[key]] = current;
    return accumulator;
  }, {});
}

const initialState = {
  isLoading: false,
  error: false,
  colunas:[],
  chatsKanban:{},
  ordemColunas:[],
  board: {
    cards: {},
    columns: {},
    columnOrder: []
  }
};

const slice = createSlice({
  name: 'chatKanban',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },


    persistCard(state, action) {
      const columns = action.payload;
      console.log('persistCard', columns)
      state.colunas = columns;

    },

    persistColumn(state, action) {
      state.ordemColunas = action.payload;
    },



    // LISTA DE CHATS POR FUNIL
    getListaChatsFunilSuccess(state, action) {
      state.isLoading = false;
      state.chatsKanban = objFromArrayId(action.payload);
    },

    getListaColunasFunilSuccess(state, action) {
      let listaColunas = action.payload;
      const reduce = listaColunas.map(item => item.id)
    
      state.isLoading = false;
      state.colunas = objFromArray(action.payload);
      state.ordemColunas = reduce;
    },
    getListaColunasFunil2Success(state, action) {
      let listaColunas = action.payload;
      state.ordemColunas = listaColunas;
    },




  }
});

// Reducer
export default slice.reducer;

export const { actions } = slice;

// ----------------------------------------------------------------------

export function getListaChatsFunil(querys) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await v2_actionListaChatsFunil(querys);
      dispatch(slice.actions.getListaChatsFunilSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}



export function getListaColunasFunil(querys2, usuarioId, clienteId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await v2_actionListaColunasChatsFunil(querys2);
      dispatch(slice.actions.getListaColunasFunilSuccess(response.data.data));
      let querys = `?cliente_id=${clienteId}&usuario_id=${usuarioId}`;
      const response_config_funil = await actionBuscarConfigFunil(querys);
      if(response_config_funil.data.data) {
        dispatch(slice.actions.getListaColunasFunil2Success(response_config_funil.data.data.ordem_colunas));
      }    
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function persistColumn(newColumnOrder, user) {
  return async (dispatch) => {
    dispatch(slice.actions.persistColumn(newColumnOrder));
    try {
      
      let data = {
        scope: { ordem_colunas:newColumnOrder },
        cliente_id:user.clienteId,
        usuario_id:user.id,
        action:"update_column"
      }
      await actionUpdateConfigFunil(data);
  
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
   
  };
}

// ----------------------------------------------------------------------

export function persistCard(columns) {
  return (dispatch) => {
    dispatch(slice.actions.persistCard(columns));
  };
}

