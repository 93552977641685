import axios from "axios";

import Config from "../../../config";

const search = (clienteId, text, limit ) => {
    return axios({
        method: "post",
        url: (Config.urlWs || '') + "/api/search",
        data: { clienteId:clienteId, limit: limit || 30, search: text || '' }
    });
};

export default search;
