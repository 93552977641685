// material
import { Box, Avatar, ListItemAvatar, ListItemButton, Badge, Typography, Button } from '@mui/material';
import { styled } from "@mui/material/styles";

import BadgeStatus from '../../BadgeStatus';
// ----------------------------------------------------------------------

const AVATAR_SIZE = 38;

const RootStyle = styled(ListItemButton)(({ theme }) => ({
  padding: theme.spacing(1.5, 3),
  transition: theme.transitions.create('all'),
  borderBottom:'1px solid #f0f0f0'
}));

const AvatarWrapperStyle = styled('div')({
  position: 'relative',
  width: AVATAR_SIZE,
  height: AVATAR_SIZE,
  '& .MuiAvatar-img': { borderRadius: '20%' },
  '& .MuiAvatar-root': { width: '100%', height: '100%' }
});



  

export default function ChatStaffItem({
  chat,
  user,
  deletarGrupo,
  onlineUsers,
  onSelectConversation,
  roomsWithNewMessages,
  isOpenSidebar
}) {

    const room = chat;

    let other = {};

    chat.people.forEach(person => {
        if (user.id !== person._id) other = person;
    });

    if (!other.firstName) {
        other = { ...other, firstName: 'Deleted', lastName: 'User' };
    }

    const title = (chat.isGroup ? chat.title : `${other.firstName} ${other.lastName}`).substr(0, 22);

    let lastMessage = chat.lastMessage;
    let text = '';

    if (!lastMessage && chat.isGroup) text = 'Novo grupo criado';
    if (!lastMessage && !chat.isGroup) text = `Sem mensagens`;

    if (!lastMessage) lastMessage = {};

    if (lastMessage.author === user.id && !chat.isGroup) text += 'Você: ';

    switch (lastMessage.type) {
        case 'file':
            text += 'Enviou um arquivo.';
            break;
        case 'image':
            text += 'Enviou uma foto';
            break;
        default:
            text += (lastMessage.content || '');
    }

 
    const getStatus = () => {
      if (room) {if(room.isGroup) return null}
      if (onlineUsers.filter(u => u.id === other._id && u.status === 'busy').length > 0) return 'busy';
      if (onlineUsers.filter(u => u.id === other._id && u.status === 'online').length > 0) return 'online';
      if (onlineUsers.filter(u => u.id === other._id && u.status === 'away').length > 0) return 'away';
      return null;
  };

  
  function validaAdminGrupo () {
    if(chat) {
      if(chat.people[0]._id === user.id) {
        if(chat.isGroup === true) {
          return <Button variant="outlined" style={{fontSize:10}} onClick={deletarGrupo} size="small">Excluir Grupo</Button>;
        }
          
      }
    }

  }

  return (
    <RootStyle
      disableGutters
      onClick={onSelectConversation}
      //onClick={() => console.log('teste 1')}
      //sx={{
        //...(isSelected && { bgcolor: 'action.selected' })
      //}}
      {...other}
    >
      <ListItemAvatar>
        <Box>
          
            <AvatarWrapperStyle className="avatarWrapper" key={chat._id}>
            {<BadgeStatus status={getStatus()} size="large" />}
              <Avatar />
              
            </AvatarWrapperStyle>
   
           
        </Box>
      </ListItemAvatar>

      {isOpenSidebar && (
        <>
      <Box  sx={{
              display: 'flex',
             
              flexDirection: 'column'
            }}
            >
        <Typography sx={{fontSize:14, fontWeight:'bold'}}> {title.substr(0, 20)}{title.length > 20 && '...'}  </Typography>
        <Typography sx={{fontSize:12}}>{text} </Typography>                            

      </Box>
 

          <Box
            sx={{
              ml: 2,
              height: 44,
              display: 'flex',
              alignItems: 'flex-end',
              flexDirection: 'column'
            }}
          >
            <Box
              sx={{
                mb: 1.25,
                fontSize: 12,
                lineHeight: '22px',
                whiteSpace: 'nowrap',
                color: 'text.disabled'
              }}
            >
               {getStatus() && <div className={`dot ${getStatus()}`} />}
            
            </Box>
            {validaAdminGrupo()}
            {roomsWithNewMessages.includes(room._id) ? <Badge badgeContent={roomsWithNewMessages.filter(r => room._id === r).length} color="secondary"></Badge> : '' }
          </Box>
        </>
      )}
    </RootStyle>
  );
}
