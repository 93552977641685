import React from "react";

import Config from "../../../../config";

const Picture = ({user = {}, group = false, picture, title = "Group"}) => {
    if (group) {
        if (picture)
            return <img src={`${Config.urlWs || ''}/api/images/${picture.shieldedID}/256`} alt="Picture" className="picture"/>;
        else
            return <div className="img">{title.substr(0,1).toUpperCase()}</div>;
    }

    let firstName = user.firstName || "Usuário";
    let lastName = user.lastName || "Nome";
    if (user.picture)
        return <img src={`${Config.urlWs || ''}/api/images/${user.picture.shieldedID}/256`} alt="Picture" className="picture"/>;
    else
        return <div className="img">{firstName.substr(0,1).toUpperCase()}{lastName.substr(0,1).toUpperCase()}</div>;
};

export default Picture;
