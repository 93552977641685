import moment from "moment";

export function formataNumeroCelular(numeroCliente) {
  if(!numeroCliente) return;
  //normalize string and remove all unnecessary characters
  numeroCliente = numeroCliente.replace(/[^\d]/g, "");

  //check if number length equals to 10
  if (numeroCliente.length === 12) {
      //reformat and return phone number
      return numeroCliente.replace(/(\d{2})(\d{2})(\d{4})(\d{4})/, "($2) $3-$4");
  } else if(numeroCliente.length === 13) {
     return numeroCliente.replace(/(\d{2})(\d{2})(\d{1})(\d{4})(\d{4})/, "($2) $3 $4-$5");
  }

  return null;
}

export const soNumeros = (string) => {
  if(!string) return;
  var numbers = string.replace(/[^0-9]/g, '');
  return numbers;
}

export function badgeFuncNumber(numero) {
  if(numero === 0) return null;
  if (numero > 99) {
      return "+99";
  } else {
      return numero.toString();
  }
}


export const formatarParaVerificar = numeroCliente => {
 
  //normalize string and remove all unnecessary characters
  numeroCliente = numeroCliente.replace(/[^\d]/g, "");

  //check if number length equals to 10
  if (numeroCliente.length === 12) {
      //reformat and return phone number
      return numeroCliente.replace(/(\d{2})(\d{2})(\d{4})(\d{4})/, "$1$29$3$4");
  } else if(numeroCliente.length === 13) {
     return numeroCliente.replace(/(\d{2})(\d{2})(\d{1})(\d{4})(\d{4})/, "$1$2$4$5");
  }

  return null;
}


export const notifyMe = (message) => {
  // Verifica se o browser suporta notificações
  if (!("Notification" in window)) {
    alert("Este browser não suporta notificações de Desktop");
  }

  // Let's check whether notification permissions have already been granted
  else if (Notification.permission === "granted") {
  if(message.response.type === "chat") {
     new Notification(message.response.sender.formattedName, {
      body: message.response.body
    });
  } else if(message.response.type === "ptt") {
   new Notification(message.response.sender.formattedName, {
    body: "Audio"
  });
  } else if(message.response.type === "image") {
     new Notification(message.response.sender.formattedName, {
     body: "Imagem/Foto"
   });
   } 
}

  else if (Notification.permission !== 'denied') {
    Notification.requestPermission(function (permission) {
      if (permission === "granted") {
         new Notification(message.response.sender.formattedName, {
          body: message.response.body
        });
      }
    });
  }
}


export const notifyMeStaff = (mensagem, remetente) => {

  // Verifica se o browser suporta notificações
  if (!("Notification" in window)) {
    alert("Este browser não suporta notificações de Desktop");
  }

  // Let's check whether notification permissions have already been granted
  else if (Notification.permission === "granted") {
    new Notification(remetente, {
      body: mensagem
    });
  
}

  else if (Notification.permission !== 'denied') {
    Notification.requestPermission(function (permission) {
      if (permission === "granted") {
       new Notification(remetente, {
          body: mensagem
        });
      }
    });
  }

}

export const getBase64 = (file) => {
  var reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    return reader.result;
  };
  reader.onerror = function (error) {
    console.log(reader.result);
    return null;
  };
}

export function verifyRules(array, rule) {
if (!array) return false;
return array.includes(rule);
}

export function validaMenu(rules, testagem) {
  return rules.some(r=> testagem.indexOf(r) >= 0);
}


export function verifyFeatures(array, rule) {
  if (!array) return false;
  return array.includes(rule);
  }

export function resultTimes(values) {
  if (!values) return 0;
  const inteiro = Math.floor(values);
 
  let format = `${verificahoras(inteiro)} ${inteiro >= 60 ? "h" : "min"}`
  return format;
}

export function verificahoras(number) {
if (number >= 60) {
  return Math.floor((number / 60))
} else {
  return Math.floor(number);
}
}


export const notifyMeComentario = (titulo, content) => {
  // Verifica se o browser suporta notificações
  if (!("Notification" in window)) {
    alert("Este browser não suporta notificações de Desktop");
  }

  // Let's check whether notification permissions have already been granted
  else if (Notification.permission === "granted") {
    const notification = new Notification(titulo, {
      body: content,
      image:'https://zindi.s3.us-east-2.amazonaws.com/lais/imagotipo.png'
    });
}

  else if (Notification.permission !== 'denied') {
    Notification.requestPermission(function (permission) {
      if (permission === "granted") {
        const notification = new Notification(titulo, {
          body: content,
          image:'https://zindi.s3.us-east-2.amazonaws.com/lais/imagotipo.png'
        });
      }
    });
  }

}


export function validaLabelMenuDatas(value, dataInicial, dataFinal) {
  if(value) {
    if(value === "mes_atual") {
      return "Esse mês";
    } else if(value === "mes_anterior") {
      return "Mês anterior";
    }   else if(value === "ultimos_90_dias") {
      return "Ultimos 3 meses";
    } else if(value === "null") {
      if(dataInicial && dataFinal) {
        let inicio2 = moment(dataInicial).format("DD/MM/YYYY");
        let final2 = moment(dataFinal).format("DD/MM/YYYY");
  
        return `De ${inicio2} a ${final2}`;
  
      }
    }
  } else {
    


  }
      
}


export const notifyNewMessage = (message, chat) => {
  console.log('chat boy lista find', chat)

  
  const foto_perfil_contato = !message?.sender?.profilePicThumbObj?.eurl ? null : message?.sender?.profilePicThumbObj?.eurl;
  const name = chat && chat.nomeCliente ? chat.nomeCliente : message?.sender?.formattedName ? message?.sender?.formattedName : soNumeros(message.chatId);
  console.log(foto_perfil_contato)
  // Verifica se o browser suporta notificações
  if (!("Notification" in window)) {
    alert("Este browser não suporta notificações de Desktop");
  }

  // Let's check whether notification permissions have already been granted
  else if (Notification.permission === "granted") {
  if(message.type === "chat") {
     new Notification(name, {
      body: message.body,
      icon: foto_perfil_contato,
    });
  } else if(message.type === "ptt") {
   new Notification(name, {
    body: "Audio",
    icon: foto_perfil_contato,
  });
  } else if(message.type === "image") {
     new Notification(name, {
     body: "Imagem/Foto",
     icon: foto_perfil_contato,
   });
   } 
}

  else if (Notification.permission !== 'denied') {
    Notification.requestPermission(function (permission) {
      if (permission === "granted") {
         new Notification(name, {
          body: message.body
        });
      }
    });
  }
}


export function obterNumeroPorClienteId(cliente_id, arr) {
  for (let i = 0; i < arr.length; i++) {
      if (arr[i].cliente_id === cliente_id) {
          return arr[i].nome;
      }
  }
  // Se o cliente_id não for encontrado, você pode retornar um valor padrão ou lançar uma exceção, dependendo dos requisitos do seu aplicativo.
  return null; // Ou outra ação apropriada, como lançar um erro.
}




export function getTransformEventsWebhook(key) {
  const events = {
  "whatsapp.payload_message": "Payload Whatsapp",
  "whatsapp.pesquisa_concluida": "Após Pesquisa",
  "whatsapp.after_resposta_pesquisa": "Após Resposta Pesquisa"
};

return events[key]
}


export function validarNumeroWhatsapp(numero) {
  // Converte o número para string para facilitar a manipulação
  numero = Math.abs(numero);
  let numeroString = numero.toString();

  // Verifica se os dois primeiros dígitos são "55"
  if (numeroString.startsWith('55')) {
    // Obtém os dígitos da posição 3 e 4
    let digits = parseInt(numeroString.substr(2, 2), 10);
    console.log(digits)
    
      const codigoPais = numeroString.substr(0, 2);
         const ddd = numeroString.substr(2, 2);
        const numero = numeroString.substr(4, 14);

    // Verifica se os dígitos da posição 3 e 4 são maiores que 31
    if (digits >= 31) {
        let numeroF;
        if(numero.length === 8) {
          numeroF = numero
          console.log('numero 8', numero)
        } else {
          numeroF =  numero.substr(1)
        }
        
      return codigoPais + ddd + numeroF
      
    } else {
        let numeroF;
        if(numero.length === 8) {
          numeroF = 9 + numero
        } else {
          numeroF =  numero
        }
      return codigoPais + ddd + numeroF
    }
  }

  // Retorna o número modificado ou o original, dependendo das condições
  return parseInt(numeroString, 10);
}

export const maskCPF = value => {
  return value
    .replace(/\D/g, "")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d{1,2})/, "$1-$2")
    .replace(/(-\d{2})\d+?$/, "$1");
};

export function mascararTel(v) {
  if(!v) return "";  
  v = v.replace(/\D/g, "");
  v = v.replace(/^(\d{2})(\d)/g, "($1) $2");
  v = v.replace(/(\d)(\d{4})$/, "$1-$2");
  return v;
}

export const maskDate = (value) => {
  return value
    .replace(/\D/g, "")
    .replace(/(\d{2})(\d)/, "$1/$2")
    .replace(/(\d{2})(\d)/, "$1/$2")
    .replace(/(\d{4})(\d)/, "$1");
};


export const validarCPF = (cpf) => {
  let i;
  let rev;
  let add;
  cpf = cpf.replace(/[^\d]+/g, '');
  if (cpf == '') return false;
  // Elimina CPFs invalidos conhecidos	
  if (cpf.length != 11 ||
    cpf == "00000000000" ||
    cpf == "11111111111" ||
    cpf == "22222222222" ||
    cpf == "33333333333" ||
    cpf == "44444444444" ||
    cpf == "55555555555" ||
    cpf == "66666666666" ||
    cpf == "77777777777" ||
    cpf == "88888888888" ||
    cpf == "99999999999")
    return false;
  // Valida 1o digito	
  add = 0;
  for (i = 0; i < 9; i++)
    add += parseInt(cpf.charAt(i)) * (10 - i);
  rev = 11 - (add % 11);
  if (rev == 10 || rev == 11)
    rev = 0;
  if (rev != parseInt(cpf.charAt(9)))
    return false;
  // Valida 2o digito	
  add = 0;
  for (i = 0; i < 10; i++)
    add += parseInt(cpf.charAt(i)) * (11 - i);
  rev = 11 - (add % 11);
  if (rev == 10 || rev == 11)
    rev = 0;
  if (rev != parseInt(cpf.charAt(10)))
    return false;
  return true;
}


export function verificarUrlProvider() {
  // Obtém a URL atual
  const urlAtual = window.location.href;

  // Exemplo de verificação simples: verificar se a URL contém uma palavra específica
  if (urlAtual.includes('eva')) {
    return 'iaeva.com.br'
  } if (urlAtual.includes('lais')) {
    return 'lais.app'
  } else {
    return 'lais.app';
  }

}

export const convertUrls = text => {
  // eslint-disable-next-line no-useless-escape
  const urlRegex =/(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
  return text.replace(urlRegex, function(url) {
      return '<a href="' + url + '" target="_blank">' + url + '</a>';
  });
};
