import axios from "axios";

import Config from "../../../config";

const message = data => {
    return axios({
        method: "post",
        url: (Config.urlWs || '') + "/api/message",
        data: data,
        headers: {'content-type': 'application/json'}
    });
};

export default message;
