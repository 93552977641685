import axios from "axios";

import Config from "../../config";

const getActionDeletarGrupo = data => {
    return axios({
        method: "post",
        url: `${Config.dev ? Config.url_dev : Config.urlBackend}/api/v1/chats/staff/deletar-grupo`,
        data
    });
};

export default getActionDeletarGrupo;
