
// icons


// material
import { useMediaQuery, Tab, Tabs, Badge } from '@mui/material';
import { styled, useTheme } from "@mui/material/styles";

import { TabContext, TabPanel } from '@mui/lab';
import { useState, useEffect } from 'react';
import { BsChatText, BsPatchCheck, BsClockHistory, BsInboxes, BsSearch } from "react-icons/bs";
import { useNavigate } from 'react-router-dom';

// redux
import { useSelector } from '../../../redux/store';
// utils
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
import axios from '../../../utils/axios';
//
import Scrollbar from '../../Scrollbar';
import ChatContactSearch from './ChatContactSearch';
import ChatConversationList from './ChatConversationList';
import ChatSearchResults from './ChatSearchResults';


// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  width: 70,
  flexShrink: 0,
  display: 'flex',
  flexDirection: 'column',
  transition: theme.transitions.create('width'),
  borderRight: `1px solid ${theme.palette.divider}`
}));

// ----------------------------------------------------------------------

export default function ChatSubnav() {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [openSidebar, setOpenSidebar] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [isSearchFocused, setSearchFocused] = useState(false);
  const displayResults = searchQuery && isSearchFocused;
  const { conversations, activeConversationId, chatsAtendimento, chatsPendente, chatsFinalizado } = useSelector((state) => state.chat);


  const [valueScrollable, setValueScrollable] = useState('1');

    ///////////// ITENS DO TAB PAINEL
    const ItemAtendendo = () => {
      return (
        <Scrollbar>
        {!displayResults ? (
          <ChatConversationList
            conversations={conversations}
            chats={chatsAtendimento}
            isOpenSidebar={openSidebar}
            activeConversationId={activeConversationId}
            sx={{ ...(isSearchFocused && { display: 'none' }) }}
          />
        ) : (
          <ChatSearchResults query={searchQuery} results={searchResults} onSelectContact={handleSelectContact} />
        )}
      </Scrollbar>
      );
    }

      ///////////// ITENS DO TAB PAINEL
      const ItemPendente = () => {
        return (
          <Scrollbar>
          {!displayResults ? (
            <ChatConversationList
              conversations={conversations}
              chats={chatsPendente}
              isOpenSidebar={openSidebar}
              activeConversationId={activeConversationId}
              sx={{ ...(isSearchFocused && { display: 'none' }) }}
            />
          ) : (
            <ChatSearchResults query={searchQuery} results={searchResults} onSelectContact={handleSelectContact} />
          )}
        </Scrollbar>
        );
      }

           ///////////// ITENS DO TAB PAINEL
           const ItemFinalizado = () => {
            return (
              <Scrollbar>
              {!displayResults ? (
                <ChatConversationList
                  conversations={conversations}
                  chats={chatsFinalizado}
                  isOpenSidebar={openSidebar}
                  activeConversationId={activeConversationId}
                  sx={{ ...(isSearchFocused && { display: 'none' }) }}
                />
              ) : (
                <ChatSearchResults query={searchQuery} results={searchResults} onSelectContact={handleSelectContact} />
              )}
            </Scrollbar>
            );
          }

            ///////////// ITENS DO TAB PAINEL
            const ItemPesquisar = () => {
              return (
                <Scrollbar>
                              {openSidebar && (
          <ChatContactSearch
            query={searchQuery}
            onFocus={handleSearchFocus}
            onChange={handleChangeSearch}
            onClickAway={handleClickAwaySearch}
            sx={{ minHeight:52,py: 1, px: 1}}
            
          />
        )}
                {!displayResults ? (
                  <ChatConversationList
                    conversations={conversations}
                    chats={chatsPendente}
                    isOpenSidebar={openSidebar}
                    activeConversationId={activeConversationId}
                    sx={{ ...(isSearchFocused && { display: 'none' }) }}
                  />
                ) : (
                  <ChatSearchResults query={searchQuery} results={searchResults} onSelectContact={handleSelectContact} />
                )}
              </Scrollbar>
              );
            }

           

  const SCROLLABLE_TAB = [
    { value: '1', icon: <Badge badgeContent={chatsAtendimento.length} color="primary"><BsChatText size={20} /></Badge>, component:<ItemAtendendo />, label:  <p>Atendendo({chatsAtendimento.length})</p>, hidden: false},
    { value: '2', icon: <BsInboxes size={20} />, hidden: false },
    { value: '3', icon:  <Badge badgeContent={chatsAtendimento.length} color="error"><BsClockHistory size={20} /></Badge>, component: <ItemPendente />, label: <p>Pendentes({chatsPendente.length})</p>, hidden: false},
     { value: '4', icon: <BsSearch size={20} />, component: <ItemPesquisar />, label: 'Finalizados', hidden: false },
     { value: '5', icon:  <Badge badgeContent={chatsFinalizado.length} color="error"><BsPatchCheck size={20} /></Badge>, component: <ItemFinalizado />, label: <p>Finalizados({chatsFinalizado.length})</p>, hidden: false},
  
  ];


  const handleChangeScrollable = (event, newValue) => {
    setValueScrollable(newValue);
  };

 ///


  useEffect(() => {

    if (isMobile) {
      return setOpenSidebar(false);
    }
    return setOpenSidebar(true);
  }, [isMobile]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (!openSidebar) {
      return setSearchFocused(false);
    }
  }, [openSidebar]);

  const handleClickAwaySearch = () => {
    setSearchFocused(false);
    setSearchQuery('');
  };

  const handleChangeSearch = async (event) => {
    try {
      const { value } = event.target;
      setSearchQuery(value);
      if (value) {
        const response = await axios.get('/api/chat/search', {
          params: { query: value }
        });
        setSearchResults(response.data.results);
      } else {
        setSearchResults([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSearchFocus = () => {
    setSearchFocused(true);
  };

  const handleSearchSelect = (username) => {
    setSearchFocused(false);
    setSearchQuery('');
    navigate(`${PATH_DASHBOARD.chat.root}/${username}`);
  };

  const handleSelectContact = (result) => {
    if (handleSearchSelect) {
      handleSearchSelect(result.username);
    }
  };



  return (
    <RootStyle sx={{ ...(!openSidebar && { width: 96 }) }}>
 

            
           <TabContext value={valueScrollable}>
           <Tabs
           //  allowScrollButtonsMobile
           orientation="vertical"
             value={valueScrollable}
             variant="fullWidth"
             //variant="standart"
        
             onChange={handleChangeScrollable}
           >
             {SCROLLABLE_TAB.map((tab) => (
   
                      <Tab  key={tab.value} icon={tab.icon} value={tab.value} disableFocusRipple={tab.hidden} />
            
         
              
             ))}
           </Tabs>
                 <Scrollbar style={{marginTop:10}}>
                 {SCROLLABLE_TAB.map((panel, index) => (
                     <TabPanel key={panel.value} value={String(index + 1)}>
                       {panel.component}
                     </TabPanel>
                   ))}
                 </Scrollbar>
        </TabContext>
     

 
    </RootStyle>
  );
}
