import React from 'react';
import "./User.css";
import { FiPlusCircle, FiMinusCircle } from 'react-icons/fi';

import Picture from "../../../Picture";

import { Grid } from '@mui/material';

const User = ({ user, selected, onSelect }) => {
    const title = `${user.firstName} ${user.lastName}`.substr(0, 22);

    return (
        <div style={{ display: 'flex' }} className="room" onClick={onSelect}>
            <Grid container spacing={2}>
            <Grid item md={3}>
               <div className="profile">
                <Picture user={user} />
            </div>
            </Grid>
            <Grid item md={7}>
            <div className="title">{title}{title.length > 22 && '...'}</div>

            </Grid>
            <Grid item md={2}>
                <div className={`button${selected ? ' selected' : ''}`}>
                    {selected ? <FiMinusCircle color="#f03" /> : <FiPlusCircle />}
                </div>
            </Grid>
            </Grid>
        </div>
    );
}

export default User;
