import {
  Divider,
  Box,
  Grid,
  Card,
  Avatar,
  Typography,
  Stack,
  Button,
  IconButton,
  Switch,
  Container
} from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import Iconify from 'src/components/Iconify';
import ModalChatGpt from './Modals/ModalChatGpt';
import Label from 'src/components/Label';
import actionListaIntegracoesByCliente from 'src/actions/clientes/actionListaIntegracoesByCliente';
import { HeaderPageComponentConfig } from 'src/components/_dashboard/configuracoes';
import actionEditarIntegracao from 'src/actions/clientes/actionEditarIntegracao';
import { useNavigate } from 'react-router';


// ----------------------------------------------------------------------

MainIntegracoes.propTypes = {
  cliente: PropTypes.object,
};

export default function MainIntegracoes({ cliente }) {
  const navigate = useNavigate();
  const [modalChatGpt, setModalChatGpt] = useState(false)
  const [integracaoIdChatgpt, setIntegracaoIdChatgpt] = useState('')
  const [tipoIntrucaoChatGpt, setTipoIntrucaoChatGpt] = useState('')
  const [chatGptStatus, setChatGptStatus] = useState(false)
  const [instrucaoChatgpt, setInstrucaoChatgpt] = useState('')
  const [chatGptApiKey, setChatGptApiKey] = useState('')
  const [chatGptOrganizationKey, setChatGptOrganizationKey] = useState('');

  // const editarChatGptIntegracao = async = () => {
  //   try {

  //   } catch(error) {
  //     console.log('error', error)
  //   }
  // }



  const buscarListaIntegracoesByCliente = async () => {
    try {
      const response = await actionListaIntegracoesByCliente(cliente._id);

      if (response.data && response.data.data.length > 0) {
        if (response.data.data.some(item => item.resource === "chatgpt")) {
          let dadosFiltrados = response.data.data.filter(item => item.resource === "chatgpt")[0];
          setIntegracaoIdChatgpt(dadosFiltrados._id)
          setInstrucaoChatgpt(dadosFiltrados.config.instrucao)
          setTipoIntrucaoChatGpt(dadosFiltrados.config.tipo_instrucao)
          setChatGptApiKey(dadosFiltrados.config.keys.LAIS_OPENAI_API_KEY)
          setChatGptOrganizationKey(dadosFiltrados.config.keys.LAIS_ORGANIZATION)
          setChatGptStatus(dadosFiltrados.status)
        }
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  const editarConfigChatGpt = async () => {
    try {
      let data = {
        scope: {
          clienteId: cliente._id,
          status: chatGptStatus,
          chatGptApiKey: chatGptApiKey,
          chatGptOrganizationKey: chatGptOrganizationKey,
          tipoInstrucao: tipoIntrucaoChatGpt,
          intrucao: instrucaoChatgpt
        },
        action: "editar_chatgpt",
      }
      await actionEditarIntegracao(integracaoIdChatgpt, data);
      buscarListaIntegracoesByCliente();
      setModalChatGpt(false)

    } catch (error) {
      console.log('error', error)
    }
  }

  const linkTo = (label) => {
    return `/dashboard/configuracoes/${label}`;

  };

  useEffect(() => {
    buscarListaIntegracoesByCliente()
  }, [])

  return (

    <Container maxWidth="lg">

      <HeaderPageComponentConfig
        titulo="Integrações"
        descricao={'Aqui você pode configurar as integrações com os canais de comunicação, webhooks e API.'}
      //actions={<Button variant='contained'>Nova Integraão</Button>}
      />


        <ModalChatGpt
          onSubmit={editarConfigChatGpt}
          open={modalChatGpt}
          chatGptStatus={chatGptStatus}
          changeStatusIntegracao={(e) => setChatGptStatus(e.target.checked)}
          changeChatGptApiKey={(e) => setChatGptApiKey(e.target.value)}
          changeChatGptOrganizationKey={(e) => setChatGptOrganizationKey(e.target.value)}
          chatGptApiKey={chatGptApiKey}
          chatGptOrganizationKey={chatGptOrganizationKey}
          onClose={() => setModalChatGpt(false)}
          tipoIntrucaoChatGpt={tipoIntrucaoChatGpt}
          instrucaoChatgpt={instrucaoChatgpt}
          changeInstrucaoChatgpt={(e) => setInstrucaoChatgpt(e.target.value)}
          changeTipoIntrucaoChatGpt={(e) => {
            setTipoIntrucaoChatGpt(e.target.value)
          }}
        />


        <Box
          sx={{
            display: 'grid',
            gap: 1,
            flexDirection: 'row',
            mt:2,
            gridTemplateColumns: {
              xs: 'repeat(2, 1fr)',
              sm: 'repeat(4, 1fr)',
              md: 'repeat(4, 1fr)',
              lg: 'repeat(4, 1fr)',
            },
          }}
        >
          <IntegracaoCard
            icon="mingcute:ai-line"
            nome="Copilot"
            descricao="Integre Inteligênica Artificial para obter sugestões de respostas para seus atendentes."
            showActive={true}
            statusIntegracao={chatGptStatus}

            onConfig={() => {
              setModalChatGpt(true)
            }}
          />
          <IntegracaoCard
            icon="material-symbols:webhook"
            nome="Webhooks"
            descricao="Receba notificações de eventos do sistema via Webhooks da Lais para criar integrações"
            statusIntegracao={false}
            onConfig={() => navigate(linkTo('webhooks'))}
          />

          {/* <Grid item xs={12} md={2}>
              <IntegracaoCard
                icon="uil:whatsapp-alt"
                nome="API Whatsapp"
                descricao="Envie mensagens de texto e arquoivos para seus clientes via API do Whatsapp"
                showActive={true}
                statusIntegracao={false}
                onConfig={() => setModalChatGpt(true)}
              />
            </Grid> */}
        </Box>


    </Container>
  );
}

// ----------------------------------------------------------------------

IntegracaoCard.propTypes = {
  item: PropTypes.object,
};

function IntegracaoCard({
  canal,
  nome,
  icon,
  botao,
  onConfig,
  statusIntegracao,
  showActive,
  changeStatusIntegracao,
  descricao
}) {


  return (
    <Box
      sx={{
        backgroundColor: theme => theme.palette.grey[200],
        border: '1px solid #e5e2f7',
        p: 1.5,
        borderRadius: 0.5,
        display: 'flex',
        position: 'relative',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <Stack alignItems={'center'} direction="column" spacing={2} sx={{ mb: 1 }}>
        {/* <Iconify icon={icon} width={30} height={30} /> */}
        <Stack alignItems={'left'}>
          <h4 className="scroll-m-20 text-xl font-semibold tracking-tight">
            {nome}
          </h4>
          <p className="leading-2 text-xs mt-2">
            {descricao}
          </p>
        </Stack>
      </Stack>
      <Stack spacing={2} alignItems={'center'} direction={'row'} sx={{ mt: 3 }}>
        {showActive && (
          <>
            <Label variant='outlined' color={statusIntegracao ? 'success' : 'error'}>{statusIntegracao ? 'Ativo' : 'Inativo'}</Label>
            <Divider orientation="vertical" flexItem />
          </>
        )}
        <IconButton onClick={onConfig}>
          <Iconify icon="ant-design:setting-filled" width={20} height={20} />
        </IconButton>

      </Stack>
    </Box>
  );
}



