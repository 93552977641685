import moment from "moment";
import React from 'react';
//import './Message.css';
import emojiRegex from 'emoji-regex';
import { FiDownloadCloud } from "react-icons/fi";
import ReactImageAppear from 'react-image-appear';

import Config from "src/config";

import { Card, Typography } from '@mui/material';
import useAuth from "src/hooks/useAuth";
import { convertUrls } from "src/utils/functions";
import { Dropdown } from "src/components/ui-component/dropdown/Dropdown";
import {

    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuPortal,
    DropdownMenuSeparator,
    DropdownMenuShortcut,
    DropdownMenuSub,
    DropdownMenuSubContent,
    DropdownMenuSubTrigger,
    DropdownMenuTrigger,
} from "src/@/components/ui/dropdown-menu"
import { Button } from "src/@/components/ui/button";
import Iconify from "src/components/Iconify";

const ChatInternoMessageItem = ({ message, previous, next, onOpen }) => {
    let { author, content, date } = message;

    const { user } = useAuth();

    if (!author) author = { firstName: 'Deleted', lastName: 'User' };
    if (previous && !previous.author) previous.author = { firstName: 'Deleted', lastName: 'User' };
    if (next && !next.author) next.author = { firstName: 'Deleted', lastName: 'User' };

    const isMine = user.id === author._id;

    let attachPrevious = false, attachNext = false;

    if (previous && Math.abs(moment(previous.date).diff(moment(date), 'minutos')) < 3 && author._id === previous.author._id)
        attachPrevious = true;
    if (next && Math.abs(moment(next.date).diff(moment(date), 'minutos')) < 3 && author._id === next.author._id)
        attachNext = true;


    const Details = ({ side }) => {
        if (!attachNext) return (
            <div className={`${side}`}>
                {moment(date).format('DD/MM - HH:mm')}
            </div>
        );
        else return null;
    };



    const openInNewTab = (url) => {
        window.open(url, '_blank').focus();
    }

    const NameOrSpacer = () => {
        if (attachPrevious) return <div className="spacer" />;
        else return (
            <div className="picture">
                <Typography sx={{ fontSize: 10, fontWeight: 'bold' }}>{author.nomeFantasia}</Typography>
            </div>
        );
    };

    const noEmoji = content ? content.replace(emojiRegex(), '') : '';
    const isOnlyEmoji = !noEmoji.replace(/[\s\n]/gm, '');

    const getBubble = () => {
        if (attachPrevious || isOnlyEmoji)
            if (isMine) return 'right';
            else return ' left';
        if (isMine) return ' bg-[#606cc1] text-white';
        else return ' bg-white';
    };



    const Content = () => {
        switch (message.type) {
            case 'image':
                return (
                    <ReactImageAppear
                        src={`${Config.urlWs || ''}/api/files/${message.content}`}
                        animationDuration="0.2s"
                        onClick={() => onOpen(message)}
                    />
                );
            case 'file':
                return (
                    <a href={() => openInNewTab(`${Config.urlWs || ''}/api/files/${message.content}`)}>
                        <div className="content-download">
                            <div className="content-file">
                                <div className="content-name">{message.file ? message.file.name : 'Arquivo'}</div>
                                {/* <div className="content-size">{message.file ? Math.round(message.file.size / 1024 / 1024 * 10 ) + 'Kb' : 'Size'}</div> */}
                            </div>
                            <div onClick={() => openInNewTab(`${Config.urlWs || ''}/api/files/${message.content}`)} className="content-icon"><FiDownloadCloud /></div>
                        </div>
                    </a>
                );
            default:
                return <div className="break-words whitespace-break-spaces text-[14px] py-5" dangerouslySetInnerHTML={{ __html: content && convertUrls(content) }} />
        }
    };

    return (
        <div className={`flex items-start my-5 ${!isMine ? 'justify-start' : 'justify-end'}`}>

            <div className={`min-w-[300px] max-w-[40vw] flex flex-col ${isMine ? ' right' : ''}`}>
                <div className={`p-2 rounded-sm shadow-sm ${getBubble()}`} >
                    <div className="flex flex-row justify-between">
                    <NameOrSpacer />
                        <DropdownMenu>
                            <DropdownMenuTrigger asChild>
                                <Button className="w-5 h-5" size="icon" variant="outline"><Iconify color="black" width={8} icon="fe:arrow-down" /></Button>
                            </DropdownMenuTrigger>
                            <DropdownMenuContent>
                                <DropdownMenuItem className="text-[10px]" onClick={() => {}}>
                                    Responder
                                </DropdownMenuItem>
                                <DropdownMenuItem  className="text-[10px]" onClick={() => {}}>
                                    Excluir
                                </DropdownMenuItem>
                            </DropdownMenuContent>
                        </DropdownMenu>
                    </div>
            
                    <Content />
                    {/* <Typography sx={{fontSize:8, fontWeight:'bold'}}>      {moment(date).format('DD/MM - HH:mm')}</Typography> */}
                </div>
                <div className="text-xs">
                    {moment(date).format('DD/MM - HH:mm')}
                </div>
            </div>
        </div>
    );
};

export default ChatInternoMessageItem;
