import { styled } from '@mui/material/styles';
import React, { useRef, useState, useCallback } from 'react';
import {
	Drawer,
	DialogActions,
	DialogContent,
	Box,
	Dialog,
	IconButton,
	Input,
	Stack,
	CircularProgress,
	Tooltip,
	LinearProgress
} from '@mui/material'
import { useSnackbar } from 'notistack';
import { useDropzone } from 'react-dropzone';
//hooks
import useResponsive from 'src/hooks/useResponsive';
//redux 
import { getChatsAtendimento, getChatsPendente, setDialogRespostas, setSelectedMessage, getCountsChats, setMensagensEnviar, resetMensagensEnviar, getChatInfoByChatId } from 'src/redux/slices/chat';
import { setIsOnlineUsers } from 'src/redux/slices/staff';
import { useDispatch, useSelector } from 'src/redux/store';
//services
import { getSession } from "src/services/auth";
//components
import ModalMensagensRapidas from "src/components/_chats/chat/components/ModalMensagensRapidas";
import RecordingTimer from "src/components/_chats/chat/components/RecordingTimer";
import Iconify from "src/components/Iconify";
import { UploadMultiFile } from 'src/components/upload';
import EmojiPicker from 'src/components/EmojiPicker';
//icons
import { AiOutlineCloseCircle } from "react-icons/ai";
import { FaCheck } from "react-icons/fa";
import { verifyFeatures, verifyRules } from "src/utils/functions";
import { setModalAlerta } from "src/redux/slices/global";
///-----
import config from "src/config";
import MicRecorder from "mic-recorder-to-mp3";
const recorder = new MicRecorder({ bitRate: 128 })
//zui
import { Card, CardContent } from 'src/@/components/ui/card';
import { Switch } from 'src/@/components/ui/switch';
import { Label } from 'src/@/components/ui/label';
import { Popover, PopoverContent, PopoverTrigger } from 'src/@/components/ui/popover';
import { RowInput } from 'src/components/forms/RowInput';
import { Button } from 'src/@/components/ui/button';
import { Textarea } from 'src/@/components/ui/textarea';
//actions
import v1_actionEnviarAudioBuffer from "src/actions/v1/whatsapp/v1_actionEnviarAudioBuffer";
import v1_actionEnviarArquivoBuffer from "src/actions/v1/whatsapp/v1_actionEnviarArquivoBuffer";
import v2_actionEnviarAudioBuffer from "src/actions/v2/whatsapp/v2_actionEnviarAudioBuffer";
import v1_actionEnviarArquivoWhatsapp from "src/actions/v1/whatsapp/v1_actionEnviarArquivoWhatsapp";
import actionCriarNotas from 'src/actions/chats/notas/actionCriarNotas';
import enviarAudioWhatsapp from "src/actions/chats/whatsapp/enviarAudioWhatsapp";
import v1_actionEnviarMenagemWhatsapp from "src/actions/v1/whatsapp/v1_actionEnviarMenagemWhatsapp";
import v1_actionEnviarVozWhatsapp from "src/actions/v1/whatsapp/v1_actionEnviarVozWhatsapp";
import v1_actionResponderMensagemWhatsapp from "src/actions/v1/whatsapp/v1_actionResponderMensagemWhatsapp";
import actionListaRespostasRapida from "src/actions/dashboard/respostas-rapidas/actionListaRespostasRapida";
import actionSendFileMeta from 'src/actions/chats/meta/actionSendFileMeta';
import actionSendMessageMeta from 'src/actions/chats/meta/actionSendMessageMeta';
import actionUploadFileMeta from 'src/actions/s3/actionUploadFileMeta';
import actionUrlToBase64 from "src/actions/global/actionUrlToBase64";
// ----------------------------------------------------------------------
const RootStyle = styled('Box')(() => ({
	display: 'flex',
	position: 'relative',
	alignItems: 'center',
	background: '#fff',
	marginLeft: 10,
	marginRight: 10,
	marginBottom: 10,
	borderRadius: 8,
	boxShadow: 10,
	elevation: 1,
	border: '1px solid #e0e0e0',
	//width:'100%'

}));



const ChatBottomBar = ({ user, cliente, chat, mensagens, onOpenDrawerGpt, disabled, ...other }) => {

	const fileInputRef = useRef(null);
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const dispatch = useDispatch();
	const { selectedMessage, dialogRespostasRapidas, chatInfo, mensagensEnviar } = useSelector((state) => state.chat);
	const [loading, setLoading] = useState(false);
	const [text, setText] = useState("");
	const [files, setFiles] = useState([]);
	const filesUrl = [];
	const [multiFile, setMultiFile] = useState(false);
	const [preview] = useState(false);
	const [loadingEnviarArquivos, setLoadingEnviarArquivos] = useState(false);
	const isDesktop = useResponsive('up', 'md');

	const [openDrawerAudio, setOpenDrawerAudio] = React.useState(false);
	const [loadingSendText, setLoadingSendText] = useState(false);
	const [assinarEnvio, setAssinarEnvio] = useState(true);
	const assinatura = '*_' + user.nomeFantasia + '_* \n';
	const assinaturaMeta = user.nomeFantasia + ':\n\n';
	const [pesquisaRespostaRapida, setPesquisaRespostaRapidas] = useState('');
	const [listaRepostasRapidas, setListaRespostasRapidas] = useState([]);
	const [isDocument, setIsDocument] = useState(false);

	/// audio recorder v2
	const [recording, setRecording] = useState(false);
	const [audioURL, setAudioURL] = useState('');
	const mediaRecorderRef = useRef(null);
	const audioChunksRef = useRef([]);

	const [openPopoverNotes, setOpenPopoverNotes] = useState(false);
	const [textNotes, setTextNotes] = useState('');



	const handleChangeAssinarEnvio = (event) => {
		setAssinarEnvio(event);
	};

	const buscarListaResponstasRapidas = async () => {
		try {
			const res = await actionListaRespostasRapida(user.clienteId, user.id);
			setListaRespostasRapidas(res.data.respostas);
		} catch (error) {
			console.log('error', error)
		}
	}

	const handleClickOpenDialogRespostasRapidas = () => {
		dispatch(setDialogRespostas(true));
		buscarListaResponstasRapidas()

	}



	const handleOnPaste = (event) => {
		const items = (event.clipboardData || event.originalEvent.clipboardData).items;
		let blob = null;
		for (let i = 0; i < items.length; i++) {
			if (items[i].type.indexOf("image") === 0) {
				blob = items[i].getAsFile();
			}
		}

		if (blob !== null) {
			setFiles([Object.assign(blob, { preview: URL.createObjectURL(blob) })])
			setMultiFile(true);
			const reader = new FileReader();
			reader.onload = function () {
				// console.log(event.target.result); 
				// console.log(event.target);
				//  setSrc(event.target.result);
			};
			reader.readAsDataURL(blob);
		}
	};


	async function uploadAndGetUrlMeta() {
		if (files.length > 0) {
			for (let file of files.map(item => item)) {
				console.log('file', file)
				var formData = new FormData();
				var imagefile = file;
				formData.append("file", imagefile);

				try {
					let querys = `?path=meta/instagram/${chat._id}`
					const response = await actionUploadFileMeta(formData, querys)
					if (!response.data.error) {
						filesUrl.push({ key: response.data.data.key, type: file.type })
						return filesUrl;
					}

				} catch (error) {
					console.log('erro ao upload arquivo', error)
				}

			}
		}
	}


	async function uploadAudioAndGetUrlMeta(file) {
		if (file) {
			var formData = new FormData();
			formData.append("file", file);
			try {
				console.log('INICIANDO UPLOAD DE ARQUIVOS 2', formData)
				let querys = `?path=meta/instagram/${chat._id}`
				const response = await actionUploadFileMeta(formData, querys)
				if (!response.data.error) {
					return { key: response.data.data.key, type: file.type, tipo: "audio" }
				}
			} catch (error) {
				console.log('erro ao upload arquivo', error)
			}
		}
	}

	const handleDropMultiFile = useCallback(
		(acceptedFiles) => {
			setFiles(acceptedFiles.map((file) => Object.assign(file, { preview: URL.createObjectURL(file) })));
			setMultiFile(true);
		},
		[setFiles]
	);

	const handleRemoveAll = () => {
		setFiles([]);
	};

	const handleRemove = (file) => {
		const filteredItems = files.filter((_file) => _file !== file);
		setFiles(filteredItems);
	};

	const { getRootProps } = useDropzone({
		accept: chat?.canal === "whatsapp" ? ['image/*', 'application/*'] : ['image/*', 'audio/*', 'video/*'],
		acceptStyle: { backgroundColor: "#0aafd9" },
		rejectStyle: { backgroundColor: "#0aafd9" },
		onDrop: acceptedFiles => {
			setMultiFile(true);
			setFiles(acceptedFiles.map(file => Object.assign(file, { preview: URL.createObjectURL(file) })));
		}
	});

	const toggleDrawer = () => () => {
		setOpenDrawerAudio(true);
	};

	async function onResponderMensagemWhatsapp(phone, meesageId, message) {
		try {
			let data = {
				phone: phone,
				messageId: meesageId,
				message: message,
			};
			const querys = `?sessao=${chatInfo.sessao}&canal=whatsapp`;
			await v1_actionResponderMensagemWhatsapp(querys, data);
		} catch (error) {
			console.log(error);
		}
	}

	function applyFilter(tableData, filterName) {
		if (filterName) {
			tableData = tableData.filter(
				(item) =>
					item.titulo.toLowerCase().indexOf(filterName.toLowerCase()) !== -1 ||
					item.resposta.toLowerCase().indexOf(filterName.toLowerCase()) !== -1
			);
		}
		return tableData;
	}
	const dataRespostasRapidasFiltered = applyFilter(listaRepostasRapidas, pesquisaRespostaRapida);

	const drawerAudio = () => (
		<div

			role="presentation"
			onClick={toggleDrawer(false)}
			onKeyDown={toggleDrawer(false)}
		>
			<div style={{
				display: "flex",
				alignItems: "center",
				alignContent: "middle"
			}}>
				<IconButton
					aria-label="cancelRecording"
					component="span"
					fontSize="large"
					disabled={loading}
					onClick={handleCancelAudio}
				>
					<AiOutlineCloseCircle color="red" />
				</IconButton>
				{loading ? (
					<div>
						<CircularProgress sx={{
							color: "green",
							opacity: "70%",
						}} />
					</div>
				) : (
					<RecordingTimer />
				)}

				<IconButton
					aria-label="sendRecordedAudio"
					component="span"
					onClick={handleUploadAudio}
					disabled={loading}
				>
					<FaCheck color="green" />
				</IconButton>
			</div>
		</div>
	);


	const onChangeText = e => {
		setText(e.target.value);
	}

	async function sendMessage() {

		if (chat.canal === "instagram") {
			try {
				let querys = `?sessao=${chat.sessao}&canal=instagram`;
				let body = {
					userId: chat.instagramUser?.id,
					message: user.nomeFantasia !== "" ? text : text
				}
				setText('');
				v1_actionEnviarMenagemWhatsapp(querys, body);
				dispatch(getChatsAtendimento(user.clienteId, user.id, user.setorId, 10, false));
				// dispatch(getCountsChats(user.clienteId, user.id, user.setorId));
				// dispatch(getChatsPendente(user.clienteId, user.id, user.setorId));

				setLoadingSendText(false);
			} catch (error) {
				alert('Erro ao enviar mensage')
			}

		} else {
	
			 if(text === '' || text === '\n' || text === ' ' || text === ' \n') {
				setText('');
				return;
			 }
				if (selectedMessage) {
					onResponderMensagemWhatsapp(chat.numeroCliente, selectedMessage.id, assinarEnvio && user.nomeFantasia !== "" ? assinatura + text : text);
					setText('');
					dispatch(setSelectedMessage({}));
					setLoadingSendText(false);
					dispatch(getChatsAtendimento(user.clienteId, user.id, user.setorId, 10, false));
					return;

				} else {
					try {
						let querys = `?sessao=${chat.sessao}&canal=whatsapp`;
						let body = {
							phone: chat.numeroCliente,
							message: assinarEnvio && user.nomeFantasia !== "" ? assinatura + text : text
						}
						setText('');
						setMensagensEnviarRedux('chat', false);
						v1_actionEnviarMenagemWhatsapp(querys, body);
						dispatch(getChatsAtendimento(user.clienteId, user.id, user.setorId, 10, false));
					} catch (error) {
						setText('');
						setLoadingSendText(false);
						dispatch(resetMensagensEnviar());
						if (error?.response?.status === 400) {
							const responseError = error.response?.data;
							if (responseError.statusConexao === "Disconnected") {
								dispatch(setModalAlerta({
									open: true,
									titulo: config.erroDesconexaoWhatsapp.titulo,
									mensagem: config.erroDesconexaoWhatsapp.mensagem,
								}))
							}
						} else {
							enqueueSnackbar(`Erro ao enviar mensagem`, {
								variant: 'error',
								autoHideDuration: 5000,
								anchorOrigin: { horizontal: 'right', vertical: 'bottom' }
							});
							setText('');
						}
			
					}
				}
			
		}
	}

	async function setMensagensEnviarRedux(type, isMedia, mimeType) {
		const novaData = new Date();
		try {
			let data = {
				"ack": 6,
				"preSend": true,
				"body": assinarEnvio && user.nomeFantasia !== "" ? assinatura + text : text,
				"chatId": `${chat.numeroCliente}@c.us`,
				"content": assinarEnvio && user.nomeFantasia !== "" ? assinatura + text : text,
				"from": `${cliente.numero}@c.us`,
				"fromMe": true,
				"mimeType": mimeType,
				"id": `true_${chat.numeroCliente}@c.us_${Math.floor((new Date()).getTime() / 1000) + Math.random().toString(15).substring(2, 7)}`,
				"isGroupMsg": false,
				"isMedia": isMedia,
				"sender": {
					"formattedName": "",
					"id": `${cliente.numero}@c.us`,
					"isMe": true,
					"profilePicThumbObj": null,
					"pushname": "",
					"verifiedName": ""
				},
				"t": novaData.getTime() / 1000,
				"timestamp": novaData.getTime() / 1000,
				"to": `${chat.numeroCliente}@c.us`,
				"type": type,
				"cliente_id":user.clienteId,
				"chat_id": chat._id
			}
			dispatch(setMensagensEnviar(data))
		} catch (error) {
			console.log(error)
		}

	}


	async function handleSelecinouRespostaRapida(resposta) {
		if (resposta.tipo === 'texto') {
			setText(resposta.resposta);
			dispatch(setDialogRespostas(false));
		} else {
			setLoadingSendText(true);
			dispatch(setDialogRespostas(false));
			if (resposta.files.length === 0) {
				setLoadingSendText(false);
				enqueueSnackbar(`Erro ao enviar resposta rápida!. Não há arquivos cadastrados`, {
					variant: 'error',
					autoHideDuration: 2000,
					anchorOrigin: { horizontal: 'left', vertical: 'bottom' }

				});
				return;
			}
			let key = resposta.files.length > 0 ? resposta.files[0].key : '';
			let type = resposta.files.length > 0 ? resposta.files[0].type : '';
			const res_base64 = await actionUrlToBase64(`https://lais-app.s3.us-east-2.amazonaws.com/${key}`)
			let querys = `?sessao=${chat.sessao}&canal=whatsapp`;
			let body = {
				phone: chat.numeroCliente,
				base64: `data:${type};base64,${res_base64.data.data}`,
				filename: cliente?.nome
			};
			try {
				if (type === 'audio/mp3') {
					let body = {
						phone: chat.numeroCliente,
						url: res_base64.data.data,
					}

					await setMensagensEnviarRedux('ptt', true, 'audio/mp3')
					await v1_actionEnviarVozWhatsapp(querys, body);
					setLoadingEnviarArquivos(false);
					setLoadingSendText(false);
				} else {
					await v1_actionEnviarArquivoWhatsapp(querys, body);
				}
				enqueueSnackbar(`Enviado com sucesso`, {
					variant: 'success',
					autoHideDuration: 5000,
					anchorOrigin: { horizontal: 'left', vertical: 'bottom' }
				});
				dispatch(resetMensagensEnviar());
				setLoadingEnviarArquivos(false);
				setLoadingSendText(false);

			} catch (error) {
				console.log('error', error)
				enqueueSnackbar(`Erro ao enviar arquivo`, {
					variant: 'error',
					autoHideDuration: 5000,
					anchorOrigin: { horizontal: 'left', vertical: 'bottom' }

				});
				dispatch(resetMensagensEnviar());
				setLoadingEnviarArquivos(false);
				setLoadingSendText(false);
			}
		}
	}

	async function onEnviarArquivosBuffer() {
		if (files.length > 0) {
			setLoadingEnviarArquivos(true);

			if (chat.canal === "instagram") {
				setLoadingEnviarArquivos(true);
				setMultiFile(false);
				setFiles([]);
				await uploadAndGetUrlMeta();
				for (const file of filesUrl.map(item => item)) {
					setLoadingEnviarArquivos(false);

					let querys = `?sessao=${chat.sessao}&canal=instagram`;
					let body = {
						scope: {
							messageType: "response",
							type: validaTipoArquivo(file.type),
							instagramUserId: chat.instagramUser?.id,
							url: `https://lais-app.s3.us-east-2.amazonaws.com/${file.key}`,

						}
					};
					await v1_actionEnviarArquivoWhatsapp(querys, body);

				}
				setLoadingEnviarArquivos(false);

			} else {
				await setMensagensEnviarRedux('video', true, 'video/mp4')
				setMultiFile(false);
				for (let file of files.map(item => item)) {
					console.log('file', file)
					const filenameFile = file.name;

					if (isDocument && verifyFeatures(cliente.features, 'lais_chat_enviar_arquivo_documento')) {

						try {
							const formData = new FormData();
							const filename = `${new Date().getTime()}_${filenameFile}`;
							formData.append("filename", filename);
							formData.append("file", file);
							formData.append("isDocument", true);
							formData.append("phone", chat.numeroCliente);
							let querys = `?sessao=${chat.sessao}&canal=whatsapp`;


							await v1_actionEnviarArquivoBuffer(querys, formData);
							dispatch(resetMensagensEnviar());
							enqueueSnackbar(`${filename} - Enviado com sucesso`, {
								variant: 'success',
								autoHideDuration: 5000,
								anchorOrigin: { horizontal: 'left', vertical: 'bottom' }
							});
						} catch (error) {
							if (error.response.status === 400) {
								const responseError = error.response?.data;
								if (responseError.statusConexao === "Disconnected") {
									dispatch(setModalAlerta({
										open: true,
										titulo: config.erroDesconexaoWhatsapp.titulo,
										mensagem: config.erroDesconexaoWhatsapp.mensagem,
									}))
								}
							}
							enqueueSnackbar(`Erro ao enviar arquivo`, {
								variant: 'error',
								autoHideDuration: 5000,
								anchorOrigin: { horizontal: 'left', vertical: 'bottom' }

							});
							dispatch(resetMensagensEnviar())
						}


					} else {
						try {
							const formData = new FormData();
							const filename = `${new Date().getTime()}_${filenameFile}`;
							formData.append("filename", filename);
							formData.append("file", file);
							formData.append("phone", chat.numeroCliente);
							let querys = `?sessao=${chat.sessao}&canal=whatsapp`;

							await v1_actionEnviarArquivoBuffer(querys, formData);
							dispatch(resetMensagensEnviar());
							enqueueSnackbar(`${filename} - Enviado com sucesso`, {
								variant: 'success',
								autoHideDuration: 5000,
								anchorOrigin: { horizontal: 'left', vertical: 'bottom' }
							});

						} catch (error) {
							if (error.response.status === 400) {
								const responseError = error.response?.data;
								if (responseError.statusConexao === "Disconnected") {
									dispatch(setModalAlerta({
										open: true,
										titulo: config.erroDesconexaoWhatsapp.titulo,
										mensagem: config.erroDesconexaoWhatsapp.mensagem,
									}))
								}
							}
							enqueueSnackbar(`Erro ao enviar arquivo`, {
								variant: 'error',
								autoHideDuration: 5000,
								anchorOrigin: { horizontal: 'left', vertical: 'bottom' }

							});
							dispatch(resetMensagensEnviar())
						}

					}
				}

				setLoadingEnviarArquivos(false);
				setMultiFile(false);
				setFiles([]);
			}
		}
	}




	function validaTipoArquivo(mime) {
		const includesImage = ["image/png", "image/jpg", "image/jpeg"];
		const includesVideo = ["video/webm", "video/ogg", "video/mp4", "video/avi"];
		const includesAudio = ["audio/webm", "audio/ogg", "audio/wav", "audio/mpeg", "audio/mp3"];

		if (includesImage.includes(mime)) {
			return "image";
		} else if (includesVideo.includes(mime)) {
			return "video";
		} else if (includesAudio.includes(mime)) {
			return "audio";
		}
	}

	const v2_startRecording = async () => {
		if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
			const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
			mediaRecorderRef.current = new MediaRecorder(stream);
			mediaRecorderRef.current.start();

			mediaRecorderRef.current.ondataavailable = (event) => {
				audioChunksRef.current.push(event.data);
			};

			setRecording(true);
		}
	};

	const v2_handleCancelAudio = async () => {
		try {
			mediaRecorderRef.current.stop();
			mediaRecorderRef.current.onstop = async () => {
				setRecording(false);
				audioChunksRef.current = [];
				setLoading(false);
			}
		} catch (err) {
			console.log(err);
		}
	};


	const v2_stopRecording = async () => {
		mediaRecorderRef.current.stop();
		mediaRecorderRef.current.onstop = async () => {
			console.log('canal', chat.canal)
			if (chat.canal === "whatsapp") {
				try {
					await setMensagensEnviarRedux('ptt', true, 'audio/mp3');
					const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/mp3' });
					setLoading(true);
					const formData = new FormData();
					const filename = `${new Date().getTime()}.mp3`;
					formData.append("filename", filename);
					formData.append("file", audioBlob);
					formData.append("phone", chat.numeroCliente);
					let querys = `?sessao=${chat.sessao}&canal=${chat.canal}`;
					await v2_actionEnviarAudioBuffer(querys, formData);
					dispatch(resetMensagensEnviar())
				} catch (err) {
					console.log(err);
				}

			}
			else if (chat.canal === "instagram") {
				try {
					// await setMensagensEnviarRedux('ptt', true, 'audio/mp3');
					const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/mp3' });
					console.log('audioBlob', audioBlob)
					setLoading(true);
					// const file = new File(audioBlob, "audio.mp3", {
					// 	type: audioBlob.type,
					// 	lastModified: Date.now()
					// });
					const res = await uploadAudioAndGetUrlMeta(audioBlob);
					// let querys = `?sessao=${chat.sessao}&canal=instagram`;
					// let body = {
					// 	scope: {
					// 		messageType: "response",
					// 		type: 'audio',
					// 		instagramUserId: chat.instagram_user_id,
					// 		url: `https://lais-app.s3.us-east-2.amazonaws.com/${res.key}`,

					// 	}
					// };
					// await v1_actionEnviarArquivoWhatsapp(querys, body);
					dispatch(resetMensagensEnviar())
				} catch (err) {
					console.log('error', err);
				}

			}
			setRecording(false);
			setLoading(false);
			//setAudioURL(audioUrl);
			setRecording(false);
			audioChunksRef.current = [];
		};
	};
	const handleUploadAudio = async () => {
		setLoading(true);

		if (chat.canal === "instagram") {
			const [buffer, blob] = await recorder.stop().getMp3()
			const file = new File(buffer, "audio.mp3", {
				type: blob.type,
				lastModified: Date.now()
			});
			const res = await uploadAudioAndGetUrlMeta(file);
			let data = {
				user_id: chat.instagram_user_id,
				content: `https://zindi-meta.s3.us-east-2.amazonaws.com/${res.key}`,
				type: validaTipoArquivo(res.type)
			}
			await actionSendFileMeta("instagram", data);
			setRecording(false);
			setLoading(false);

		} else {
			const [, blob] = await recorder.stop().getMp3();
			const reader = new FileReader();
			reader.readAsDataURL(blob);
			reader.onloadend = async function () {
				const base64data = reader.result;
				let querys = `?sessao=${chat.sessao}&canal=whatsapp`;
				let body = {
					phone: chat.numeroCliente,
					url: base64data,
				}

				try {
					await setMensagensEnviarRedux('ptt', true, 'audio/mp3')
					await v1_actionEnviarVozWhatsapp(querys, body);
					dispatch(resetMensagensEnviar())
				} catch (error) {
					dispatch(resetMensagensEnviar())
					if (error.response.status === 400) {
						const responseError = error.response?.data;
						if (responseError.statusConexao === "Disconnected") {
							dispatch(setModalAlerta({
								open: true,
								titulo: config.erroDesconexaoWhatsapp.titulo,
								mensagem: config.erroDesconexaoWhatsapp.mensagem,
							}))
						}
					}
				}

				//await enviarAudioWhatsapp({ url: base64data, phone: chat.numeroCliente })
			};

			setRecording(false);
			setLoading(false);
		}


	};

	const handleCancelAudio = async () => {
		try {
			await recorder.stop().getMp3();
			setRecording(false);
			dispatch(setIsOnlineUsers(true));
		} catch (err) {
			console.log(err);
		}
	};

	const handleKeyDown = (event) => {
		if (!event.shiftKey && event.key === 'Enter') {
		  event.preventDefault();
		  sendMessage();
		}
	  };

	  const handleKeyUp = (event) => {
		console.log('opa')
		// Outras lógicas que você possa ter no handleKeyUp
	  };


	async function handleAddNote() {
		try {
			let data = {
				nota: {
					body: textNotes,
					usuario_id: user.id,
					date: new Date()
				}
			}
			await actionCriarNotas(chat._id, data)
			setTextNotes('');
			dispatch(getChatInfoByChatId(chat._id, chat.funil_id, user.id, false));
			dispatch(getChatsAtendimento(user.clienteId, user.id, user.setorId, 100, false));
			setOpenPopoverNotes(false);
			enqueueSnackbar('Nota adicionada com sucesso', { variant: 'success', autoHideDuration: 3000, anchorOrigin: { vertical: 'top', horizontal: 'right' } });
		} catch (error) {
			enqueueSnackbar('Erro ao criar Nota', { variant: 'error', autoHideDuration: 3000, anchorOrigin: { vertical: 'top', horizontal: 'right' } });

		}
	}




	return (
		<Card {...getRootProps()} className="mx-2">
			{!isDesktop && recording && (
				<>
					<Drawer anchor={'bottom'} open={openDrawerAudio} onClose={toggleDrawer(false)}>
						{drawerAudio()}
					</Drawer>
				</>
			)}
			<Dialog fullWidth open={multiFile} onClose={() => setMultiFile(false)} >
				<DialogContent>
					<Box
						component="form"
						noValidate
						sx={{
							margin: 'auto',
							display: 'flex',
							width: 'fit-content',
							flexDirection: 'column'
						}}
					>
						<Stack spacing={2}>
							<UploadMultiFile
								canal={chat?.canal}
								isStaff={false}
								cliente={cliente}
								isDocument={isDocument}
								changeIsDocument={e => setIsDocument(e.target.checked)}
								loadingEnviarArquivos={loadingEnviarArquivos}
								showPreview={preview}
								files={files}
								onEnviarArquivos={onEnviarArquivosBuffer}
								onDrop={handleDropMultiFile}
								onRemove={handleRemove}
								onRemoveAll={handleRemoveAll}
							/>
						</Stack>
					</Box>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setMultiFile(false)} style={{ background: '#f03', color: "#fff" }} variant="outlined">
						Fechar
					</Button>

				</DialogActions>
			</Dialog>

			<ModalMensagensRapidas
				dialogRespostasRapidas={dialogRespostasRapidas}
				onClose={() => {
					dispatch(setDialogRespostas(false))
				}}
				data={dataRespostasRapidasFiltered}
				onSelect={resposta => handleSelecinouRespostaRapida(resposta)}
				changePesquisa={e => setPesquisaRespostaRapidas(e.target.value)}
				setPesquisa={setPesquisaRespostaRapidas}
				pesquisa={pesquisaRespostaRapida}
			/>


			<Stack sx={{ width: '100%', p: 1 }} direction="column">
				<Stack direction="column">
					<Stack direction={"row"}>
						{loadingSendText ? (
							<Box sx={{ width: '20%', height: 20, pt: 1 }}>
								<LinearProgress />
							</Box>
						) : (
							<div className='w-full flex items-center justify-between'>
								<div className="flex items-center gap-1 flex-row">

									<Stack direction={"row "} alignItems={"center"}>
										<IconButton onClick={() => setMultiFile(true)}>
											<Iconify color="primary" icon="material-symbols:attach-file-add-rounded" width={20} height={20} />
										</IconButton>
										{isDesktop && <EmojiPicker disabled={disabled} value={text} setValue={setText} />}
										<Tooltip title="Resposta rápidas">
											<IconButton onClick={handleClickOpenDialogRespostasRapidas}>
												<Iconify color="primary" icon="mdi:message-text-fast" width={22} height={22} />
											</IconButton>
										</Tooltip>

										{verifyRules(user.rules, 'chatgpt') && (
											<Tooltip title="Copilot">
												<IconButton onClick={onOpenDrawerGpt}>
													<Iconify color="primary" icon="mdi:stars" width={18} height={15} />
												</IconButton>
											</Tooltip>

										)}
									</Stack>
									<div className="flex items-center space-x-2">
										<Switch
											checked={assinarEnvio}
											onCheckedChange={handleChangeAssinarEnvio}
											id="airplane-mode" />
										<Label htmlFor="airplane-mode">Assinar</Label>
									</div>
								</div>


								<Popover onOpenChange={setOpenPopoverNotes} open={openPopoverNotes}>
									<PopoverTrigger asChild>
										<Button onClick={() => setOpenPopoverNotes(true)} variant="secondary" className="h-7 bg-violet-100">
											<Iconify className="mr-1 text-violet-900" icon="fluent:note-add-16-filled" width={15} height={15} />
											nova nota
										</Button>
									</PopoverTrigger>
									<PopoverContent className="w-80 min-h-[200px]">
										<div className='flex flex-col gap-5'>

											<div className="w-full bg-slate-100 p-2 rounded-sm flex flex-row justify-between items-center">
												<h4 className="font-medium leading-none">Criar nova nota</h4>
												<Button onClick={() => setOpenPopoverNotes(false)} variant="secondary" size="small">
													<Iconify icon="material-symbols:close" />
												</Button>

											</div>
											<div className="mt-2">
												<RowInput
													label="Nota"
													input={
														<Textarea
															value={textNotes}
															onChange={(e) => setTextNotes(e.target.value)}
															rows={4}
															multiline
														/>
													}
												/>
											</div>

											<Button onClick={handleAddNote} className="w-full">
												Criar
											</Button>

										</div>

									</PopoverContent>
								</Popover>
								{/* {chat?.canal === "instagram" && (
									<Tooltip title="Tempo restante para interação com o contato">
										<Stack spacing={.3} direction={"row"} alignItems={"center"}>
											<Iconify color="primary" icon="mingcute:time-line" width={18} height={18} />
											<CountdownTimer sx={{ ml: 2 }} targetDate={chat?.instagramUser?.lastDateMessageUser ? chat?.instagramUser?.lastDateMessageUser : "2024-05-54T21:00:00"} />
										</Stack>
									</Tooltip>
								)} */}
							</div>
						)}

					</Stack>

					<Stack direction={"row"}>
						<Input
							disabled={loadingSendText ? true : false}
							fullWidth={isDesktop ? true : false}
							value={text}
							maxRows={10}
							minRows={2}
							multiline={loadingSendText ? false : true}
							onPaste={handleOnPaste}
							disableUnderline
							onKeyDown={handleKeyDown} // Adicionando onKeyDown
							onKeyUp={handleKeyUp}
							onChange={onChangeText}
							className="bg-slate-100 mt-2  pl-2"
							placeholder={loadingSendText ? "Enviando mensagem..." : "Digite algo"}
						/>

						{text.length > 0 ?
							(
								<IconButton onClick={sendMessage}>
									<Iconify color="primary" icon="iconoir:send-solid" width={22} height={22} />
								</IconButton>
							)
							:
							(
								<>
									{recording ? (
										<div
											style={{
												display: "flex",
												alignItems: "center",
												alignContent: "middle"
											}}>
											<IconButton
												aria-label="cancelRecording"
												component="span"
												fontSize="large"
												disabled={loading}
												onClick={v2_handleCancelAudio}
											>
												<AiOutlineCloseCircle color="red" />
											</IconButton>
											{loading ? (
												<Box sx={{ width: 20 }}>
													<LinearProgress size={15} />
												</Box>
											) : (
												<RecordingTimer />
											)}

											<IconButton
												aria-label="sendRecordedAudio"
												component="span"
												//onClick={handleUploadAudio}
												//onClick={handleUploadAudioBuffer}
												onClick={v2_stopRecording}
												disabled={loading}
											>
												<Iconify color="success" icon="lets-icons:check-fill" width={28} height={28} />
											</IconButton>
										</div>
									) : (
										<IconButton
											disabled={loading}
											onClick={v2_startRecording}
										//onClick={handleStartRecording}
										>
											<Iconify icon="ph:microphone-fill" color="primary" width={22} height={22} />
										</IconButton>
									)}
								</>
							)}

						<input type="file" ref={fileInputRef} style={{ display: 'none' }} />
					</Stack>
				</Stack>
			</Stack>
		</Card>
	);
}

export default ChatBottomBar;
