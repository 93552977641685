import {
  Tab,
  // Tabs,
  Drawer, useMediaQuery, IconButton, Box,
  Badge,
  Divider
} from '@mui/material';

import { Stack, Typography } from '@mui/material';
import { useTheme, styled } from '@mui/material/styles';
import { useState, useEffect } from 'react';
// icons
import { BsChatText, BsClockHistory } from "react-icons/bs";
import { FaClock } from "react-icons/fa";
import { useNavigate, useLocation } from 'react-router-dom';

import useResponsive from 'src/hooks/useResponsive';
import { getChatsAtendimento, getChatsFinalizados, getChatsGeral, getChatsPendente, getCountsChats, setDrawerPesquisas } from 'src/redux/slices/chat'
import { useSelector, useDispatch } from 'src/redux/store';
// utils
// routes
import Scrollbar from 'src/components/Scrollbar';
import ChatConversationList from './ChatsList/ChatConversationList';
import ChatConversationListFinalizado from './ChatsList/ChatConversationListFinalizado';
import ChatConversationListGeral from './ChatsList/ChatConversationListGeral';
import ChatConversationListPendente from './ChatsList/ChatConversationListPendente';
import Iconify from 'src/components/Iconify';
import SkeletonListaChats from 'src/components/skeleton/chats/SkeletonListaChats';
import useAuth from 'src/hooks/useAuth';
import v2_actionFinalizarMultiplosChats from 'src/actions/v2/chats/v2_actionFinalizarMultiplosChats';
import { useSnackbar } from 'notistack';
import { badgeFuncNumber, verifyRules } from 'src/utils/functions';

import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "src/@/components/ui/tabs"

import { Button } from 'src/@/components/ui/button';
import { ButtonLoading } from 'src/@/components/use/ButtonLoading';
import ChatListPesquisas from './ChatsList/ChatListPesquisas';

import { useLayoutEffect, useRef, memo } from 'react';
// ----------------------------------------------------------------------

const ScrollableComponent = ({ children }) => {
  const scrollRef = useRef(null);
  const scrollPosition = useRef(0);

  // Captura a posição do scroll antes de qualquer atualização
  useLayoutEffect(() => {
    if (scrollRef.current) {
      scrollPosition.current = scrollRef.current.scrollTop;
    }
  }, [children]);

  // Restaura a posição do scroll após a atualização
  useLayoutEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollPosition.current;
    }
  }, [children]);

  return (
    <Scrollbar sx={{ height: 'calc(100vh - 200px)', }} ref={scrollRef}>
      {children}
    </Scrollbar>
  );
};


// Memoização para evitar re-renderizações desnecessárias
const ItemAtendendo = memo(function ItemAtendendo({
  chatsAtendimento,
  openSidebar,
  cliente,
  loadingListaChatsAtendendo,
  listaFunilReduce,
  listaTagsReduce,
  tagsSelecionadas,
  funisSelecionadas,
  chatsNaoLidos,
  dataFiltered,
  changeChatsNaoLidos,
  changeAccordion,
  changeFunis,
  changeTags,
  limparFiltro,
  isFiltered,
  expanded
}) {
  return (
    loadingListaChatsAtendendo ? (
      <SkeletonListaChats />
    ) : (
      <ScrollableComponent>
        <ChatConversationList
          cliente={cliente}
          chats={chatsAtendimento}
          isOpenSidebar={openSidebar}
          expanded={expanded}
          listaFunilReduce={listaFunilReduce}
          listaTagsReduce={listaTagsReduce}
          tagsSelecionadas={tagsSelecionadas}
          funisSelecionadas={funisSelecionadas}
          chatsNaoLidos={chatsNaoLidos}
          dataFiltered={dataFiltered}
          changeChatsNaoLidos={changeChatsNaoLidos}
          changeAccordion={changeAccordion}
          changeFunis={changeFunis}
          changeTags={changeTags}
          limparFiltro={limparFiltro}
          isFiltered={isFiltered}
        />
      </ScrollableComponent>
    )
  );
});

const ItemPendente = memo(function ItemPendente({
  chatsPendente,
  openSidebar,
  loadingListaChatsPendente
}) {
  return (
    loadingListaChatsPendente ? (
      <SkeletonListaChats />
    ) : (
      <ScrollableComponent>
        <ChatConversationListPendente
          chats={chatsPendente}
          isOpenSidebar={openSidebar}
        />
      </ScrollableComponent>
    )
  );
});

const ItemFinalizado = memo(function ItemFinalizado({
  loadingLimparChats,
  limparChats,
  chatsFinalizado,
  openSidebar,
  cliente,
  user,
  loadingListaChatsFinalizados
}) {
  return (
    loadingListaChatsFinalizados ? (
      <SkeletonListaChats />
    ) : (
      <ScrollableComponent>
        <ChatConversationListFinalizado
          cliente={cliente}
          chats={chatsFinalizado}
          isOpenSidebar={openSidebar}
        />
      </ScrollableComponent>
    )
  );
});

const ItemGeral = memo(function ItemGeral({
  limparChats,
  loadingLimparChats,
  chatsGeral,
  openSidebar,
  chatInfo,
  user,
  cliente,
  loadingListaChatsGeral
}) {
  return (
    loadingListaChatsGeral ? (
      <SkeletonListaChats />
    ) : (
      <ScrollableComponent>
        {chatsGeral?.length > 0 && verifyRules(user.rules, "finalizar_chat_massa") && (
          <Stack direction={"row"} justifyContent={'center'} alignItems={'center'} sx={{ mt: 0.5, mb: 0.5 }}>
            <ButtonLoading className="w-full bg-red-100" variant='secondary' onClick={limparChats} loading={loadingLimparChats}>
              <Iconify className="mr-2" icon="icon-park-solid:clear-format" />
              Limpar chats
            </ButtonLoading>
          </Stack>
        )}
        <ChatConversationListGeral
          cliente={cliente}
          chats={chatsGeral}
          chat={chatInfo}
          isOpenSidebar={openSidebar}
        />
      </ScrollableComponent>
    )
  );
});

export default function ChatSidebar() {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar()
  const { pathname } = useLocation();
  const [openSidebar, setOpenSidebar] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const { chatsAtendimento, chatInfo, chatsPendente, chatsGeral, chatsFinalizado, pesquisasChat, drawerPesquisasChat, countsListaChats, loadingListaChatsAtendendo, loadingListaChatsPendente, loadingListaChatsGeral, loadingListaChatsFinalizados } = useSelector((state) => state.chat);

  const isDesktop = useResponsive('up', 'md');
  const { user, cliente } = useAuth();
  const [valueScrollable, setValueScrollable] = useState('1');

  // filtro
  const { listaFunil, listaTags } = useSelector((state) => state.chat);
  const listaFunilReduce = listaFunil ? listaFunil.map((item) => item.nome_funil) : []
  const listaTagsReduce = listaTags ? listaTags.map((item) => item.nome) : []
  const tableData = chatsAtendimento;
  const [tagsSelecionadas, setTagsSelecionadas] = useState([]);
  const [funisSelecionadas, setFunisSelecionadas] = useState([]);
  const [chatsNaoLidos, setChatsNaoLidos] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const isFiltered = tagsSelecionadas.length > 0 || funisSelecionadas.length > 0 || chatsNaoLidos.length > 0;
  const dataFiltered = applySortFilter({
    tableData,
    filterName: 'nao',
    tagsSelecionadas,
    funisSelecionadas,
    chatsNaoLidos
  })
  const [loadingLimparChats, setLoadingLimparChats] = useState(false);

  const handleChangeAccordion = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleChangeTags = (event) => {
    const {
      target: { value },
    } = event;
    setTagsSelecionadas(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const handleChangeFunis = (event) => {
    const {
      target: { value },
    } = event;
    setFunisSelecionadas(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const handleChatsNaoLidos = (event) => {
    const {
      target: { value },
    } = event;
    setChatsNaoLidos(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };




  async function limparChats() {
    try {
      let data = {
        usuarioId: user.id,
        status: 'Geral',
        clienteId: cliente._id,

      }
      setLoadingLimparChats(true)
      const result = await v2_actionFinalizarMultiplosChats(data);
      if (!result.data.error) {
        dispatch(getChatsGeral(user.clienteId, user.id, user.setorId, 100, true));
        dispatch(getCountsChats(user.clienteId, user.id, user.setorId));
      }
      setLoadingLimparChats(false)
      enqueueSnackbar('Chats finalizados', { variant: 'success' });
    } catch (error) {
      setLoadingLimparChats(false)
      console.log(error);
      enqueueSnackbar('Erro ao limpar chats', { variant: 'error' });

    }
  }




  const SCROLLABLE_TAB = [
    {
      value: '1',
      counts: countsListaChats.count_atendendo,
      component: <ItemAtendendo
        loadingLimparChats={loadingLimparChats}
        limparChats={limparChats}
        chatsAtendimento={chatsAtendimento}
        openSidebar={openSidebar}
        user={user}
        cliente={cliente}
        loadingListaChatsAtendendo={loadingListaChatsAtendendo}
        listaFunilReduce={listaFunilReduce}
        listaTagsReduce={listaTagsReduce}
        tagsSelecionadas={tagsSelecionadas}
        funisSelecionadas={funisSelecionadas}
        chatsNaoLidos={chatsNaoLidos}
        dataFiltered={dataFiltered}
        changeChatsNaoLidos={handleChatsNaoLidos}
        changeAccordion={handleChangeAccordion}
        changeFunis={handleChangeFunis}
        changeTags={handleChangeTags}
        limparFiltro={() => {
          setTagsSelecionadas([]);
          setFunisSelecionadas([]);
          setChatsNaoLidos([]);
        }}
        isFiltered={isFiltered}
        expanded={expanded}
      />
      ,
      hidden: false,
      label: "Atendendo"
    },
    {
      value: '2',
      counts: countsListaChats.count_pendente,
      component: <ItemPendente
        chatsPendente={chatsPendente}
        openSidebar={openSidebar}
        loadingListaChatsPendente={loadingListaChatsPendente}
      />,
      hidden: false,
      label: "Em Fila"
    },
    {
      value: '3',
      counts: countsListaChats.count_geral,
      component: <ItemGeral
        limparChats={limparChats}
        loadingLimparChats={loadingLimparChats}
        chatsGeral={chatsGeral}
        openSidebar={openSidebar}
        chatInfo={chatInfo}
        user={user}
        cliente={cliente}
        loadingListaChatsGeral={loadingListaChatsGeral}
      />,
      hidden: false,
      label: "No Bot"
    },
    {
      value: '4',
      counts: countsListaChats.count_finalizado,
      component: <ItemFinalizado
        loadingLimparChats={loadingLimparChats}
        limparChats={limparChats}
        chatsFinalizado={chatsFinalizado}
        openSidebar={openSidebar}
        cliente={cliente}
        user={user}
        loadingListaChatsFinalizados={loadingListaChatsFinalizados}
      />,
      hidden: false,
      label: "Finalizados"
    }

  ];

  const handleChangeScrollable = (newValue) => {
  
    if(newValue === '1') {
      dispatch(getChatsAtendimento(user.clienteId, user.id, user.setorId, 30, false));
    } else if (newValue === '2') {
      dispatch(getChatsPendente(user.clienteId, user.id, user.setorId, 50, false));

    } else if (newValue === '3') {
      dispatch(getChatsGeral(cliente._id, user.id, user.setorId, 50, false));
    } else if (newValue === '4') {
      dispatch(getChatsFinalizados(cliente._id, user.id, user.setorId, 50, true));
    }
    setValueScrollable(newValue);
  };



  useEffect(() => {
    if (!isDesktop) {
      return handleCloseSidebar();
    }
    return handleOpenSidebar();
  }, [isDesktop, pathname]);

  // eslint-disable-next-line consistent-return

  const handleOpenSidebar = () => {
    setOpenSidebar(true);
  };

  const handleCloseSidebar = () => {
    setOpenSidebar(false);
  };

  const handleToggleSidebar = () => {
    setOpenSidebar((prev) => !prev);
  };


  const renderContent = (
    <div>
      {
        drawerPesquisasChat ? (

          <div className="w-[450px]">
            <Stack direction={'row'} spacing={2} sx={{ alignItems: 'center', my: 1 }}>
              <Button
                onClick={() => dispatch(setDrawerPesquisas(false))}
                size="icon"
                variant="outline"
              >
                <Iconify icon="ep:back" />
              </Button>
              <small className="text-md font-medium leading-none">{pesquisasChat.length} resultados encontrados... </small>
            </Stack>
            <Scrollbar sx={{ height: 'calc(90vh - 200px)', }}>
              <ChatListPesquisas
                query={searchQuery}
                results={pesquisasChat}
                callback={() => {
                  console.log('callback')
                  dispatch(setDrawerPesquisas(false))
                }}
              />
            </Scrollbar>
          </div>
        ) : (
          <div className='flex min-w-[410px]'>
            <Tabs forceMount onValueChange={handleChangeScrollable} value={valueScrollable} defaultValue="1" className="w-[450px] h-full">
              <TabsList className="grid bg-slate-200 grid-cols-4 mx-2">
                {SCROLLABLE_TAB.map((tab, index) => (
                  <TabsTrigger value={tab.value}>
                    <Stack alignItems={"center"} direction={"row"} spacing={0.5}>
                        <small className="text-xs font-medium leading-none">{tab.label}</small>
                      <Stack>
                      <Typography
                          sx={{
                            fontWeight: '500',
                            fontSize: 10,
                            color: '#fff',
                            px: 0.6,
                            borderRadius: 0.5
                          }}
                          className={`bg-${index === 1 ? 'red' : 'violet'}-500`}
                      >
                        {badgeFuncNumber(tab.counts)}
                      </Typography>
                        </Stack>
                      
                    </Stack>
                  </TabsTrigger>
                ))}
              </TabsList>
              {SCROLLABLE_TAB.map((panel) => (
                <TabsContent key={panel.value} value={panel.value}>
                  <div className='ml-2 mr-1 h-full'>
                    {panel.component}
                  </div>
                </TabsContent>
              ))}
            </Tabs>
          </div>
        )
      }
    </div>
  );

  return (
    <div className='flex min-w-[410px]'>
      {renderContent}
    </div>
  );
}

function applySortFilter({
  tableData,
  filterName,
  tagsSelecionadas,
  funisSelecionadas,
  chatsNaoLidos
}) {
  let filteredData = [];

  function mapStatus(status) {
    const statusMap = {
      "Chats não lidos": "sim",
      "Chats lidos": "nao"
    };

    return statusMap[status] || status;
  }

  const chatsNaoLidoMapped = chatsNaoLidos.map(mapStatus);

  if (tagsSelecionadas.length > 0) {
    tableData.forEach((item) => {
      if (item.tags_ids && Array.isArray(item.tags_ids)) {
        const filteredTags = item.tags_ids.filter((tag) => tagsSelecionadas.includes(tag.nome));

        if (filteredTags.length > 0) {
          // Se algum dos tags_ids corresponder ao filtro, adiciona o item ao novo array

          filteredData.push(item);
        }
      }
    });
    tableData = filteredData;
  }

  if (funisSelecionadas.length > 0) {
    tableData.forEach((item) => {
      if (funisSelecionadas.includes(item.nome_funil)) {
        // Se a novaMensagem estiver incluída em chatsNaoLidos, adiciona o item ao novo array
        filteredData.push(item);
      }
    });
    tableData = filteredData;
  }

  if (chatsNaoLidos.length > 0) {
    tableData.forEach((item) => {
      if (chatsNaoLidoMapped.includes(item.novaMensagem)) {
        // Se a novaMensagem estiver incluída em chatsNaoLidos, adiciona o item ao novo array
        filteredData.push(item);
      }
    });
    tableData = filteredData;
  }


  return tableData;
}
