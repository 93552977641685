
import { Tab, Box, Tabs, Stack, Divider, Dialog, Container } from '@mui/material';
import { Typography } from '@mui/material';
import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Iconify from 'src/components/Iconify';
import CanaisConfig from './CanaisConfig';
import v2_actionListUsersByClient from 'src/actions/v2/usuarios/v2_actionListUsersByClient';
import v2_actionListSectorsByClient from 'src/actions/v2/cliente/v2_actionListSectorsByClient';
import useAuth from 'src/hooks/useAuth';
import v2_actionDetalhesCanal from 'src/actions/v2/cliente/canais/v2_actionDetalhesCanal';
import v2_actionEditarCanal from 'src/actions/v2/cliente/canais/v2_actionEditarCanal';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { Button } from 'src/@/components/ui/button';
import { ButtonLoading } from 'src/@/components/use/ButtonLoading';

export default function CanaisDetails({ canalData }) {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const { user, cliente } = useAuth();
    const [isEdit, setIsEdit] = useState(true);
    const [listUsers, setListUsers] = useState([]);
    const [listSectors, setListSectors] = useState([]);
    const [loading, setLoading] = useState(false);

    const location = useLocation();
    // Extrai o parâmetro de consulta "channel" da string de busca (search) da URL
    const params = new URLSearchParams(location.search);
    const channel = params.get('channelId');
    const isNew = params.get('new');

    const [canal, setCanal] = useState({
        nome: '',
        status: true,
        platform: 'whatsapp',
        date: new Date(),
        principal: true,
        sessao: '',
        pod: '',
        descontinuado: false,
        webhook_type: ''
    });

    const [configAi, setConfigAi] = useState({
        prompt: '',
        provider: '',
        openai: {
            apiKey: '',
            organization: '',
            model: ''
        },
        gemini: {
            apiKey: '',
            model: ''
        },
        allowsInChat: [],
        messages: {
            msgAfterTargeting: '',
            msgBreakFiles: "Não é permitido enviar arquivos",
            msgBreakAudios: "Não é permitido enviar audios",
            msgImages: "No momento nao consigo ver sua imagem, por fvor envie um audio ou mensgem de texto",
            messageCallback: "Nao consegue entender sua mensagem, pode enviar uma mensagem de texto?"
        },
    });

    const [config, setConfig] = useState({
        token: '',
        usuario: '',
        sessao: '',
        dialogflow: {
            privateKey: '',
            status: true,
            projectId: '',
            clientEmail: ''
        },
        direcionamento_direto: {
            direcionado_para: '',
            direciona_setor: [],
            direciona_usuario: [],
            statusRoom: '',
            mensagem_apos_cadastro: ''
        },
        aplicativo: {
            status: true
        },
        incomingcall: {
            status: true,
            message: ''
        },
        flowbuilder: {
            flowId: ''
        },
        ai: configAi,
        laisGo: {
            status: false
        },
        departamentConfig: {
            allocate: {
                status:false,
                type:"round_robin"
            }
        }
    });



    let makeChannel = {
        ...canal,
        config: config
    }

    async function getDetailsChannel() {
        try {
            const response = await v2_actionDetalhesCanal(channel);
            const responseChannel = response.data.data;
            if (responseChannel) {
                setCanal({
                    ...canal,
                    nome: responseChannel.nome,
                    platform: responseChannel.platform,
                    webhook_type: responseChannel.webhook_type
                })
                setConfig(responseChannel.config)
            }
        } catch (error) {
            console.log(error);
        }
    }


    async function createChannel() {
        try {
            let data = {
                action: "editar_canal_front",
                scope: makeChannel
            }
            setLoading(true);
            await v2_actionEditarCanal(channel, data)
            enqueueSnackbar('Canal salvo com sucesso', { variant: 'success' });
            setLoading(false);

        } catch (error) {
            setLoading(false);
            enqueueSnackbar('Erro ao salvar canal', { variant: 'error' });

            console.log(error);
        }
    }

    async function getListUsers() {
        try {
            const response = await v2_actionListUsersByClient(user.clienteId);
            console.log('lsit Users', response.data.data)
            setListUsers(response.data.data)
        } catch (error) {
            console.log(error);
        }
    }

    async function getListSectors() {
        try {
            const response = await v2_actionListSectorsByClient(user.clienteId);
            setListSectors(response.data.data)
        } catch (error) {
            console.log(error);
        }
    }

    async function handleOnChangeChannel(e, newValue) {
        const { name, value, } = e.target;

        switch (name) {
            case 'channelName':
                setCanal({ ...canal, nome: value })
                break;
            case 'statusRoomDirectTarget':
                setConfig({ ...config, direcionamento_direto: { ...config.direcionamento_direto, statusRoom: value } })
                break;
            case 'channelType':
                setCanal({ ...canal, platform: value })
                break;
            case 'dialogflowPrivateKey':
                setConfig({ ...config, dialogflow: { ...config.dialogflow, privateKey: value } })
                break;
            case 'dialogflowProjectId':
                setConfig({ ...config, dialogflow: { ...config.dialogflow, projectId: value } })
                break;
            case 'dialogflowClientEmail':
                setConfig({ ...config, dialogflow: { ...config.dialogflow, clientEmail: value } })
                break;
            case 'providerAi':
                setConfig({ ...config, ai: { ...config.ai, provider: value } });
                break;
            case 'openaiApiKey':
                setConfig({ ...config, ai: { ...config.ai, openai: { ...config.ai.openai, apiKey: value } } });
                break;
            case 'openaiOrganization':
                setConfig({ ...config, ai: { ...config.ai, openai: { ...config.ai.openai, organization: value } } });
                break;
            case 'geminiApiKey':
                setConfig({ ...config, ai: { ...config.ai, gemini: { ...config.ai.gemini, apiKey: value } } });
                break;
            case 'modelGemini':
                setConfig({ ...config, ai: { ...config.ai, gemini: { ...config.ai.gemini, model: value } } });
                break;
            case 'messageCallback':
                setConfig({ ...config, ai: { ...config.ai, messages: { ...config.ai.messages, messageCallback: value } } });
                break;
            case 'modelOpenai':
                setConfig({ ...config, ai: { ...config.ai, openai: { ...config.ai.openai, model: value } } });
                break;
            case 'apiOpenai':
                setConfig({ ...config, ai: { ...config.ai, openai: { ...config.ai.openai, apiKey: value } } });
                break;
            case 'apiKeyGemini':
                setConfig({ ...config, ai: { ...config.ai, gemini: { ...config.ai.gemini, apiKey: value } } });
                break;
            case 'apiKeyOpenai':
                setConfig({ ...config, ai: { ...config.ai, openai: { ...config.ai.openai, apiKey: value } } });
                break;
            case 'organizationOpenai':
                setConfig({ ...config, ai: { ...config.ai, openai: { ...config.ai.openai, organization: value } } });
                break;
            case 'msgAfterTargeting':
                setConfig({ ...config, ai: { ...config.ai, messages: { ...config.ai.messages, msgAfterTargeting: value } } });
                break;
            case 'msgBreakFiles':
                setConfig({ ...config, ai: { ...config.ai, messages: { ...config.ai.messages, msgBreakFiles: value } } });
                break;
            case 'msgBreakAudios':
                setConfig({ ...config, ai: { ...config.ai, messages: { ...config.ai.messages, msgBreakAudios: value } } });
                break;
            case 'msgBreakImages':
                setConfig({ ...config, ai: { ...config.ai, messages: { ...config.ai.messages, msgImages: value } } });
                break;
            case 'prompt':
                setConfig({ ...config, ai: { ...config.ai, prompt: value } });
                break;
            case 'directTargetMessage':
                setConfig({ ...config, direcionamento_direto: { ...config.direcionamento_direto, mensagem_apos_cadastro: value } });
                break;
            case 'laisai_v2.ai_agent_id':
                setConfig({ ...config, laisai_v2: { ...config.laisai_v2, ai_agent_id: value } });
                break;

            case 'generalConfig.msgs.finish':
                setConfig({ ...config, generalConfig: { ...config.generalConfig, msgs: { ...config.generalConfig.msgs, finish: value } } });
                break;
            case 'generalConfig.msgs.feedback':
                setConfig({ ...config, generalConfig: { ...config.generalConfig, msgs: { ...config.generalConfig.msgs, feedback: value } } });
                break;
            case 'generalConfig.msgs.welcome':
                setConfig({ ...config, generalConfig: { ...config.generalConfig, msgs: { ...config.generalConfig.msgs, welcome: value } } });
                break;
            case 'generalConfig.msgs.transfer':
                setConfig({ ...config, generalConfig: { ...config.generalConfig, msgs: { ...config.generalConfig.msgs, transfer: value } } });
                break;
            case 'generalConfig.msgs.afterTarget':
                setConfig({ ...config, generalConfig: { ...config.generalConfig, msgs: { ...config.generalConfig.msgs, afterTarget: value } } });
                break;
            case 'generalConfig.msgTransfer':
                setConfig({ ...config, generalConfig: { ...config.generalConfig, msgTransfer: value } });
                break;
            case 'generalConfig.msgFinish':
                setConfig({ ...config, generalConfig: { ...config.generalConfig, msgFinish: value } });
                break;
            case 'generalConfig.feedback':
                setConfig({ ...config, generalConfig: { ...config.generalConfig, feedback: value } });
                break;
            case 'laisGo.status':
                setConfig({ ...config, laisGo: { ...config.laisGo, status: value } });
                break;
                case 'departamentConfig.allocate.status':
                    setConfig({ ...config, departamentConfig: { ...config.departamentConfig, allocate: { ...config.departamentConfig.allocate, status: value } } });
                    break;
                case 'departamentConfig.allocate.type':
                    setConfig({ ...config, departamentConfig: { ...config.departamentConfig, allocate: { ...config.departamentConfig.allocate, type: value } } });
                    break;
                default:
                break;
        }

    }


    async function handleOnChangeAutomCompleteChannel(e, newValue, name) {

        const mapping = newValue.map(item => item._id);
        switch (name) {
            case 'directTargetUser':
                setConfig({ ...config, direcionamento_direto: { ...config.direcionamento_direto, direciona_usuario: mapping } })
                break;
            case 'directTargetSector':
                setConfig({ ...config, direcionamento_direto: { ...config.direcionamento_direto, direciona_setor: mapping } })
                break;
            default:
                break;
        }

    }


    useEffect(() => {
        console.log('useEffect')
        getListUsers()
        getListSectors()

        getDetailsChannel()

    }, []);



    return (
        <Container maxWidth="lg">
            <Box sx={{ p: 1.5 }}>
                <Stack spacing={1.5}>
                    <Box sx={{ width: '100%' }}>
                        <Stack direction={"row"} justifyContent={"space-between"}>
                            <Stack spacing={1} alignItems={"center"} direction={"row"}>



                                <Button
                                    size='icon'
                                    variant='outline'
                                    onClick={
                                        () => {
                                            navigate('/dashboard/configuracoes/canais')
                                        }}
                                >
                                    <Iconify icon="majesticons:arrow-left" color="black" />
                                </Button>
                            </Stack>

                            <ButtonLoading
                                loading={loading}
                                onClick={createChannel}
                            >
                                {isEdit ? 'salvar' : 'criar canal'}
                            </ButtonLoading>

                        </Stack>
                    </Box>
                    <Divider sx={{ borderStyle: 'dashed' }} />
                    <Stack sx={{ mt: 1 }} spacing={2}>
                        <CanaisConfig
                            cliente={cliente}
                            user={user}
                            listUsers={listUsers}
                            listSectors={listSectors}
                            isEdit={isEdit}
                            config={config}
                            canal={makeChannel}
                            onChange={(e, newValue) => handleOnChangeChannel(e, newValue)}
                            onChangeAutocomplete={(e, newValue, name) => handleOnChangeAutomCompleteChannel(e, newValue, name)}
                            changeAgent={value => {
                                setConfig({ ...config, dialogflow: { ...config.dialogflow, projectId: value } })
                            }}
                            onChangeChannel={value => {
                                if (value.target === "channelType") {
                                    setCanal({ ...canal, platform: value.value })
                                } else if (value.target === "webhookType") {
                                    setCanal({ ...canal, webhook_type: value.value })
                                }
                            }}
                        />
                    </Stack>
                </Stack>
            </Box>
        </Container>
    );
}
