
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
import { Icon } from '@iconify/react';
import { Box, Link, Avatar, Typography, AvatarGroup, Chip } from '@mui/material';
import { styled } from "@mui/material/styles";
import PropTypes from 'prop-types';
import { BsFunnelFill  } from "react-icons/bs";

import {formataNumeroCelular} from '../../../utils/functions';

const RootStyle = styled('div')(({ theme }) => ({
  flexShrink: 0,
  minHeight: 52,
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 2)
}));

// ----------------------------------------------------------------------

OneAvatar.propTypes = {
  participants: PropTypes.array
};

function OneAvatar({ participants, chat }) {
  const participant = [...participants][0];



  if (participant === undefined) {
    return null;
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ position: 'relative' }}>
        <Avatar         sx={{ width: 24, height: 24 }} src={participant.avatar} alt={chat.nomeCliente} />
        {/* <BadgeStatus status={participant.status} sx={{ position: 'absolute', right: 2, bottom: 2 }} /> */}
      </Box>
      <Box sx={{  display:"flex", ml: 0 }}>
        <Box sx={{ lineHeight:0, display:"block", ml: 2 }}>
        <Typography  variant="subtitle2"> {formataNumeroCelular(chat.numeroCliente)}</Typography>
        </Box>
      
        <Chip
            icon={<BsFunnelFill size={10} />}
            color="primary"
            variant="outlined"
            size="small"
            label="Teste"
            sx={{ ml: 2}}
            //onDelete={handleDelete}
           // deleteIcon={<DoneIcon />}
          />

      </Box>
    </Box>
  );
}

GroupAvatar.propTypes = {
  participants: PropTypes.array
};

function GroupAvatar({ participants }) {
  return (
    <div>
      <AvatarGroup
        max={3}
        sx={{
          mb: 0.5,
          '& .MuiAvatar-root': { width: 32, height: 32 }
        }}
      >
        {participants.map((participant) => (
          <Avatar key={participant.id} alt={participant.name} src={participant.avatar} />
        ))}
      </AvatarGroup>
      <Link variant="body2" underline="none" component="button" color="text.secondary" onClick={() => {}}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {participants.length} persons
          <Icon icon={arrowIosForwardFill} />
        </Box>
      </Link>
    </div>
  );
}

ChatHeaderDetail.propTypes = {
  participants: PropTypes.array
};

export default function ChatHeaderDetail({ chat, participants, ...other }) {
  const isGroup = participants.length > 1;

  return (
    <RootStyle {...other}>
      {isGroup ? <GroupAvatar participants={participants} /> : <OneAvatar chat={chat} participants={participants} />}


      <Box sx={{ flexGrow: 1 }} />

    
      
    </RootStyle>
  );
}
