import { format, getTime, formatDistanceToNow, formatDistanceToNowStrict } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
// ----------------------------------------------------------------------

export function fDate(date) {
  return format(new Date(date), 'dd/MM/yyyy');
}

export function fDateTime(date) {
  return format(new Date(date), 'dd/MM/yyyy HH:mm', {
    addSuffix: true,
    locale: ptBR

  });
}

export function fTimestamp(date) {
  return getTime(new Date(date));
}

export function fDateTimeSuffix(date) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}

export function fTime(date) {
  return format(new Date(date), 'hh:mm');
}

export function fToNow(date) {
  return formatDistanceToNowStrict(new Date(date), {
    addSuffix: false,
    locale: ptBR

  });
}

export function isDateValid(dateString) {
  // Tenta criar um objeto de data com a string fornecida
  const date = new Date(dateString);
  
  // Verifica se a data é válida
  return !isNaN(date) && date.toString() !== '';
}
