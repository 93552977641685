import { Box, Divider } from '@mui/material';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { setRemoveRoomUnread } from 'src/redux/slices/staff';
import { useDispatch, useSelector } from 'src/redux/store';
import BottomBar from 'src/components/_chats/staff/components/BottomBar';
import useAuth from 'src/hooks/useAuth';
import ChatInternoMessagesList from './ChatInternoMessagesList';
import ChatInternoHeaderDetail from './ChatInternoHeaderDetail';


export default function ChatInternoWindow() {
  const dispatch = useDispatch();
  const { conversationKey } = useParams();
  const { staff } = useSelector((state) => state.staff);
  const {user} = useAuth()


 useEffect(() => {
    if (conversationKey) {
      dispatch(setRemoveRoomUnread(conversationKey));
    }
  }, [dispatch, conversationKey]);



  return (
    <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
      {/* <TopBar chat={staff} /> */}
      <ChatInternoHeaderDetail chat={staff} />
      <Divider />
      <Box sx={{ flexGrow: 1, display: 'flex', overflow: 'hidden' }}>
        <Box sx={{ display: 'flex', flexGrow: 1, flexDirection: 'column' }}>
          <ChatInternoMessagesList />
          <Divider />
          <BottomBar user={user} chat={staff} />
        </Box>
      </Box>

    </Box>
  );
}
