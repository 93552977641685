import { useEffect, useState, useRef } from 'react';
// material
import { Stack, Table, Box, TableRow, TableHead, TableBody, TableCell, TableContainer, IconButton, Grid, TextField, Button, Typography, Card, Container, Paper, FormControl, InputBase } from '@mui/material';

import Scrollbar from 'src/components/Scrollbar';
// configs
import { nomeProjeto } from 'src/config';
import useAuth from 'src/hooks/useAuth';
import { resultTimes, validaLabelMenuDatas } from 'src/utils/functions';
import moment from 'moment';
import v2_actionRelatorioChamados from 'src/actions/v2/dashboard/relatorios/v2_actionRelatorioChamados';
import v2_actionRelatorioChamadosUsuarios from 'src/actions/v2/dashboard/relatorios/v2_actionRelatorioChamadosUsuarios';
import MenuDatas2 from 'src/components/MenuDatas2';
import { RowInput } from 'src/components/forms/RowInput';


const Widget = ({ title, total, icon }) => {
  return (
    <div className='flex flex-row gap-2 rounded bg-slate-100 p-3'>
      <div>
        <Typography color={theme => theme.palette.grey[900]} variant="h3">{total}</Typography>
        <p className="text-xs">
          {title}
        </p>
      </div>
    </div>
  );
}

export default function RelatorioChamados() {
  const { user } = useAuth();
  const [isPesquisado, setIsPesquisado] = useState(false);
  const [inicial, setInicial] = useState(new Date());
  const [final, setFinal] = useState(new Date());

  //força vendas
  const [metricasChamados, setMetricasChamados] = useState(null);
  const [listaMetricasUsuarios, setListaMetricasUsuarios] = useState([]);
  const [periodo, setPeriodo] = useState('mes_atual')
  const [filterPesquisa, setFilterPesquisa] = useState('mes_atual')
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenuDatas = Boolean(anchorEl);


  useEffect(() => {
    handleBuscarDadosChamados(periodo);

  }, [inicial, final]);


  const handleBuscarDadosChamados = async (value) => {
    try {
      let inicio2 = moment(inicial).format("YYYY-MM-DD");
      let final2 = moment(final).format("YYYY-MM-DD");
      let querys = `?periodo=${value}&cliente_id=${user.clienteId}&inicio=${inicio2}&final=${final2}`
      const res = await v2_actionRelatorioChamados(querys);
      const res2 = await v2_actionRelatorioChamadosUsuarios(querys);
      setMetricasChamados(res.data.data.total);
      setListaMetricasUsuarios(res2.data.data)
    } catch (err) {
      console.log('err', err)
    }
  }



  const onFiltrar = async (value, action) => {
    setIsPesquisado(false);
    setPeriodo(value)
    handleBuscarDadosChamados(value);
    setFilterPesquisa('')
  }

  const onPesquisar = async () => {
    // setIsPesquisado(true);
    // const inicio2 = moment(inicio).format("YYYY-MM-DD");
    // const final2 = moment(final).format("YYYY-MM-DD");
    // const querys = `?periodo=null&cliente_id=${multiSession === "1" ? clienteIdActive : user.clienteId}&inicio=${inicio2}&final=${final2}&action=pesquisar&search=${filterPesquisa}`;
    // await buscarListaChats(querys, "pesquisar", true)
  }

  return (
    <>
            <Container maxWidth={"lg"} >
      <Box sx={{ mt: 5 }} title={nomeProjeto}>

        <div className='flex flex-row items-center gap-3'>
          <RowInput
            label="período"
            input={<MenuDatas2
              onClose={() => {
                setAnchorEl(null);
              }}
              anchorEl={anchorEl}
              open={openMenuDatas}
              inicial={inicial}
              final={final}
              changeInicial={newValue => setInicial(newValue)}
              changeFinal={newValue => { setFinal(newValue) }}
              content={validaLabelMenuDatas(periodo, inicial, final)}
              onSelect={value => {
                setPeriodo(value);
                setAnchorEl(null);
                onFiltrar(value)
              }}
              onPesquisar={() => onPesquisar('null')}
            />}

          />

        </div>


          <Box sx={{ backgroundColor: '#fff', height: 'calc(9vh - 150px)', }}>

            <Stack direction={'column'} spacing={2}>
              <Card sx={{ p: 3 }}>
                {metricasChamados && (
                  <Grid container spacing={1} >
                    <Grid item xs={12} sm={4} md>
                      <Widget title="Abertos" total={metricasChamados.totalAberto} icon={'ion:ticket'} />
                    </Grid>
                    <Grid item xs={12} sm={4} md>
                      <Widget title="Pendentes" total={metricasChamados.totalPendente} icon={'tabler:file-time'} />
                    </Grid>
                    <Grid item xs={12} sm={4} md>
                      <Widget title="Concluidos" total={metricasChamados.totalConcluido} icon={'fa-solid:check'} />
                    </Grid>
                    <Grid item xs={12} sm={4} md>
                      <Widget title="Tempo Conclusão" total={0} icon={'mdi:clock-outline'} />
                    </Grid>
                    <Grid item xs={12} sm={4} md>
                      <Widget title="Concluídos apos Prazo" total={metricasChamados.atrasados} icon={'material-symbols:assignment-late-sharp'} />
                    </Grid>
                    <Grid item xs={12} sm={4} md>
                      <Widget title="Cancelados" total={metricasChamados.totalCancelado} icon={'zondicons:close-solid'} />
                    </Grid>
                    <Grid item xs={12} sm={4} md>
                      <Widget title="Recusados" total={metricasChamados.totalRecusado} icon={'healthicons:refused'} />
                    </Grid>


                  </Grid>
                )}
              </Card>

              {listaMetricasUsuarios.length > 0 && (

                <Card sx={{ backgroundColor: '#fff', p: 1 }}>
                  <Box sx={{ height: 500, alignItems: 'center' }}>
                    <Scrollbar >
                      <TableContainer sx={{ minWidth: 400, mt: 1 }}>
                        <Table >
                          <TableHead>
                            <TableRow>

                              <TableCell align="left">Usuário</TableCell>
                              <TableCell align="left">Abertos</TableCell>
                              <TableCell align="left">Pendentes</TableCell>
                              <TableCell align="left">Concluídos</TableCell>
                              <TableCell align="left">Tempo Conclusão</TableCell>
                              <TableCell align="left">Concluídos apos Prazo</TableCell>
                              <TableCell align="left">Recusados</TableCell>
                              <TableCell align="left">Cancelados</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {listaMetricasUsuarios.map((row) => {
                              let tma = row.tma / row.count_tma;


                              return (
                                <TableRow key={row._id}>
                                  <TableCell width={200} component="th" scope="row">
                                    {row.nomeUsuario}
                                  </TableCell>

                                  <TableCell width={150} component="th" scope="row">
                                    {row.totalAberto ? row.totalAberto : '-'}
                                  </TableCell>
                                  <TableCell width={150} component="th" scope="row">
                                    {row.totalPendente ? row.totalPendente : '-'}
                                  </TableCell>

                                  <TableCell width={150} component="th" scope="row">
                                    {row.totalConcluido ? row.totalConcluido : '-'}

                                  </TableCell>
                                  <TableCell width={150} component="th" scope="row">
                                    {tma ? resultTimes(tma) : '-'}

                                  </TableCell>
                                  <TableCell width={150} component="th" scope="row">
                                    {row.totalAtrasados ? row.totalAtrasados : '-'}
                                  </TableCell>

                                  <TableCell width={150} component="th" scope="row">
                                    {row.totalRecusados ? row.totalRecusados : '-'}
                                  </TableCell>

                                  <TableCell width={150} component="th" scope="row">
                                    {row.totalCancelado ? row.totalCancelado : '-'}
                                  </TableCell>

                                </TableRow>
                              )
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Scrollbar>
                  </Box>


                </Card>
              )}
            </Stack>

          </Box>

      </Box >
              
      </Container>
    </>
  );
}
