import { Button } from "src/@/components/ui/button";
import { Card } from "src/@/components/ui/card";

function Automacoes() {
  return (
    <div className="container flex justify-between w-full p-4">
      {/* Left Column */}
      <div className="w-1/2 bg-white rounded-md p-4 shadow-md">
        <h2 className="text-xl font-semibold mb-4">Sempre que...</h2>
        <ul className="space-y-2">
          <Card className="flex items-center space-x-3 p-4 hover:bg-gray-50 cursor-pointer">
            <span>🔄</span>
            <p>Um card entrar em uma fase</p>
          </Card>
          <Card className="flex items-center space-x-3 p-4 hover:bg-gray-50 cursor-pointer">
            <span>🖊️</span>
            <p>Um campo for atualizado</p>
          </Card>
          <Card className="flex items-center space-x-3 p-4 hover:bg-gray-50 cursor-pointer">
            <span>📝</span>
            <p>Um card for criado</p>
          </Card>
          {/* Add more items similarly */}
        </ul>
      </div>

      {/* Right Column */}
      <div className="w-1/2 bg-white rounded-md p-4 shadow-md">
        <h2 className="text-xl font-semibold mb-4">Faça isso...</h2>
        <ul className="space-y-2">
          <Card className="flex items-center space-x-3 p-4 hover:bg-gray-50 cursor-pointer">
            <span>📧</span>
            <p>Envie um template de email</p>
          </Card>
          <Card className="flex items-center space-x-3 p-4 hover:bg-gray-50 cursor-pointer">
            <span>➡️</span>
            <p>Mova um card</p>
          </Card>
          <Card className="flex items-center space-x-3 p-4 hover:bg-gray-50 cursor-pointer">
            <span>🛠️</span>
            <p>Atualize um campo no card ou registro</p>
          </Card>
          {/* Add more items similarly */}
        </ul>
      </div>
    </div>
  );
}

export default Automacoes;
