import React, {useEffect, useState} from 'react';
import {useGlobal} from "reactn";

import "./Create.css";
import Scrollbar from 'src/components/Scrollbar';

import search from "../../../../../../actions/chats/staff/search";
import TopBar from "./components/TopBar";
import User from "./components/User";

// 
import { useDispatch, useSelector } from 'react-redux';
import { setPanel, setNewGroupUsers, setSearchResults } from 'src/redux/slices/staff';
import useAuth from 'src/hooks/useAuth';

import { Button } from 'src/@/components/ui/button';
import { Box, Divider } from '@mui/material';

const Panel = () => {
    // eslint-disable-next-line no-unused-vars
    const dispatch = useDispatch();
    const {user} = useAuth()
    const {newGroupUsers, searchResults} = useSelector((state) => state.staff);
    const [error, setError] = useState(false);

    useEffect(() => {
        search().then(res => dispatch(setSearchResults(res.data.users)));
    }, [dispatch]);

    useEffect(() => {
        dispatch(setNewGroupUsers([user.id]));
    }, [user]);

    const onSelect = id => {
        if (newGroupUsers.includes(id)) {
            dispatch(setNewGroupUsers(newGroupUsers.filter(u => u !== id)));
        }
        else {
            setError(false);
            dispatch(setNewGroupUsers([...newGroupUsers, id]));
        }
    };

    const searchResultsList = searchResults.map(user => (
            <User key={user._id} user={user} selected={newGroupUsers.includes(user._id)} onSelect={() => onSelect(user._id)} />
        )
    );

    const Notice = ({text}) => <div className="notice">{text}</div>

    const createGroup = e => {
        e.preventDefault();
        if (newGroupUsers.length > 1) dispatch(setPanel('createGroup2'));
        else setError(true);
    }

    return (
        <div className="group-create">
            <TopBar/>

   

            <Box className="mt-2 mb-2">
            {newGroupUsers.length < 2 ? (
                <Notice text="Selecione as pessoas que deseja adicionar ao grupo" />
            
            ) : (
                <Button className="w-full mt-3 mb-3" onClick={createGroup}>Continuar</Button>
            )}
            </Box>
         

            <Divider sx={{borderStyle:'dashed'}} />

            <div className="selection-text mt-3" hidden={newGroupUsers.length <= 1}>
                {newGroupUsers.length - 1} selecionados - <a onClick={() => dispatch(setNewGroupUsers([user.id]))}>Limpar</a>
            </div>
    
            <Scrollbar className="mt-10" sx={{height:'calc(100vh - 250px)'}}>
                {searchResultsList}
            </Scrollbar>
    
        </div>
    );
}

export default Panel;
