import React, {useState, useRef, useEffect} from 'react';
import { Lightbox } from "react-modal-image";

import Config from "src/config";

//import './Messages.css';
import { useSelector} from "react-redux";

import useAuth from 'src/hooks/useAuth';
import ChatInternoMessageItem from './ChatInternoMessageItem';
import { Input } from 'src/@/components/ui/input';
import { Button } from 'src/@/components/ui/button';
import { Avatar } from '@mui/material';
//import getMoreMessages from "../../../actions/getMoreMessages";

import ReactImageAppear from 'react-image-appear';
import Iconify from 'src/components/Iconify';
import { convertUrls } from 'src/utils/functions';



const groupMessagesByDate = (messages) => {
  return messages.reduce((acc, message) => {
    const date = new Date(message.date).toLocaleDateString();
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(message);
    return acc;
  }, {});
};


  const ChatMessageItem = ({msg, user, index}) => {
    const {sender, time, content, isSender} = msg;
    const isMe = user.id === msg.author._id;
    console.log(isMe)

    return (
      <ChatInternoMessageItem
            key={index}
            message={msg}
            previous={msg[index - 1]}
            next={msg[index + 1]}
            onOpen={() => {}}
        />
    )

  //   const Content = () => {
  //     switch (msg.type) {
  //         case 'image':
  //             return (
  //                 <ReactImageAppear
  //                     src={`${Config.urlWs || ''}/api/files/${msg.content}`}
  //                     animationDuration="0.2s"
  //                     onClick={() => onOpen(msg)}
  //                 />
  //             );
  //         case 'file':
  //             return (
  //                 <a href={() => openInNewTab(`${Config.urlWs || ''}/api/files/${msg.content}`)}>
  //                     <div className="content-download">
  //                         <div className="content-file">
  //                             <div className="content-name">{msg.file ? msg.file.name : 'Arquivo'}</div>
  //                             {/* <div className="content-size">{message.file ? Math.round(message.file.size / 1024 / 1024 * 10 ) + 'Kb' : 'Size'}</div> */}
  //                         </div>
  //                         <div onClick={() => openInNewTab(`${Config.urlWs || ''}/api/files/${msg.content}`)} className="content-icon"><Iconify icon="material-symbols:download" /></div>
  //                     </div>
  //                 </a>
  //             );
  //         default:
  //             return <div>{content}</div>
  //     }
  // };

  //   return (
  
  //     <div key={msg._id} className={`flex items-start my-2 ${isMe ? 'justify-start' : 'justify-end'}`}>
  //     {/* <Avatar className="mr-2" size="sm" src="/avatar_placeholder.png" /> */}
  //     <div className={`bg-${isMe ? 'purple-200' : 'yellow-200'} p-3 rounded-xl max-w-xs`}>
  //       <div className="text-sm text-gray-600 mb-1">{msg.sender}</div>
  //           <Content/>
  //       <div className="text-xs text-gray-500 mt-1">{msg.time}</div>
  //     </div>
  //   </div>
  //   )
  }



const ChatMessage = ({mensagens, user, ref}) => {
  const groupedMessages = groupMessagesByDate(mensagens);
    return (
      <div ref={ref}  className="flex flex-col min-h-full x-auto bg-slate-200 shadow-lg p-4">
        <div className="overflow-auto flex-1">
        {Object.keys(groupedMessages).map((date) => (
          <div key={date}>
            <div className='w-full flex justify-center'>
              <div className=" p-2 bg-slate-100 shadow-sm rounded-lg text-xs font-bold text-slate-500">{date}</div>

            </div>
            {groupedMessages[date].map((msg, index) => (
               <ChatMessageItem msg={msg} user={user} index={index} />
            ))}
          </div>
        ))}

        </div>

      </div>
    );
  };

const ChatInternoMessagesList = () => {
    const {user} = useAuth();

    const [loading, setLoading] = useState(false);
    const { mensagensStaff, staff } = useSelector((state) => state.staff);
    const mensagens = mensagensStaff ;

    const chat = useRef(null);

    const [open, setOpen] = useState(null);
 
    // eslint-disable-next-line no-unused-vars
    let other = {
        firstName: 'A', lastName: 'A'
    };

    if (!staff.isGroup && staff.people) {
        staff.people.forEach(person => {
            if (person._id !== user.id) other = person;
        });
    }

    const Messages = mensagens.map((message, index) => {
        return <ChatInternoMessageItem
            key={index}
            message={message}
            previous={mensagens[index - 1]}
            next={mensagens[index + 1]}
            onOpen={setOpen}
        />;
    });

    const onScroll = () => {
        if (chat.current.scrollTop === 0) {
            if (loading) return;
            setLoading(true);
            // getMoreMessages({roomID: room._id, firstMessageID: messages[0]._id}).then(res => {
            //     console.log(res.data.messages)
            //     // dispatch({type: Actions.MORE_MESSAGES, messages: res.data.messages});
            //     setLoading(false);
            // }).catch(err => {
            //     setLoading(false);
            // });
        }
    };


    useEffect(() => {
        chat.current.scrollTop = chat.current.scrollHeight;
    }, [mensagens.length]);

    return (
        <div className="messages-wrapper" ref={chat} onScroll={onScroll}>
            <div className="messages-container ">
                {open && (
                    <Lightbox
                        medium={`${Config.urlWs || ''}/api/files/${open.content}`}
                        large={`${Config.urlWs || ''}/api/files/${open.content}`}
                        alt="Lightbox"
                        hideDownload={false}
                        onClose={() => setOpen(null)}
                    />
                )}
                <ChatMessage
                ref={chat} 
                mensagens={mensagens}
                user={user}
                />
                {/* {Messages} */}
            </div>
        </div>
    );
};

export default ChatInternoMessagesList;
