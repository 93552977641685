
import { Drawer, IconButton, Box, useMediaQuery, Tab, Badge, Divider, List, ListItem, Link } from '@mui/material';
import { TabContext, TabPanel } from '@mui/lab';
import { useTheme, styled } from '@mui/material/styles';
import { useState, useEffect } from 'react';
import { BsFillChatLeftFill, BsFillPersonLinesFill, BsArrowRightCircle } from "react-icons/bs";
import { FaClock } from "react-icons/fa";
import { MdOutlineGroupAdd } from "react-icons/md";
import { useNavigate } from 'react-router-dom';
// icons
// material
// redux
import createRoom from 'src/actions/chats/staff/createRoom';
import useResponsive from 'src/hooks/useResponsive';
import { useSelector, useDispatch } from 'src/redux/store';

// routes
import CreateGroup from 'src/components/_chats/staff/components/Group/Create';
import CreateGroup2 from 'src/components/_chats/staff/components/Group/Create2';
import {
  getStaff,
  getSearchStaffs,
  getListaStaffsWithoutIsGroup,
  setSearchResults
} from 'src/redux/slices/staff';
import { PATH_CHAT } from 'src/routes/paths';
import Scrollbar from 'src/components/Scrollbar';
import search from 'src/actions/chats/staff/search';
import ChatConversationList from './ChatConversationList';
import ChatSearchResults from './ChatSearchResults';
import useAuth from 'src/hooks/useAuth';
import { setPanel } from 'src/redux/slices/staff';
import Iconify from 'src/components/Iconify';
import { Button } from "src/@/components/ui/button"
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "src/@/components/ui/card"
import { Input } from "src/@/components/ui/input"
import { Label } from "src/@/components/ui/label"
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "src/@/components/ui/tabs"

import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "src/@/components/ui/tooltip"


const LinkItem = ({ title, onClick }) => {
  return (
    <Link
      onClick={onClick}
      className="flex items-center gap-3 rounded-lg px-3 py-1.5 text-muted-foreground transition-all hover:text-primary cursor-pointer"
    >
      {title}
    </Link>
  )
}

const LinkHeader = ({ title, icon }) => {
  return (
    <div className="flex items-center gap-1 mb-1 justify-start">
      {/* <Iconify size={12} icon={icon} className="text-primary" /> */}
      <div className="text-sm font-semibold text-right">{title}</div>
    </div>
  )
}

const TabButton = ({
  tab,
  icon,
  label,
  selectedTab,
  setSelectedTab
}) => (
  <Button
    variant="ghost"
    size="icon"
    className={`rounded-lg p-1 ${selectedTab === tab ? 'bg-violet-200' : ''}`}
    aria-label={label}
    onClick={() => setSelectedTab(tab)}
  >
    <Iconify icon={icon} className="text-center font-[20px]" />
  </Button>
);

const tabs = [
  { tab: 'conversas', icon: 'flowbite:messages-outline', label: 'Conversas' },
  { tab: 'agentes', icon: 'clarity:group-solid-badged', label: 'Agentes' },
  { tab: 'criar_grupo', icon: 'ic:baseline-group-add', label: 'Criar Grupo' }
];

export default function ChatInternoSidebar() {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [openSidebar, setOpenSidebar] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [searchQuery, setSearchQuery] = useState('');
  const [isSearchFocused, setSearchFocused] = useState(false);
  const displayResults = searchQuery && isSearchFocused;
  const { staffs, roomsWithNewMessages, onlineUsers, panel, searchResults } = useSelector((state) => state.staff);
  const { user } = useAuth()
  const clienteId = user.clienteId;
  const isDesktop = useResponsive('up', 'md');

  const [selectedTab, setSelectedTab] = useState('conversas');


  const [valueScrollable, setValueScrollable] = useState('1');

  useEffect(() => {
    search().then(res => dispatch(setSearchResults(res.data.users))).catch(err => console.log(err));
  }, [setSearchResults]);

  useEffect(() => {
    dispatch(getListaStaffsWithoutIsGroup())
    // dispatch(getListaStaffs(false));
    // dispatch(getListaStaffsGroup(true));
    dispatch(getSearchStaffs());
  }, [clienteId]);




  ///////////// ITENS DO TAB PAINEL
  const ItemChatStaff = () => {
    const groupChats = staffs.filter((staff) => staff.isGroup);
    const staffChats = staffs.filter((staff) => !staff.isGroup);
    return (
      <ChatConversationList
        chats={staffs}
        isOpenSidebar={openSidebar}
        user={user}
        roomsWithNewMessages={roomsWithNewMessages}

      />

    );
  }


  ///////////// ITENS DO TAB PAINEL
  const ItemPesquisar = () => {
    return (
      <div>
        {openSidebar && (
          <IconButton onClick={() => dispatch(setPanel('createGroup'))}>
            <MdOutlineGroupAdd size={22} />
          </IconButton>
        )}
        <Scrollbar sx={{ height: 'calc(100vh - 230px)' }}>
          <ChatSearchResults query={searchQuery} user={user} onlineUsers={onlineUsers} results={searchResults} onSelectContact={criarStaffApartirUsuario} />
        </Scrollbar>
      </div>

    );
  }


  useEffect(() => {

    if (isMobile) {
      return setOpenSidebar(false);
    }
    return setOpenSidebar(true);
  }, [isMobile]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (!openSidebar) {
      return setSearchFocused(false);
    }
  }, [openSidebar]);

  const handleCloseSidebar = () => {
    setOpenSidebar(false);
  };

  const handleToggleSidebar = () => {
    setOpenSidebar((prev) => !prev);
  };

  const criarStaffApartirUsuario = async (result) => {
    const res = await createRoom(result._id);
    // const target = `/room/${res.data.room._id}`;

    //dispatch({type: Actions.SET_ROOM, room: res.data.room});
    dispatch(getStaff(res.data.room._id));
    navigate(`chats/interno/${res.data.room._id}`);
    //dispatch({type: Actions.SET_MESSAGES, messages: res.data.room.messages});

    // if (location.pathname !== target) history.replace(target);
  };



  const getPanel = () => {
    switch (panel) {
      case 'createGroup':
        return <CreateGroup />
      case 'createGroup2':
        return <CreateGroup2 />
      default:
        return (
          <div className='flex flex-col'>
            <div className='w-[400px]'>
              {selectedTab === 'conversas' && <ItemChatStaff />}
              {selectedTab === 'agentes' && <ItemPesquisar />}
              {selectedTab === 'criar_grupo' && <ItemPesquisar />}
            </div>
          </div>
        )
    }
  }





  return (
    <div className="flex w-[450px] bg-white  h-full">
      <div className="flex flex-col bg-slate-100 border-r-slate-200 border-r min-w-[70px] items-center">

        <nav className="grid gap-2 pt-2">
          {tabs.map(({ tab, icon, label }) => (
            <TabButton
              key={tab}
              tab={tab}
              icon={icon}
              label={label}
              selectedTab={selectedTab}
              setSelectedTab={tab => setSelectedTab(tab)}
            />
          ))}
        </nav>

      </div>
      {getPanel()}
    </div>
  );
}
