import { Box, Stack, useMediaQuery, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
// material
import { useLocation, useNavigate, useParams } from 'react-router-dom';

// utils
import useAuth from 'src/hooks/useAuth';
import { ChatWindow, ChatSidebar } from 'src/sections/@chats/chat';
import { ChatContactSearch } from 'src/components/_chats/chat';
import { getChatsAtendimento, getChatsPendente, getCountsChats, getListaFunil, getListaTags, getPesquisarChats, setChatInfo, setDrawerPesquisas, setMessage } from 'src/redux/slices/chat';
// redux
import { setLimparChatStaff, setMessages } from 'src/redux/slices/staff';
// hooks
import { useDispatch, useSelector } from 'src/redux/store';
//services 
import ModalNovoContato from 'src/sections/@chats/chat/Modals/ModalNovoContato';
import actionListaClientesCanais from 'src/actions/clientes/actionListaClientesCanais';
import MenuOptionsNovo from 'src/sections/@dashboard/negocios/MenuOptionsNovo';
import { getConfig } from 'src/redux/slices/global';
import EmptyContentChat from 'src/components/EmptyContentChat';
import { Toast, ToastProvider } from 'src/@/components/ui/toast';


export default function Chat() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { user, cliente } = useAuth();
  const [searchQuery, setSearchQuery] = useState('');
  const [isSearchFocused, setSearchFocused] = useState(false);
  const { chatInfo } = useSelector((state) => state.chat);
  const { config } = useSelector((state) => state.global);
  const [listaClientesCanais, setListaClientesCanais] = useState([]);
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const [sidebarLeft, setSidebarLeft] = useState(false);


  async function buscarListaClientesCanais() {
    try {
      const response_clientes_canais = await actionListaClientesCanais(cliente._id)
      console.log(response_clientes_canais.data.data)
      setListaClientesCanais(response_clientes_canais.data.data);
    } catch (err) {
      console.log(err)
    }
  }

  const handleClickAwaySearch = () => {
    setSearchFocused(false);
    setSearchQuery('');
  };

  const handleSearchFocus = () => {
    setSearchFocused(true);
  };


  const handleChangeSearch = async (event) => {
    try {
      const { value } = event.target;
      setSearchQuery(value);
      dispatch(getPesquisarChats(user.clienteId, value));
      if (value === '') {
        dispatch(setDrawerPesquisas(false))
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {


    return () => {
      console.log('saiu 1')
    }

  }, [])



  useEffect(() => {
    dispatch(getChatsAtendimento(user.clienteId, user.id, user.setorId, 10, true));
    dispatch(getCountsChats(user.clienteId, user.id, user.setorId));
    dispatch(getChatsPendente(user.clienteId, user.id, user.setorId));
    dispatch(getListaFunil(user.clienteId));
    let querys = `?cliente_id=${user.clienteId}`
    dispatch(getListaTags(querys))
    dispatch(getCountsChats(user.clienteId, user.id, user.setorId));
    dispatch(setLimparChatStaff());
    dispatch(getConfig());
    if (cliente.multiSession === 1) {
      buscarListaClientesCanais()
    }

    const handleKeyPress = (event) => {
      // Verifica se a tecla pressionada é a tecla "Esc" (código 27)
      if (event.keyCode === 27) {
        // Chama sua função aqui
        dispatch(setChatInfo(null))
        dispatch(setMessages([]))
      }
    };

    // Adiciona um ouvinte de eventos ao documento para capturar pressionamentos de teclas
    document.addEventListener('keydown', handleKeyPress);

    // Remove o ouvinte de eventos ao desmontar o componente
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };

  }, []);


  return (
    <Stack direction={"row"} className={isMobile ? 'mt-14' : ''}>
      {!sidebarLeft && (
        <div className='mx-1.5'>
          <div className='flex justify-between items-center gap-2 my-4 mx-2'>
            <ChatContactSearch
              query={searchQuery}
              onFocus={handleSearchFocus}
              onChange={handleChangeSearch}
              onClickAway={handleClickAwaySearch}
            />
            <MenuOptionsNovo />
          </div>


          <ChatSidebar />


        </div>
      )}
      {chatInfo ? <ChatWindow
        sidebarLeft={sidebarLeft}
        changeOpenSidebarLeft={() => {
            isMobile && setSidebarLeft(!sidebarLeft)
        }}
        config={config} 
        chatInfo={chatInfo}
         /> : <EmptyContentChat title={"Selecione um chat para começar"} />}
    </Stack>
  );
}
