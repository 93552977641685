import axios from "axios";

import Config from "../../../config";

const createGroup = data => {
    return axios({
        method: "post",
        url: (Config.urlWs || '') + "/api/group/create",
        data
    });
};

export default createGroup;
