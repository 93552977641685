import { Avatar, Box, Stack } from "@mui/material";
import {
    Cloud,
    CreditCard,
    Github,
    Keyboard,
    LifeBuoy,
    LogOut,
    Mail,
    MessageSquare,
    Plus,
    PlusCircle,
    Settings,
    User,
    UserPlus,
    Users,
    MessageCircleDashed,
    MessageSquareDot
} from "lucide-react"
import { useNavigate } from "react-router";

import { Button } from "src/@/components/ui/button"
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuPortal,
    DropdownMenuSeparator,
    DropdownMenuShortcut,
    DropdownMenuSub,
    DropdownMenuSubContent,
    DropdownMenuSubTrigger,
    DropdownMenuTrigger,
} from "src/@/components/ui/dropdown-menu"
import Iconify from "src/components/Iconify";
import useAuth from "src/hooks/useAuth";




export function DropdownAccountNav() {
    const {user, logout} = useAuth();
    const navigate = useNavigate();

    return (
        <DropdownMenu>
            <DropdownMenuTrigger asChild>
                <Button size="icon" className="text-gray-900" variant='outline'>
                    <Iconify color="black" icon="mdi:account-outline"/>
                </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-72">


                <Box sx={{p:1, mt:1, alignItems:'center', flex:1}}>
                    <Stack spacing={1} direction={"row"}>
                        {/* <Avatar /> */}
                        <Stack direction={"column"} spacing={1} >
                    <small className="font-bold ">{user?.nomeFantasia}</small>
                    <small className="text-sm font-medium leading-none">{user?.email}</small>
                    </Stack>
                    </Stack>
      
                </Box>

                <DropdownMenuSeparator />

                <DropdownMenuGroup>
                    <DropdownMenuItem
                    onClick={() => navigate('/dashboard/conta/perfil')}
                    >
                        <Iconify icon="ion:settings-outline" className="mr-2 h-4 w-4" />
                        <span>Configurações</span>
                    </DropdownMenuItem>
                    <DropdownMenuSeparator />

                    <Button
                    onClick={() => logout()}
                        className="w-full mt-2 mb-2"
                        variant="outline"
                    >
                        Sair
                    </Button>
                </DropdownMenuGroup>




            </DropdownMenuContent>
        </DropdownMenu>
    )
}
