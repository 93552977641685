import axios from "axios";
import Config from "src/config";

const v1_actionEnviarMenagemWhatsapp = (querys, data) => {
    return axios({
        method: "post",
        url:`${Config.dev ? Config.url_dev : Config.urlBackend}/api/v1/chats/canais/enviar-mensagem${querys}`,
        data,
        timeout: 20000,

    });
};

export default v1_actionEnviarMenagemWhatsapp;
