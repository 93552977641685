// material
import { Box, Avatar, ListItemAvatar, ListItemButton, Badge, Typography, Stack } from '@mui/material';
import { styled } from "@mui/material/styles";
import { formatDistanceToNow, formatDistanceToNowStrict } from 'date-fns';
import { Card } from 'src/@/components/ui/card';
import Label from 'src/components/Label';
import ptBR from 'date-fns/locale/pt-BR';
import {

  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuPortal,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from "src/@/components/ui/dropdown-menu"
import { Button } from 'src/@/components/ui/button';
import Iconify from 'src/components/Iconify';
// ----------------------------------------------------------------------

const AVATAR_SIZE = 38;

const RootStyle = styled(ListItemButton)(({ theme }) => ({
  padding: theme.spacing(1.5, 3),
  transition: theme.transitions.create('all'),
  borderBottom: '1px solid #f0f0f0'
}));

const AvatarWrapperStyle = styled('div')({
  position: 'relative',
  width: AVATAR_SIZE,
  height: AVATAR_SIZE,
  '& .MuiAvatar-img': { borderRadius: '20%' },
  '& .MuiAvatar-root': { width: '100%', height: '100%' }
});





export default function ChatStaffItem({
  chat,
  user,
  deletarGrupo,
  onlineUsers,
  onSelectConversation,
  roomsWithNewMessages,
  isOpenSidebar,
  key
}) {

  const room = chat;

  let other = {};

  chat.people.forEach(person => {
    if (user.id !== person._id) other = person;
  });

  if (!other.firstName) {
    other = { ...other, firstName: 'Deleted', lastName: 'User' };
  }

  const title = (chat.isGroup ? chat.title : `${other.firstName} ${other.lastName}`).substr(0, 22);

  let lastMessage = chat.lastMessage;
  let text = '';

  if (!lastMessage && chat.isGroup) text = 'Novo grupo criado';
  if (!lastMessage && !chat.isGroup) text = `Sem mensagens`;

  if (!lastMessage) lastMessage = {};

  if (lastMessage.author === user.id && !chat.isGroup) text += 'Você: ';

  switch (lastMessage.type) {
    case 'file':
      text += 'Enviou um arquivo.';
      break;
    case 'image':
      text += 'Enviou uma foto';
      break;
    default:
      text += (lastMessage.content || '');
  }


  const getStatus = () => {
    if (room) { if (room.isGroup) return null }
    if (onlineUsers.filter(u => u.id === other._id && u.status === 'busy').length > 0) return 'busy';
    if (onlineUsers.filter(u => u.id === other._id && u.status === 'online').length > 0) return 'online';
    if (onlineUsers.filter(u => u.id === other._id && u.status === 'away').length > 0) return 'away';
    return null;
  };


  function validaAdminGrupo() {
    if (chat) {
      if (chat.people[0]._id === user.id) {
        if (chat.isGroup === true) {
          return <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button className="w-5 h-5" size="icon" variant="outline"><Iconify color="black" width={8} icon="fe:arrow-down" /></Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              <DropdownMenuItem className="text-[10px]" onClick={() => { }}>
                Excluir grupo
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        }

      }
    }
  }

  return (
    <div className={`my-1 mr-1 border-b-1 p-2 shadow-none ${roomsWithNewMessages.filter(r => room._id === r).length > 0 ? "bg-violet-50" : "bg-white "}`} key={key}>

      <Stack direction={"row"}>
        <Box sx={{ width: '100%' }}>
          <Stack direction="column" spacing={1}>
            <Stack key={key} spacing={0.5} direction={'row'}>
              <ListItemButton
                sx={{ px: 0.5, py: 0 }}
                onClick={onSelectConversation}
              >

                <Stack direction={'column'} sx={{ width: '75%' }}>

                  <Stack spacing={1} direction={'row'}>
                    <Box className="w-full">
                      <Stack>
                        <p className='text-[13px] font-semibold'> {title.substr(0, 25)}{title.length > 25 && '...'}  </p>
                        <p className='text-[10px] text-foreground'> {text.substr(0, 20)}{text.length > 20 && '...'}  </p>
                        {/* <Typography sx={{ fontSize: 10 }}>{chat?.lastMessage?.lastUpdate	}</Typography> */}
                      </Stack>
                    </Box>
                  </Stack>
                </Stack>

                <Box sx={{
                  width: '25%',
                  backgrounColor: "red"
                }}>
                  <Stack direction={'column'} spacing={1}>

                    <Stack direction={'column'} spacing={1} alignItems={'center'} justifyContent={'end'}>
                      {chat.lastMessage && <Typography style={{ fontSize: 9, textAlign: 'right' }}>{formatDistanceToNowStrict(new Date(chat?.lastMessage?.date), { addSuffix: false, locale: ptBR, includeSeconds: false })}</Typography>}
                     {validaAdminGrupo()}
                    </Stack>


                  </Stack>
                </Box>
              </ListItemButton>
            </Stack>






          </Stack>
        </Box>
      </Stack>
    </div>
  );
}


