import { List, Avatar, Typography, ListItemText, ListItemAvatar, ListItemButton } from '@mui/material';
import PropTypes from 'prop-types';

import BadgeStatus from 'src/components/BadgeStatus';

// ----------------------------------------------------------------------

ChatSearchResults.propTypes = {
  query: PropTypes.string,
  results: PropTypes.array,
  onSelectContact: PropTypes.func
};

export default function ChatSearchResults({ results, onSelectContact, onlineUsers, chat }) {

  const room = chat;

//   let other = {};

//   chat.people.forEach(person => {
//       if (user.id !== person._id) other = person;
//   });

//   if (!other.firstName) {
//       other = { ...other, firstName: 'Deleted', lastName: 'User' };
//   }


  return (
    <>
      <Typography paragraph variant="subtitle1" sx={{ px: 3, color: 'text.secondary' }}>
        Chats
      </Typography>

      <List disablePadding>
        {results.map((result) =>  {
          const getStatus = () => {
            if (room) {if(room.isGroup) return null}
            if (onlineUsers.filter(u => u.id === result._id && u.status === 'busy').length > 0) return 'busy';
            if (onlineUsers.filter(u => u.id === result._id && u.status === 'online').length > 0) return 'online';
            if (onlineUsers.filter(u => u.id === result._id && u.status === 'away').length > 0) return 'away';
            return null;
        };

        // let other = {};

        // room.people.forEach(person => {
        //     if (user.id !== person._id) other = person;
        // });
    
        // if (!other.firstName) {
        //     other = { ...other, firstName: 'Deleted', lastName: 'User' };
        // }
        return (
          <ListItemButton
            key={result.id}
            onClick={() => onSelectContact(result)}
            sx={{
              py: 1.5,
              px: 3
            }}
          >
            <ListItemAvatar>
            <BadgeStatus status={getStatus()} size="large" />
              <Avatar alt={result.name} src={result.avatar} />
            </ListItemAvatar>
            <ListItemText
              primary={result.firstName}
              primaryTypographyProps={{
                noWrap: true,
                variant: 'subtitle2'
              }}
            />
          </ListItemButton>
        )}
        )}
      </List>
      {/* {!isFound && (
        <SearchNotFound
          searchQuery={query}
          sx={{
            p: 3,
            mx: 'auto',
            width: `calc(100% - 48px)`,
            bgcolor: 'background.neutral'
          }}
        />
      )} */}
    </>
  );
}
