
import {Drawer, IconButton, Box, useMediaQuery, Tab, Tabs, Badge, Divider, List, ListItem } from '@mui/material';
import { TabContext, TabPanel } from '@mui/lab';
import { useTheme, styled } from '@mui/material/styles';
import { useState, useEffect } from 'react';
import { BsFillChatLeftFill, BsFillPersonLinesFill, BsArrowRightCircle } from "react-icons/bs";
import { FaClock } from "react-icons/fa";
import { MdOutlineGroupAdd } from "react-icons/md";
import { useNavigate } from 'react-router-dom';
// icons
// material
// redux
import createRoom from 'src/actions/chats/staff/createRoom';
import useResponsive from 'src/hooks/useResponsive';
import { useSelector, useDispatch } from 'src/redux/store';

// routes
import CreateGroup from '../../../components/_chats/staff/components/Group/Create';
import CreateGroup2 from '../../../components/_chats/staff/components/Group/Create2';
import {
  getStaff,
  getSearchStaffs,
  getListaStaffsWithoutIsGroup,
  setSearchResults
} from '../../../redux/slices/staff';
import { PATH_CHAT } from '../../../routes/paths';
import Scrollbar from '../../Scrollbar';
import search from './../../../actions/chats/staff/search';
import ChatConversationList from './ChatConversationList';
import ChatSearchResults from './ChatSearchResults';
import useAuth from 'src/hooks/useAuth';
import { setPanel } from 'src/redux/slices/staff';


const ToggleButtonStyle = styled((props) => <IconButton disableRipple {...props} />)(({ theme }) => ({
  left: 0,
  zIndex: 9,
  width: 32,
  height: 32,
  position: 'absolute',
  top: theme.spacing(13),
  borderRadius: `0 12px 12px 0`,
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.palette.primary.main,
  boxShadow: theme.customShadows.primary,
  '&:hover': {
    backgroundColor: theme.palette.primary.darker,
  },
}));

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  width: 320,
  flexShrink: 0,
  display: 'flex',
  flexDirection: 'column',
  transition: theme.transitions.create('width'),
  borderRight: `1px solid ${theme.palette.divider}`
}));

// ----------------------------------------------------------------------

export default function ChatSidebar() {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [openSidebar, setOpenSidebar] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [searchQuery, setSearchQuery] = useState('');
  const [isSearchFocused, setSearchFocused] = useState(false);
  const displayResults = searchQuery && isSearchFocused;
  const { staffs, roomsWithNewMessages, onlineUsers, panel, searchResults } = useSelector((state) => state.staff);


  const {user} = useAuth()
  const clienteId = user.clienteId;
  const isDesktop = useResponsive('up', 'md');
  const isCollapse = isDesktop && !openSidebar;
  const SIDEBAR_WIDTH = 320;
  const SIDEBAR_COLLAPSE_WIDTH = 70;

  const [valueScrollable, setValueScrollable] = useState('1');

  useEffect(() => {
    search().then(res => dispatch(setSearchResults(res.data.users))).catch(err => console.log(err));
}, [setSearchResults]);

  useEffect(() => {
    dispatch(getListaStaffsWithoutIsGroup())
    // dispatch(getListaStaffs(false));
    // dispatch(getListaStaffsGroup(true));
    dispatch(getSearchStaffs());
  }, [clienteId]);




  ///////////// ITENS DO TAB PAINEL
  const ItemChatStaff = () => {
    return (
      <Scrollbar  sx={{height: 'calc(100vh - 230px)'}}>
         {!displayResults && (
        <ChatConversationList
          chats={staffs}
          isOpenSidebar={openSidebar}
          user={user}
          roomsWithNewMessages={roomsWithNewMessages}
      
        />
         )}
      </Scrollbar>
    );
  }


  ///////////// ITENS DO TAB PAINEL
  const ItemPesquisar = () => {
    return (
      <Scrollbar sx={{height: 'calc(100vh - 230px)'}}>
        <ChatSearchResults query={searchQuery} user={user} onlineUsers={onlineUsers} results={searchResults} onSelectContact={criarStaffApartirUsuario} />
        </Scrollbar>
    );
  }



  const SCROLLABLE_TAB = [
    { value: '1', icon:<Badge badgeContent={roomsWithNewMessages.length} color="primary"><BsFillChatLeftFill size={17} /></Badge> , component: <ItemChatStaff />, label: 'Conversas', hidden: false },
    // { value: '2', icon: <Badge badgeContent={0} color="primary"><MdGroups size={20} /></Badge>, component: <ItemChatStaffGroup />, label: 'Grupos', hidden: false },
    { value: '2', icon: <BsFillPersonLinesFill size={17} />, component: <ItemPesquisar />, label: 'Pesquisar', hidden: false },


  ];



  const handleChangeScrollable = (event, newValue) => {
    setValueScrollable(newValue);
  };

  ///


  useEffect(() => {

    if (isMobile) {
      return setOpenSidebar(false);
    }
    return setOpenSidebar(true);
  }, [isMobile]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (!openSidebar) {
      return setSearchFocused(false);
    }
  }, [openSidebar]);

  const handleCloseSidebar = () => {
    setOpenSidebar(false);
  };
  
  const handleToggleSidebar = () => {
    setOpenSidebar((prev) => !prev);
  };
 
    const criarStaffApartirUsuario = async (result) => {
      const res = await createRoom(result._id);
      // const target = `/room/${res.data.room._id}`;

      //dispatch({type: Actions.SET_ROOM, room: res.data.room});
      dispatch(getStaff(res.data.room._id));
      navigate(`${PATH_CHAT.staff.root}/${res.data.room._id}`);
      //dispatch({type: Actions.SET_MESSAGES, messages: res.data.room.messages});

     // if (location.pathname !== target) history.replace(target);
  };



  const getPanel = () => {
    switch (panel) {
        case 'createGroup':
            return <CreateGroup/>
        case 'createGroup2':
            return <CreateGroup2/>
        default:
            return (
              <>
              <Box sx={{ display: 'flex', justifyContent:'center', alignItems: 'center' }}>
              {/* <MIconButton onClick={() => setOpenSidebar(!openSidebar)}>
                {openSidebar ? <BsArrowLeftCircle  size={18}  /> : <BsArrowRightCircle style={{justifyContent:'center'}}  size={18} />} 
              </MIconButton> */}
              {openSidebar && (
                <IconButton onClick={() => dispatch(setPanel('createGroup'))}>
                  <MdOutlineGroupAdd size={22} />
                </IconButton>
              )} 
          </Box>
          
          
              <TabContext value={valueScrollable}>
              <Tabs
                allowScrollButtonsMobile
                value={valueScrollable}
                variant="fullWidth"
                onChange={handleChangeScrollable}
              >
                {SCROLLABLE_TAB.map((tab) => (
                  <Tab key={tab.value} icon={tab.icon} value={tab.value} disableFocusRipple={tab.hidden} />
                ))}
              </Tabs>
              <Scrollbar style={{ marginTop: 10 }}>
                {SCROLLABLE_TAB.map((panel, index) => (
                  // eslint-disable-next-line react/jsx-key
                  <Scrollbar >
                  <TabPanel key={index} value={String(index + 1)}>
                    {panel.component}
                  </TabPanel>
                  </Scrollbar>
                ))}
              </Scrollbar>
            </TabContext>
            </>
              )
    }
}

const renderContent = (
  <>
  {!openSidebar && (
        <>         
          <List sx={{ width: '100%' }}>
            <ListItem>
              <IconButton onClick={() => setOpenSidebar(!openSidebar)}>
                <BsArrowRightCircle size={18} />
              </IconButton>
            </ListItem>

          </List>
        </>
      )}
  {openSidebar &&  getPanel()} 
  </>
);



  return (
    <RootStyle sx={{ ...(!openSidebar && { width: isMobile ? 5 :70  }) }}>

{!isDesktop && (
        <ToggleButtonStyle onClick={handleToggleSidebar}>
          <FaClock size={16}  />
        </ToggleButtonStyle>
      )}

      {isDesktop ? (
        <Drawer
          open={openSidebar}
          variant="persistent"
          sx={{
            width: SIDEBAR_WIDTH,
            transition: theme.transitions.create('width'),
            '& .MuiDrawer-paper': {
              position: 'static',
              width: SIDEBAR_WIDTH,
            },
            ...(isCollapse && {
              width: SIDEBAR_COLLAPSE_WIDTH,
              '& .MuiDrawer-paper': {
                width: SIDEBAR_COLLAPSE_WIDTH,
                position: 'static',
                transform: 'none !important',
                visibility: 'visible !important',
              },
            }),
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          ModalProps={{ keepMounted: true }}
          open={openSidebar}
          onClose={handleCloseSidebar}
          sx={{
            '& .MuiDrawer-paper': { width: SIDEBAR_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}

      <Divider />
      

    </RootStyle>
  );
}
