import { List } from '@mui/material';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import getActionDeletarGrupo from 'src/actions/chats/getActionDeletarGrupo';

import { getStaff, getListaStaffsWithoutIsGroup } from '../../../redux/slices/staff';
import { useDispatch, useSelector } from '../../../redux/store';
import { PATH_CHAT } from '../../../routes/paths';
import ChatStaffItem from './ChatStaffItem';
//
ChatConversationList.propTypes = {
  isOpenSidebar: PropTypes.bool,
  activeConversationId: PropTypes.string
};

export default function ChatConversationList({ chats, user, isOpenSidebar, ...other }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { roomsWithNewMessages,  onlineUsers } = useSelector((state) => state.staff);


  const handleChoose = (chat) => {
      const target = `${PATH_CHAT.staff.root}/${chat._id}`;
      navigate(target);
      dispatch(getStaff(chat._id))
  }


  const handleDeletarGrupo = (chat) => {
    getActionDeletarGrupo({chatId:chat._id}).then(() => {
      dispatch(getListaStaffsWithoutIsGroup())
    })
  }
 


  return (
    <List {...other}>
      {chats.map((chat) => (
    
        <ChatStaffItem
          key={chat._id}
          chat={chat}
          user={user}
          onlineUsers={onlineUsers}
          deletarGrupo={() => handleDeletarGrupo(chat)}
          roomsWithNewMessages={roomsWithNewMessages}
          isOpenSidebar={isOpenSidebar}     
         // isSelected={activeConversationId === conversationId}
           onSelectConversation={() => handleChoose(chat)}
        />
      ))}
    </List>
  );
}
