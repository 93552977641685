import { Link } from "@mui/material"
import {
  Bell,
  CircleUser,
  Home,
  LineChart,
  Menu,
  Package,
  Package2,
  Search,
  ShoppingCart,
  Users,
} from "lucide-react"

import { Badge } from "src/@/components/ui/badge"
import { Button } from "src/@/components/ui/button"
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "src/@/components/ui/card"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "src/@/components/ui/dropdown-menu"
import { Input } from "src/@/components/ui/input"
import { Sheet, SheetContent, SheetTrigger } from "src/@/components/ui/sheet"

import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// @mui
// redux
import useAuth from 'src/hooks/useAuth';
import { useDispatch } from 'src/redux/store';
// routes
// hooks
// components
import MainIntegracoes from 'src/sections/@dashboard/configuracoes/Integracoes/MainIntegracoes';

//paginas
import { verifyRules } from 'src/utils/functions';
import { CanaisComponent } from 'src/sections/@dashboard/configuracoes/canais';
import UsuariosComponent from 'src/sections/@dashboard/configuracoes/usuarios';
import RespostasRapidasComponent from 'src/sections/@dashboard/configuracoes/respostasRapidas';
import TagsComponent from 'src/sections/@dashboard/configuracoes/tags';
import MainWebhooks from 'src/sections/@dashboard/configuracoes/Integracoes/MainWebooks';
import actionListaCanaisPorCliente from 'src/actions/clientes/actionListaCanaisPorCliente';
import ModalAlerta from 'src/components/modal/ModalAlerta';
import FunilComponent from 'src/sections/@dashboard/configuracoes/funil';
import MainAutomacoes from 'src/sections/@dashboard/configuracoes/automacoes/MainAutomacoes';
import v1_actionListaAutomacoes from 'src/actions/v1/clientes/v1_actionListaAutomacoes';
import FlowsComponent from 'src/sections/@dashboard/configuracoes/flows/FlowsComponent';
import v2_actionListaFlows from 'src/actions/v2/cliente/v2_actionListaFlows';
import FlowDetails from 'src/sections/@dashboard/configuracoes/flows/FlowDetails';
import v1_actionListaFlows from 'src/actions/v1/clientes/v1_actionListaFlows';
import CanaisDetails from 'src/sections/@dashboard/configuracoes/canais/CanaisDetails';
import { useLocation } from 'react-router-dom'; // se estiver usando react-router
import CanaisMetaPageList from 'src/sections/@dashboard/configuracoes/canais/CanaisMetaPageList';
import SidebarSubConfiguracoes from 'src/sections/@dashboard/configuracoes/SidebarSubConfiguracoes';
import SetoresComponent from 'src/sections/@dashboard/configuracoes/setores';
import ConectarWhatsapp from 'src/sections/@dashboard/configuracoes/canais/ConectarWhatsapp';
import Iconify from "src/components/Iconify"
import PerfilComponent from "src/sections/@dashboard/configuracoes/perfil"
import SidebarDashboard from "src/sections/@dashboard/SidebarDashboard"
import CredenciaisComponent from "src/sections/@dashboard/configuracoes/credenciais"
import { AgentesIaComponent } from "src/sections/@dashboard/configuracoes/agentesIa"
import ConfigAgentesIaComponent from "src/sections/@dashboard/configuracoes/agentesIa/ConfigAgentesIaComponent"
import v2_actionListaAgentesAi from "src/actions/v2/ai/v2_actionListaAgentesAi"
import { useSnackbar } from "notistack"
import BaseDadosAi from "src/sections/@dashboard/configuracoes/baseDadosAi"
import DocumentStoreDetails from "src/sections/@dashboard/configuracoes/agentesIa/components/training/views/DocumentStoreDetail"
import AiVozesComponent from "src/sections/@dashboard/configuracoes/aiVozes"
import Automacoes from "src/sections/@dashboard/configuracoes/automacoes/Automacoes"

export default function Configuracoes() {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, cliente } = useAuth()
  const { label } = useParams();
  const [openSidebar, setOpenSidebar] = useState(false);
  const [listaCanais, setCanais] = useState([]);
  const [listaFlows, setListaFlows] = useState([]);
  const [listaAutomacoes, setListaAutomacoes] = useState([]);
  const [modalAlerta, setModalAlerta] = useState(false);
  const [msgModalAlerta, setMsgModalAlerta] = useState("");
  const [flow, setFlow] = useState(null);

  const [listaAgentesAi, setListaAgentesAi] = useState([]);

  const location = useLocation();
  let path = location.pathname.substring(1); // Remove o "/" inicial

 

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get('channelId');

  }, [location]);


  <ModalAlerta
    open={modalAlerta}
    mensagem={msgModalAlerta}
    onClose={() => setModalAlerta(false)}
  />

  const buscarListaAutomacoes = async () => {
    try {

      const response = await v1_actionListaAutomacoes(cliente._id)
      setListaAutomacoes(response.data.data);

    } catch (error) {

    }
  }

  async function buscarListaAgentesAi() {
    try {
      const response = await v2_actionListaAgentesAi(cliente._id);
      if (response.status !== 200) return enqueueSnackbar("Erro ao buscar lista de agentes de IA", { variant: 'error' });
      setListaAgentesAi(response.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  const buscarListaCanais = async () => {
    try {
      const response = await actionListaCanaisPorCliente(cliente._id)
      setCanais(response.data.data)
    } catch (error) {
      setMsgModalAlerta("Erro ao buscar lista de canais, tente novamente mais tarde. Se persistir entre em contato com o suporte.")
      setModalAlerta(true)
    }
  }


  const buscarListaFlows = async () => {
    try {
      const response = await v1_actionListaFlows(cliente._id)
      setListaFlows(response.data.data)
    } catch (error) {
      setMsgModalAlerta("Erro ao buscar lista de canais, tente novamente mais tarde. Se persistir entre em contato com o suporte.")
      setModalAlerta(true)
    }
  }

  // eslint-disable-next-line no-unused-vars

  const linkTo = (label) => {
    return `/dashboard/configuracoes/${label}`;

  };


  function abrirOutraPagina(canal) {
    window.open(`https://www.facebook.com/v20.0/dialog/oauth?client_id=1130925304811273&state=${canal._id}&redirect_uri=https://webhook-beta.lais.app/webhook/v1/auth&scope=instagram_basic,instagram_manage_messages`, '_blank');
  }

  useEffect(() => {

    buscarListaCanais();
    buscarListaAutomacoes();
    buscarListaFlows();
    buscarListaAgentesAi();

  }, [dispatch]);





  return (
    <div className="grid h-full w-full md:grid-cols-[180px_1fr] lg:grid-cols-[220px_1fr]">

      <SidebarDashboard user={user} />

      <div className="flex flex-col justify-center">


        <div className="h-[90vh] mt-5">
          {label === "canais" && <CanaisComponent
            user={user}
            cliente={cliente}
            listaCanais={listaCanais}
            onButtonLoginFacebook={canal => abrirOutraPagina(canal)}
            onCallback={() => buscarListaCanais()}
            onConfig={item => {
              setFlow(item);
              navigate(linkTo('channelDetails'))
            }}
          />}
          {label === "flows" && <FlowsComponent
            user={user}
            cliente={cliente}
            onCallback={() => buscarListaFlows()}
            onConfigFlow={item => {
              setFlow(item);
              navigate(linkTo('flowDetails'))
            }}
            list={listaFlows} />
            }
          {label === "perfil" && <PerfilComponent cliente={cliente} />}
          {label === "integracoes" && <MainIntegracoes cliente={cliente} />}
          {label === "webhooks" && <MainWebhooks cliente={cliente} />}
          {label === "conectar-whatsapp" && <ConectarWhatsapp />}
          {verifyRules(user.rules, "agentes") && label === "usuarios" && <UsuariosComponent user={user} />}
          {verifyRules(user.rules, "respostas_rapidas") && label === "respostas-rapidas" && <RespostasRapidasComponent />}
          {label === "funil-vendas" && <FunilComponent user={user} cliente={cliente} />}
          {/* {label === "automacoes" && <MainAutomacoes onCallback={buscarListaAutomacoes} cliente={cliente} user={user} listaAutomacoes={listaAutomacoes} />} */}
          {label === "automacoes" && <Automacoes />}

          {label === "tags" && <TagsComponent />}
          {label === "setores" && <SetoresComponent />}
          {label === "flowDetails" && <FlowDetails flow={flow} user={user} cliente={cliente} />}
          {label === "channelDetails" && <CanaisDetails dispatch={dispatch} flow={flow} user={user} cliente={cliente} />}
          {label === "pageList" && <CanaisMetaPageList flow={flow} user={user} cliente={cliente} />}
          {label === "credenciais" && <CredenciaisComponent flow={flow} user={user} cliente={cliente} />}
          {label === "aiDatabases" && <BaseDadosAi user={user} cliente={cliente} />}
          {label === "aiDatabaseDetail" && <DocumentStoreDetails user={user} cliente={cliente} />}
          {label === "agentes-ia" && <AgentesIaComponent list={listaAgentesAi} flow={flow} user={user} cliente={cliente} />}
          {label === "agentDetail" && <ConfigAgentesIaComponent flow={flow} user={user} cliente={cliente} />}
          {label === "aiVoices" && <AiVozesComponent flow={flow} user={user} cliente={cliente} />}

        </div>


      </div>
    </div>
  )
}
