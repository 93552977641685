import { Box, Divider } from '@mui/material';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

// material
// redux
import {
  setRemoveRoomUnread
} from '../../../redux/slices/staff';
import { useDispatch, useSelector } from '../../../redux/store';
// routes
import "./components/Conversation.css";

// ----------------------------------------------------------------------

import BottomBar from '../staff/components/BottomBar';
import Messages from '../staff/components/Messages';
import TopBar from '../staff/components/TopBar';
import useAuth from 'src/hooks/useAuth';



export default function ChatWindow() {
  const dispatch = useDispatch();
  const { conversationKey } = useParams();
  const { staff } = useSelector((state) => state.staff);
  const {user} = useAuth()


 useEffect(() => {
    if (conversationKey) {
      dispatch(setRemoveRoomUnread(conversationKey));
    }
  }, [dispatch, conversationKey]);



  return (
    <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
      <TopBar chat={staff} />
      <Divider />
      <Box sx={{ flexGrow: 1, display: 'flex', overflow: 'hidden' }}>
        <Box sx={{ display: 'flex', flexGrow: 1, flexDirection: 'column' }}>
          <Messages />
          <Divider />
          <BottomBar user={user} chat={staff} />
        </Box>
      </Box>

    </Box>
  );
}
