import React, { useEffect, useState } from 'react';
import { useGlobal } from "reactn";

import "./Create.css";
import Scrollbar from 'src/components/Scrollbar';
import User from "./components/User";
import { Button } from '@mui/material';
import useAuth from 'src/hooks/useAuth';
import { useDispatch, useSelector } from 'react-redux';
import { setGroupTitle, setNewGroupUsers } from 'src/redux/slices/staff';


const EditGroup1 = ({ chat, continueEdit }) => {
    const { user } = useAuth()    /* eslint-disable no-unused-vars */
   
    const {newGroupUsers, search, groupTitle, searchResults} = useSelector((state) => state.staff);
    /* eslint-enable no-unused-vars */
    const searchText = search;
    const [error, setError] = useState(false);
    const dispatch = useDispatch();


    useEffect(() => {

        if (chat) {
            let participantes = chat.people;
            const reducePepople = participantes.map(item => item._id);
            dispatch(setNewGroupUsers(reducePepople)); 
            dispatch(setGroupTitle(chat.title));
        }
        

    }, []);

    const onSelect = id => {
        if (newGroupUsers.includes(id)) {
            dispatch(setNewGroupUsers(newGroupUsers.filter(u => u !== id))); 
            
        }
        else {
            setError(false);
            dispatch( setNewGroupUsers([...newGroupUsers, id])); 

           
        }
    };

    const searchResultsList = searchResults.map(user => (
        <User key={user._id} user={user} selected={newGroupUsers.includes(user._id)} onSelect={() => onSelect(user._id)} />
    )
    );

    const Notice = ({ text }) => <div className="notice">{text}</div>



    return (
        <div className="group-create">

            <Button sx={{ m: 2 }} variant="contained" onClick={continueEdit}>{newGroupUsers.length < 2 ? "Selecione para adicionar" : "Continuar"}</Button>
            <div className="selection-text error" hidden={newGroupUsers.length <= 1 || !error}>
                You must select some people!
            </div>
            <div className="selection-text" hidden={newGroupUsers.length <= 1}>
                {newGroupUsers.length - 1} selecionados - <a onClick={() => dispatch(setNewGroupUsers([user.id]))}>Limpar</a>
            </div>

            <Scrollbar sx={{ marginTop: 5, height: 'calc(100vh - 250px)' }}>
                {searchResultsList}
                {searchResults.length === 0 && <Notice text={`There are no users available for "${searchText}"`} />}
            </Scrollbar>

        </div>
    );
}

export default EditGroup1;
