
import { Icon } from '@iconify/react';
import { Box, Divider, Collapse, Typography, Stack, Avatar, Menu, MenuItem, FormControl, Paper } from '@mui/material';
import { LoadingButton } from '@mui/lab'
import PropTypes from 'prop-types';

import { useDispatch } from 'src/redux/store';

import Scrollbar from 'src/components/Scrollbar';
import { useSnackbar } from 'notistack';
import NegociosCard from 'src/sections/@dashboard/negocios/NegociosCard';
import NegocioCard from './NegocioCard';
import { useEffect, useState } from 'react';
import DrawerNegocio from 'src/sections/@dashboard/negocios/DrawerNegocio';
import v2_actionConfigCrm from 'src/actions/v2/crm/v2_actionConfigCrm';
import Iconify from 'src/components/Iconify';
import ModalAlerta from 'src/components/modal/ModalAlerta';
import InputStyle from 'src/components/InputStyle';
import { RowInput } from 'src/components/forms/RowInput';
import v2_actionCriarCrmLead from 'src/actions/v2/crm/v2_actionCriarCrmLead';
import { ButtonLoading } from 'src/@/components/use/ButtonLoading';
import { Button } from 'src/@/components/ui/button';

ChatInfoNegocios.propTypes = {
  isCollapse: PropTypes.bool,
  onCollapse: PropTypes.func
};


const MenuInserirFunil = ({ anchorEl, configCrmProps, onClose, open, cliente, user, onConfirm, loading }) => {

  const [listaContatos, setListaContatos] = useState([]);
  const [pipelineId, setPipelineId] = useState(null);
  const [pipelineStageId, setPipelineStageId] = useState(null);

  const [modalAlerta, setModalAlerta] = useState(null);
  const [modalAlertaMensagem, setModalAlertaMensagem] = useState(null);
  const [configCrm, setConfigCrm] = useState(configCrmProps?.configCrmProps);

  async function buscarConfigCrm(querys) {
    try {
      const result = await v2_actionConfigCrm(cliente._id, querys);
      if (result.data.data) {
        setConfigCrm(result.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
    >
      <ModalAlerta
        open={modalAlerta}
        mensagem={modalAlertaMensagem}
        onClose={() => {
          setModalAlerta(false);
          setModalAlertaMensagem(null);
        }}
      />
      <Paper sx={{ width: 300, height: 300, p: 2 }}>

        <Stack spacing={3} direction={"column"}>

          <Stack
            sx={{ width: { xs: '100%', md: '100%', mt: 4 } }}
            spacing={1}
            direction={"row"}
          >
            <Box sx={{ width: '100%' }}>
              <RowInput
                isRequired={true}
                label="Funil"
                input={<FormControl fullWidth sx={{ m: 1, minWidth: 200 }}>
                  <InputStyle
                    select
                    placeholder="Funil"
                    fullWidth
                    size="small"
                    value={pipelineId}
                    onChange={async (e) => {
                      setPipelineId(e.target.value)
                      let querys = `?pipeline_id=${e.target.value}`
                      buscarConfigCrm(querys);

                    }}
                  >
                    {configCrmProps?.pipelines.map((option) => (
                      <MenuItem key={option._id} value={option._id}>
                        {option.nome}
                      </MenuItem>
                    ))}
                  </InputStyle>
                </FormControl>}
              />
            </Box>
          </Stack>

          {pipelineId && (
            <Stack
              sx={{ width: { xs: '100%', md: '100%', mt: 4 } }}
              spacing={1}
              direction={"row"}
            >
              <Box sx={{ width: '100%' }}>
                <RowInput
                  isRequired={true}
                  label="Estagio do Funil"
                  input={<FormControl fullWidth sx={{ m: 1, minWidth: 200 }}>
                    <InputStyle
                      select
                      fullWidth
                      disabled={!pipelineId}
                      size="small"
                      value={pipelineStageId}
                      onChange={e => {
                        setPipelineStageId(e.target.value);

                      }}
                    >
                      {configCrm?.stages.map((option) => (
                        <MenuItem key={option._id} value={option._id}>
                          {option.nome}
                        </MenuItem>
                      ))}
                    </InputStyle>
                  </FormControl>}
                />
              </Box>
            </Stack>
          )}

          {pipelineId && (
            <Stack direction="row">
              <LoadingButton disabled={!pipelineStageId} onClick={() => onConfirm({ pipelineId, pipelineStageId })} loading={loading} variant="contained" >
                Incluir
              </LoadingButton>
            </Stack>

          )}



        </Stack>
      </Paper>

    </Menu>
  )
}

export default function ChatInfoNegocios({ chat, user, cliente, callbackRefresh, listaLeads, ...other }) {
  const { enqueueSnackbar } = useSnackbar();
  const [openDrawerNegocio, setOpenDrawerNegocio] = useState(false);
  const [lead, setLead] = useState(null);
  const [configCrm, setConfigCrm] = useState(null);
  const [leads, setLeads] = useState(listaLeads);
  const [anchorMenuInserirFunil, setAnchorMenuAlterarFunil] = useState(null);
  const [loading, setLoading] = useState(false);
  const openAnchorInserirFunil = Boolean(anchorMenuInserirFunil);


  async function buscarConfigCrm(querys) {
    try {
      const result = await v2_actionConfigCrm(cliente._id, querys);
      if (result.data.data) {
        let data = result.data.data;
        setConfigCrm(data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function handleOpen(lead) {
    setLead(lead)
    let querys = `?pipeline_id=${lead?.crm_pipeline_id}`
    buscarConfigCrm(querys)
    setOpenDrawerNegocio(true)
  }

  const criarNegocio = async (negocio) => {
    try {
      let data = {
        clienteId: cliente._id,
        usuarioId: user.id,
        scope: {
          nome: chat?.nome,
          qualificacao: 1,
          pipelineId: negocio.pipelineId,
          pipelineEstagioId: negocio.pipelineStageId,
          origemId: null,
          contatoId: chat?.contato_id,
          empresa_id: null,
          nome: chat?.nomeCliente,
          whatsapp_chat_id: chat._id,
        }
      }
      await v2_actionCriarCrmLead(data);
    } catch (error) {
      console.log('Error', error)
    }
  }


  useEffect(() => {
    setLeads(listaLeads)
  }, [lead, configCrm, listaLeads])

  return (
    <>
      {lead && (
        <DrawerNegocio
          configCrm={configCrm}
          open={openDrawerNegocio}
          lead={lead}
          onClose={() => setOpenDrawerNegocio(false)}
          callbackRefreshLeads={callbackRefresh}

        />
      )}

      <Box sx={{ mb: 2 }}>
        <Button
          aria-controls={openAnchorInserirFunil ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={openAnchorInserirFunil ? 'true' : undefined}
          onClick={async (event) => {
            buscarConfigCrm(null);
            setAnchorMenuAlterarFunil(event.currentTarget)
          }}
          className="h-8 w-full bg-slate-500"

        >
          <Iconify className="mr-1 mt-0.5" icon="mdi:plus" />
            Colocar no funil
   
        </Button>

        <MenuInserirFunil
          onConfirm={async (value) => {
            setLoading(true);
            try {
              let data = {
                pipelineId: value?.pipelineId,
                pipelineStageId: value?.pipelineStageId
              }
              await criarNegocio(data);
              setAnchorMenuAlterarFunil(null);
              enqueueSnackbar('Lead criado com sucesso', { variant: 'success', autoHideDuration: 3000, anchorOrigin: { vertical: 'bottom', horizontal: 'left' } });
              setTimeout(() => {
                callbackRefresh()
              }, 1500);
              setLoading(false);
            } catch (error) {
              setLoading(false);
              enqueueSnackbar('Erro ao inserir no funil', { variant: 'error', autoHideDuration: 3000, anchorOrigin: { vertical: 'bottom', horizontal: 'left' } });
            }
          }}
          configCrmProps={configCrm}
          cliente={cliente}
          user={user}
          open={openAnchorInserirFunil}
          anchorEl={anchorMenuInserirFunil}
          onClose={() => setAnchorMenuAlterarFunil(null)}
          loading={loading}
        />

      </Box>
      <Scrollbar sx={{ height: '70vh' }}>
        <Stack spacing={1} direction={"column"}>
          {listaLeads.length > 0 && listaLeads.map(lead => {
            return (
              <>
                {lead && <NegocioCard onOpen={() => {
                  handleOpen(lead)
                }} card={lead} />}
              </>

            )
          })}
        </Stack>
      </Scrollbar>
    </>
  );
}
