import moment from "moment";
import React from 'react';
import './Message.css';
import emojiRegex from 'emoji-regex';
import {FiDownloadCloud} from "react-icons/fi";
import ReactImageAppear from 'react-image-appear';

import Config from "../../../../config";

import {Card, Typography} from '@mui/material';
import useAuth from "src/hooks/useAuth";

const Message = ({message, previous, next, onOpen}) => {
    let { author, content, date } = message;

    const {user} = useAuth();

    if (!author) author = { firstName: 'Deleted', lastName: 'User' };
    if (previous && !previous.author) previous.author = { firstName: 'Deleted', lastName: 'User' };
    if (next && !next.author) next.author = { firstName: 'Deleted', lastName: 'User' };

    const isMine = user.id === author._id;

    let attachPrevious = false, attachNext = false;

    if (previous && Math.abs(moment(previous.date).diff(moment(date), 'minutos')) < 3  && author._id === previous.author._id)
        attachPrevious = true;
    if (next && Math.abs(moment(next.date).diff(moment(date), 'minutos')) < 3 && author._id === next.author._id)
        attachNext = true;


    const Details = ({side}) => {
        if (!attachNext) return (
            <div className={`message-details ${side}`}>
                {moment(date).format('DD/MM - HH:mm')}
            </div>
        );
        else return null;
    };


    
    const openInNewTab = (url) => {
        window.open(url, '_blank').focus();
       }

    const NameOrSpacer = () => {
        if (attachPrevious) return <div className="spacer"/>;
        else return (
            <div className="picture">
               <Typography sx={{fontSize:10, fontWeight:'bold'}}>{author.nomeFantasia}</Typography>
            </div>
        );
    };

    const noEmoji = content ? content.replace(emojiRegex(), '') : '';
   const isOnlyEmoji = !noEmoji.replace(/[\s\n]/gm, '');

    const getBubble = () => {
        if (attachPrevious || isOnlyEmoji)
            if (isMine) return ' right';
            else return ' left';
        if (isMine) return ' bubble-right right';
        else return ' bubble-left left';
    };

    const convertUrls = text => {
        // eslint-disable-next-line no-useless-escape
        const urlRegex =/(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
        return text.replace(urlRegex, function(url) {
            return '<a href="' + url + '" target="_blank">' + url + '</a>';
        });
    };

    const Content = () => {
        switch (message.type) {
            case 'image':
                return (
                    <ReactImageAppear
                        src={`${Config.urlWs || ''}/api/files/${message.content}`}
                        animationDuration="0.2s"
                        onClick={() => onOpen(message)}
                    />
                );
            case 'file':
                return (
                    <a href={() => openInNewTab(`${Config.urlWs || ''}/api/files/${message.content}`)}>
                        <div className="content-download">
                            <div className="content-file">
                                <div className="content-name">{message.file ? message.file.name : 'Arquivo'}</div>
                                {/* <div className="content-size">{message.file ? Math.round(message.file.size / 1024 / 1024 * 10 ) + 'Kb' : 'Size'}</div> */}
                            </div>
                            <div onClick={() => openInNewTab(`${Config.urlWs || ''}/api/files/${message.content}`)} className="content-icon"><FiDownloadCloud/></div>
                        </div>
                    </a>
                );
            default:
                return <div style={{fontSize:12, paddingTop:14, paddingBottom:14, wordBreak:'break-word', whiteSpace:'break-spaces'}} dangerouslySetInnerHTML={{__html: content && convertUrls(content)}} />
        }
    };

    const getBubbleClass = () => {
        if (message.type === 'image') return 'bubble-image';
        return isOnlyEmoji ? 'bubble' : 'bubble';
    };

    return (
        <div className={`message${isMine ? ' right' : ''}${attachPrevious ? ' attach-previous' : ''}${attachNext ? ' attach-next' : ''}`}>
           
            <div className={`content-x${isMine ? ' right' : ''}${attachPrevious ? ' attach-previous' : ''}${attachNext ? ' attach-next' : ''}`}>
                <Card className={`${getBubbleClass()}${getBubble()}${attachPrevious ? ' attach-previous' : ''}${attachNext ? ' attach-next' : ''}`} >
                <NameOrSpacer/>
                    <Content/>
                    {/* <Typography sx={{fontSize:8, fontWeight:'bold'}}>      {moment(date).format('DD/MM - HH:mm')}</Typography> */}
                </Card>
                <Details side={isMine ? 'right' : 'left'} />
            </div>
        </div>
    );
};

export default Message;
