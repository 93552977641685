import React, { useState } from 'react';

import "./Create.css";
import { getListaStaffsWithoutIsGroup, getStaff, setPanel, setGroupTitle } from 'src/redux/slices/staff';

import createGroup from "../../../../../../actions/chats/staff/createGroup";
import { PATH_CHAT } from '../../../../../../routes/paths';
import TopBar from "./components/TopBar";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Divider, Stack } from '@mui/material';
import { Input } from 'src/@/components/ui/input';
import { RowInput } from 'src/components/forms/RowInput';

import { Button } from 'src/@/components/ui/button';

const Panel = () => {
    const dispatch = useDispatch();
    const title = useSelector((state) => state.staff.groupTitle);
    const { newGroupUsers } = useSelector((state) => state.staff);
    const [nomeGrupo, setNomeGrupo] = useState('');
    const navigate = useNavigate();

    const create = async e => {

        try {

            console.log('nomeGrupo', { people: newGroupUsers, picture: "", title:nomeGrupo  })
    
            const res = await createGroup({ people: newGroupUsers, picture: null, title:nomeGrupo  });
            dispatch(setPanel('standard'))
            navigate(PATH_CHAT.staff.root);
            dispatch(getListaStaffsWithoutIsGroup())
            dispatch(getStaff(res.data._id));
            navigate(`${PATH_CHAT.staff.root}/${res.data._id}`);
        } catch (error) {
            console.log('error', error)
        }



    }

    return (

        <div className="p-1">
            <TopBar />

            <Divider className='mt-2 mb-2 border-dashed' />

            <div className='p-2'>

                <Stack direction="column" spacing={1}>
                    <RowInput
                        label="Nome do Grupo"
                        input={<Input
                            value={nomeGrupo}
                            onChange={e => setNomeGrupo(e.target.value)}
                        />}

                    />
                    <Button onClick={create}>Criar Grupo</Button>
                </Stack>

            </div>



        </div>
    );
}

export default Panel;
