// material
import { Box, Stack, Typography, Card } from '@mui/material';
import { styled } from '@mui/material/styles';
// routes

// hooks
import { nomeProjeto } from 'src/config';
import { LoginForm } from '../../components/authentication/login';
import Page from '../../components/Page';
import useAuth from '../../hooks/useAuth';
import Logo from 'src/components/Logo';

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  },
  display: 'flex',
  height: '100%',
  width: '100%',
  justifyContent: 'space-around',
  alignItems: 'center',
  background: 'radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(229,245,255,1) 50%, rgba(204,233,255,1) 100%);'
 //  background:theme.palette.grey[300]
}));

const SectionStyle = styled(Box)(({ theme }) => ({
  maxWidth: 600,
  zIndex: 1,
  display: 'flex',
  flexDirection: 'column',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled(Card)(({ theme }) => ({

  borderRadius: 10,
  background:'#fff',
  display: 'flex',
 // background: theme.palette.grey[200],
  flexDirection: 'column',
  padding: theme.spacing(7, 5)
}));

// ----------------------------------------------------------------------

export default function Login() {

  return (
    <div className="
    justify-center items-center
    flex min-h-screen w-full flex-col 
    bg-gradient-to-r from-blue-50 via-slate-50 to-violet-100
    ">
        <Stack direction="column" alignItems={'center'} justifyContent={'center'} spacing={6}>
        <Logo />
          <ContentStyle>
            <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
              <Box sx={{ flexGrow: 1, }}>
                <Typography variant="h6" textAlign={'center'}>
                  Login
                </Typography>
              </Box>
            </Stack>
            <LoginForm />
          </ContentStyle>
        </Stack>
    </div>
  );
}
