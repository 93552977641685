// material
import { Box, Card, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect } from 'react';

// redux
// hooks
import { useLocation, useParams } from 'react-router-dom';

// components
import LoadingScreen from 'src/components/LoadingScreen';
import { useSelector } from 'src/redux/store';

import { ChatSidebar, ChatWindow } from '../../components/_chats/staff';
import Page from '../../components/Page';
import { NAVBAR, nomeProjeto } from '../../config';
import { ChatInternoSidebar, ChatInternoWindow } from 'src/sections/@chats/chatInterno';
import EmptyContentChat from 'src/components/EmptyContentChat';


// ----------------------------------------------------------------------


export default function ChatInterno() {
  const theme = useTheme();

  const { conversationKey } = useParams();
  const { loading,  } = useSelector((state) => state.staff);
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  return (
      <Box sx={{
        height: `calc(100vh - ${NAVBAR.DASHBOARD_ITEM_HORIZONTAL_HEIGHT + 20}px)`,
        display: 'flex',
        overflow: 'hidden'
      ,
      }}
      className={isMobile ? 'mt-14' : ''}
      >
        {/* <ChatSidebar /> */}
        <ChatInternoSidebar />
        {conversationKey && loading ? <LoadingScreen /> : (conversationKey ? <ChatInternoWindow /> : <EmptyContentChat title="Selecione uma conversa" />)}
        {/* {conversationKey && loading ? <LoadingScreen /> : <ChatWindow />} */}
      </Box>

  );
}
