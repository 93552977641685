import axios from "axios";

import Config from "../../../../config";

const v2_actionListaByStatus = (clienteId, usuarioId, status, setorId, limit, offset) => {
    return axios({
        method: "get",
        url:`${Config.dev ? Config.url_dev : Config.urlBackend}/api/v2/chats/${clienteId}/${usuarioId}/lista-by-status?status=${status}&limit=${limit ? limit : 100}&setor_id=${setorId}${offset ? `&offset=${offset}` : ''}`,
        timeout: 30000 // Timeout de 10 segundos
    });
};

export default v2_actionListaByStatus;
