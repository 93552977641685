import React, { useState, useCallback, useRef } from 'react';
import "./BottomBar.css";
import {
  FiImage,
  FiPaperclip,
} from "react-icons/fi";
import { useDispatch } from "react-redux";
import { useGlobal } from "reactn";
import moment from "moment";
import { styled } from '@mui/material/styles';
import { Icon } from '@iconify/react';
import EmojiPicker from 'src/components/EmojiPicker';
import { UploadMultiFile } from 'src/components/upload';
import useResponsive from 'src/hooks/useResponsive';

//actions
import message from "../../../../actions/chats/staff/message";
import uploadFile from "../../../../actions/chats/staff/uploadFile";

import roundSend from '@iconify/icons-ic/round-send';
import { Box, CardContent, CardHeader, Button, Input, Divider, IconButton, InputAdornment, Stack, CircularProgress, Dialog, DialogContent, DialogActions } from '@mui/material';


import { setMessage, getListaStaffsWithoutIsGroup } from '../../../../redux/slices/staff';
import useAuth from 'src/hooks/useAuth';


//import Actions from "../../../constants/Actions";
//import getRooms from "../../../actions/getRooms";

const RootStyle = styled('div')(({ theme }) => ({
  minHeight: 56,
  display: 'flex',
  position: 'relative',
  alignItems: 'center',
  paddingLeft: theme.spacing(2)
}));


const BottomBar = ({ chat }) => {

  const ref = useRef(null);
   const { user } = useAuth();
  const [text, setText] = useState('');
  const { cliente } = useAuth();
  /* eslint-disable no-unused-vars */
  const [preview, setPreview] = useState(false);
  /* eslint-enable no-unused-vars */

  const [pictureRefs, addPictureRef] = useState([]);

  const isDesktop = useResponsive('up', 'md');
  const [loadingSendText, setLoadingSendText] = useState(false);

  //multifile
  const [multiFile, setMultiFile] = useState(false);
  const [multiImages, setMultiImages] = useState(false);
  const [loadingEnviarArquivos, setLoadingEnviarArquivos] = useState(false);
  const [files, setFiles] = useState([]);
  const [images, setImages] = useState([]);


  const dispatch = useDispatch();

  const handleKeyUp = (event) => {
    if (!event.shiftKey && event.key === 'Enter') {
      sendMessage();
    }
  };

  const onChangeText = e => {
    setText(e.target.value)
  }

  const handleDropMultiFile = useCallback(
    (acceptedFiles) => {
      for (let i = 0; i < acceptedFiles.length; i++) {
        if ((acceptedFiles[i].size / (1024 * 1024)) > 20) return alert('Arquivos excederam 20mb');
      }
      setFiles(acceptedFiles.map((file) => Object.assign(file, { preview: URL.createObjectURL(file) })));
      setMultiFile(true);
    },
    [setFiles]
  );

  const handleDropMultiImages = useCallback(
    (acceptedFiles) => {
      for (let i = 0; i < acceptedFiles.length; i++) {
        if ((acceptedFiles[i].size / (1024 * 1024)) > 20) return alert('Arquivos excederam 20mb');
      }
      setImages(acceptedFiles.map((file) => Object.assign(file, { preview: URL.createObjectURL(file) })));
      setMultiImages(true);
    },
    [setImages]
  );



  const handleRemoveAll = () => {
    setFiles([]);
    setImages([]);
  };

  const handleRemove = (file) => {
    const filteredItems = files.filter((_file) => _file !== file);
    setFiles(filteredItems);
    const filteredItemsImages = images.filter((_file) => _file !== file);
    setImages(filteredItemsImages);
  };

  function onChangeAnexo2() {
    if (files.length > 0) {
      setLoadingEnviarArquivos(true);
      sendFiles(files);
    }
  }

  function onChangeAnexo3() {
    if (images.length > 0) {
      setLoadingEnviarArquivos(true);
      sendImages(images);
    }
  }

  const handleOnPaste = (event) => {
    console.log({ event });
    const items = (event.clipboardData || event.originalEvent.clipboardData).items;

    console.log("items: ", JSON.stringify(items));

    let blob = null;

    for (let i = 0; i < items.length; i++) {
      if (items[i].type.indexOf("image") === 0) {
        blob = items[i].getAsFile();
      }
    }

    if (blob !== null) {
      if (blob.type === 'image/png') {
        console.log('blob', blob);
        setImages([Object.assign(blob, { preview: URL.createObjectURL(blob) })])
        setMultiImages(true);
      } else {
        console.log('blob', blob);
        setFiles([Object.assign(blob, { preview: URL.createObjectURL(blob) })])
        setMultiFile(true);
      }
    }
  };



  function sendMessage() {
    setLoadingSendText(true);
    if (text.length === 0)
      return;
    message({ clienteId: user.clienteId, roomID: chat._id, authorID: user.id, content: text, contentType: 'text' }).then(() => {
      dispatch(getListaStaffsWithoutIsGroup());
      setLoadingSendText(false);
    });
    let newMessage = {
      _id: Math.random(), author: { ...user, _id: user.id }, content: text, type: 'text', date: moment()
    };
    dispatch(setMessage(newMessage));
    setText('');
  }




  const sendImages = async images => {
    setLoadingSendText(true);
    for (let i = 0; i < images.length; i++) {
      if ((images[i].size / (1024 * 1024)) > 20) return alert('Arquivos excederam 20mb');
    }
    let tmpRefs = [];
    for (let i = 0; i < images.length; i++) {
      const file = images[i];
      tmpRefs.push(ref + i);

      const res = await uploadFile(file, ref + i)
      message({ clienteId: user.clienteId, roomID: chat._id, authorID: user.id, content: res.data.file.shieldedID, type: 'image', fileID: res.data.file._id });
      let newMessage = {
        _id: Math.random(), author: { ...user, _id: user.id }, content: res.data.file.shieldedID, type: 'image', date: moment(), file: res.data.file,
      };
      dispatch(setMessage(newMessage));
      setLoadingSendText(false);
      setLoadingEnviarArquivos(false);
      setMultiImages(false);
      setImages([]);
    }
    addPictureRef([...pictureRefs, ...tmpRefs]);
    dispatch(getListaStaffsWithoutIsGroup());
  };



  const sendFiles = async files => {
    setLoadingSendText(true);
    for (let i = 0; i < files.length; i++) {
      if ((files[i].size / (1024 * 1024)) > 20) return alert('Arquivos excederam 20mb');
    }
    let tmpRefs = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      tmpRefs.push(ref + i);

      console.log(file.type)

      const res = await uploadFile(file, ref + i)
      message({ clienteId: user.clienteId, roomID: chat._id, authorID: user.id, content: res.data.file.shieldedID, type: 'file', fileID: res.data.file._id });
      let newMessage = {
        _id: Math.random(), author: { ...user, _id: user.id }, content: res.data.file.shieldedID, type: 'file', date: moment(), file: res.data.file,
      };
      dispatch(setMessage(newMessage));
      setLoadingSendText(false);
      setLoadingEnviarArquivos(false);
      setMultiFile(false);
      setFiles([]);

    }
    addPictureRef([...pictureRefs, ...tmpRefs]);
    dispatch(getListaStaffsWithoutIsGroup());
  };

  return (

    <RootStyle>

      <Dialog fullWidth open={multiFile} onClose={() => setMultiFile(false)} >
        <DialogContent>
          <Box
            component="form"
            noValidate
            sx={{
              margin: 'auto',
              display: 'flex',
              width: 'fit-content',
              flexDirection: 'column'
            }}
          >
            <Stack spacing={2}>
              {/* <CardHeader title="Máximo 3 arquivos" /> */}
              <CardContent>
                <UploadMultiFile
                  isStaff={true}
                  cliente={cliente}
                  isDocument={false}
                  changeIsDocument={() => { }}
                  loadingEnviarArquivos={loadingEnviarArquivos}
                  showPreview={preview}
                  files={files}
                  onEnviarArquivos={onChangeAnexo2}
                  onDrop={handleDropMultiFile}
                  onRemove={handleRemove}
                  onRemoveAll={handleRemoveAll}
                />
              </CardContent>
            </Stack>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setMultiFile(false)} style={{ background: '#f03', color: "#fff" }} variant="outlined">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>


      <Dialog fullWidth open={multiImages} onClose={() => setMultiImages(false)} >
        <DialogContent>
          <Box
            component="form"
            noValidate
            sx={{
              margin: 'auto',
              display: 'flex',
              width: 'fit-content',
              flexDirection: 'column'
            }}
          >
            <Stack spacing={2}>
              <CardHeader title="Envie Imagens" />
              <CardContent>
                <UploadMultiFile
                  isStaff={true}
                  cliente={cliente}
                  isDocument={false}
                  changeIsDocument={() => { }}
                  loadingEnviarArquivos={loadingEnviarArquivos}
                  showPreview={preview}
                  files={images}
                  onEnviarArquivos={onChangeAnexo3}
                  onDrop={handleDropMultiImages}
                  onRemove={handleRemove}
                  onRemoveAll={handleRemoveAll}
                />
              </CardContent>
            </Stack>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setMultiImages(false)} style={{ background: '#f03', color: "#fff" }} variant="outlined">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>


      <Input
        disabled={loadingSendText ? true : false}
        fullWidth={isDesktop ? true : false}
        onChange={onChangeText}
        multiline={loadingSendText ? false : true}
        onPaste={handleOnPaste}
        maxRows={5}
        disableUnderline
        autoFocus
        onKeyPress={handleKeyUp}
        value={text}
        placeholder="Escreva uma mensagem..."
        startAdornment={
          <InputAdornment position="start">
            {isDesktop && <EmojiPicker value={text} setValue={setText} />}
            {user.isSendFileStaff && (
              <>
                {/* <input
                  className="file-input"
                  type="file"
                  ref={imageInput}
                  accept="image/*"
                  multiple={true}
                  onChange={e => sendImages(e.target.files)}
                /> */}
                {/* <div className="button image-attach" onClick={() => imageInput && imageInput.current && imageInput.current.click()}> */}
                <IconButton onClick={() => setMultiImages(true)}><FiImage size={isDesktop ? 15 : 15} /> </IconButton>
                {/* </div> */}
                {/* <div className="button attach" onClick={() => fileInput && fileInput.current && fileInput.current.click()}> */}
                {/* <input
                    className="file-input"
                    type="file"
                    ref={fileInput}
                    multiple={true}
                    // onChange={e => sendFiles(e.target.files)}
                    
                  /> */}
                <IconButton onClick={() => setMultiFile(true)}><FiPaperclip size={isDesktop ? 15 : 15} /></IconButton>

                {/* </div> */}
              </>

            )}

            {loadingSendText && <CircularProgress size={20} />}
          </InputAdornment>
        }

        sx={{ height: '100%' }}
      />

      <Divider orientation="vertical" flexItem />

      <IconButton color="primary" disabled={!text} onClick={sendMessage} sx={{ mx: 1 }}>
        <Icon icon={roundSend} width={24} height={24} />
      </IconButton>


    </RootStyle>


  );
}

export default BottomBar;
