
import { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
//mui
import { Box, Divider, IconButton, Stack, Icon } from '@mui/material';
// routes
import { PATH_DASHBOARD } from 'src/routes/paths';
// components
import ChatBottomBar from 'src/sections/@chats/chat/ChatBottomBar';
import ChatHeader from 'src/sections/@chats/chat/ChatHeader';
import ChatMessageList from './ChatMessageList';
import SkeletonChatWindow from 'src/components/skeleton/chats/SkeletonChatWindow';
import bgWhatsapp from 'src/assets/bgChat.svg';
import ChatInfoSidebar from 'src/sections/@chats/chat/ChatInfoSidebar';
//hooks
import useAuth from 'src/hooks/useAuth';
//redux
import { useDispatch, useSelector } from 'src/redux/store';
import { getChatInfoByChatId, getListaFunil, getMensagensWhatsapp, getMensagensWhatsappDb } from 'src/redux/slices/chat';
//actions
import v2_actionBuscarChatById from 'src/actions/v2/services/chats/v2_actionBuscarChatById';
import DrawerChatGpt from 'src/components/_chats/chatgpt/DrawerChatgpt';
import v1_actionEnviarGpt from 'src/actions/v1/chatgpt/v1_actionEnviarGpt';

import conversaMeta from 'src/mock/conversasMeta.json';
import ChatMessageListMeta from 'src/components/_chats/chat/meta_components/ChatMessageListMeta';
import { styled } from '@mui/material/styles';


const RootStyle = styled(Box)(({ theme }) => ({
  width: '100%',
  flexGrow: 1,
  display: 'flex'
}));

export default function ChatWindow({
  config,
  chatInfo,
  sidebarLeft,
  changeOpenSidebarLeft
}) {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const {
    loadingChat,
    mensagens,
    mensagensMeta,
    chatIsOnline,
    mensagensEnviar
  } = useSelector((state) => state.chat);
  const { isUserStandby } = useSelector((state) => state.user);
  const { onlineUsers } = useSelector((state) => state.staff);
  const { listaUsuariosTransferencia } = useSelector((state) => state.user);
  const { user, cliente } = useAuth();
  // console.log('mensagensEnviar', mensagensEnviar)
  const mensagensEnviarFilter = mensagensEnviar.filter(i => i.chat_id === `${chatInfo._id}@c.us`);
  const messagesMerge = mensagens.concat(mensagensEnviar);
  const messagesMergeMeta = mensagensMeta.concat(mensagensEnviar);

  const [drawerChatgpt, setDrawerChatgpt] = useState(false);

  // CHATGPT
  const [respostaGpt, setRespostaGpt] = useState('');
  const [respostasGpt, setRespostasGpt] = useState([]);
  const [loadingGpt, setLoadingGpt] = useState(false);
  const [conversacao, setConversacao] = useState([]);
  const [textInputDrawer, setTextInputDrawer] = useState('');
  const [isShowContextOptions, setIsShowContextOptions] = useState(false);
  const [openSidebar, setOpenSidebar] = useState(true);


  useEffect(() => {
    dispatch(getListaFunil(user.clienteId))
  }, [chatInfo, mensagensEnviar]);

  const handleEnviarGpt = async (mensagens) => {
    setDrawerChatgpt(true);
    setIsShowContextOptions(false);

    let arrayFilter = mensagens.filter(i => i.type === "chat")

    const updatedArr = arrayFilter.map(item => {
      let newObj = {
        body: item.body.replace(/\*\_.*?\_\*/g, ''),
        type: item.type,
        fromMe: item.fromMe
      }
      return newObj;
    });

    setLoadingGpt(true);

    try {
      let data = updatedArr;
      let querys = `?usuario_id=${user.id}&cliente_id=${user.clienteId}`;
      const response_chatgpt = await v1_actionEnviarGpt(data, querys);
      if (response_chatgpt.data.data.length > 0) {
        let resposta = response_chatgpt.data.data[0].message.content;
        let newObj = {
          body: resposta,
          type: 'chat',
          fromMe: false,
          fromGpt: true
        }

        setConversacao(conversacao => [...conversacao, newObj]);
        setLoadingGpt(false);
        setLoadingGpt(false);
      }

    } catch (error) {
      setLoadingGpt(false);
    }
  }

  const onStartConversationGpt = async (inputText, isGatilho, nomeGatilho) => {
    try {
      let newObj = {
        body: inputText,
        type: 'chat',
        fromMe: true
      }

      let newObjPush = {
        body: nomeGatilho,
        type: 'chat',
        fromMe: true
      }

      setConversacao(conversacao => [...conversacao, newObjPush]);
      setLoadingGpt(true);

      let data = [newObj];

      let querys = `?usuario_id=${user.id}&cliente_id=${user.clienteId}&ignore_instruction=1`;
      const response_chatgpt = await v1_actionEnviarGpt(data, querys);
      if (response_chatgpt.data.data.length > 0) {
        let resposta = response_chatgpt.data.data[0].message.content;
        let newObjResponse = {
          body: resposta,
          type: 'chat',
          fromMe: false,
          fromGpt: true
        }

        setConversacao(conversacao => [...conversacao, newObjResponse]);
        setLoadingGpt(false);
        setTextInputDrawer('');
      }
    } catch (error) {
      console.log('error', error)
      setLoadingGpt(false);
    }
  }


  const onStartConversationGptFree = async () => {

    try {
      let newObj = {
        body: textInputDrawer,
        type: 'chat',
        fromMe: true
      }

      setConversacao(conversacao => [...conversacao, newObj]);
      setLoadingGpt(true);

      let data = [newObj];

      let querys = `?usuario_id=${user.id}&cliente_id=${user.clienteId}&ignore_instruction=1`;
      const response_chatgpt = await v1_actionEnviarGpt(data, querys);
      if (response_chatgpt.data.data.length > 0) {
        let resposta = response_chatgpt.data.data[0].message.content;
        let newObj = {
          body: resposta,
          type: 'chat',
          fromMe: false,
          fromGpt: true
        }

        setConversacao(conversacao => [...conversacao, newObj]);
        setLoadingGpt(false);
        setTextInputDrawer('');
      }
    } catch (error) {
      console.log('error', error)
      setLoadingGpt(false);
    }
  }

  const handleCallbackGpt = async (value) => {
    if (!value) return;
    setDrawerChatgpt(true);
    onStartConversationGpt(('Texto: ' + value.message + ' . Instrução: ' + value.input).replace(/\*\_.*?\_\*|\n/g, ''), true, value.nome)
  }
  const CHAT_STATUS = {
    ATTENDING: "Atendendo",
    FINISHED: "Finalizado",
  };

  return (
    <RootStyle>
      {drawerChatgpt && (
        <DrawerChatGpt
          open={drawerChatgpt}
          user={user}
          onClear={() => {
            setRespostaGpt('');
            setRespostasGpt([]);
            setConversacao([]);
            setDrawerChatgpt(false)
          }}
          loadingGpt={loadingGpt}
          conversacao={conversacao}
          // changeConversacao={(conversacao, value) => console.log('conversacao', conversacao, value)}
          mensagens={mensagens}
          onClose={() => setDrawerChatgpt(false)}
          resposta={respostaGpt}
          respostas={respostasGpt}
          onEnviarGpt={() => handleEnviarGpt(mensagens)}
          onGerarResposta={handleEnviarGpt}
          onStartConversationGpt={() => onStartConversationGptFree()}
          isShowContextOptions={isShowContextOptions}
          textInputDrawer={textInputDrawer}
          changeText={(e) => setTextInputDrawer(e.target.value)}
          choice={value => {
            callbackSendTextBottomBar(value.body)
            setDrawerChatgpt(false)
            setRespostaGpt('')
          }}
        />
      )}



      <Stack sx={{ width: '100%' }} direction={"row"}>
        <Box
          className="bg-slate-200"
          sx={{
            width: '100%'
          }}>
          {!isUserStandby && (
            <ChatHeader
              openSidebar={openSidebar}
              sidebarLeft={sidebarLeft}
              changeOpenSidebarLeft={() => {

                setOpenSidebar(false)
              }}
              changeOpenSidebar={() => {
                changeOpenSidebarLeft()
                setOpenSidebar(!openSidebar)
              }}
              cliente={cliente}
              user={user}
              chatIsOnline={chatIsOnline}
              onlineUsers={onlineUsers}
              chat={chatInfo}
              listaUsuariosTransferencia={listaUsuariosTransferencia}
            />
          )}

          <Divider sx={{ borderColor: '#ccd9e3' }} />

          <Box
            className="bg-slate-200"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              overflow: 'hidden',
              maxHeight: 'calc(100vh - 130px)',
              // height:{
              //   xs:'calc(60vh - 56px)',
              //   sm:'calc(70vh - 64px)',
              //   md:'calc(75vh - 64px)',
              //   lg:'calc(75vh - 64px)',
              // }
            }}
          >

            {/* {chatInfo.canal === 'instagram' && <ChatMessageListMeta conversa={messagesMergeMeta} canal={chatInfo.canal} />} */}

            {chatInfo.canal !== 'instagram' && <ChatMessageList
              callbackGpt={handleCallbackGpt}
              config={config}
              user={user}
              loadingChat={loadingChat}
              cliente={cliente}
              chat={chatInfo}
              conversa={messagesMerge}
            />
            }

            <div className='pb-3'>
              {!isUserStandby && chatInfo.statusRoom === CHAT_STATUS.ATTENDING && <ChatBottomBar
                cliente={cliente}
                onOpenDrawerGpt={() => {
                  setIsShowContextOptions(true);
                  setDrawerChatgpt(true)
                }}
                user={user}
                mensagens={messagesMerge}
                disabled={pathname === PATH_DASHBOARD.chat.new}
                chat={chatInfo}
              />}
            </div>

          </Box>

        </Box>


        <div className={`overflow-visible bg-white ${openSidebar ? 'min-w-[370px]' : 'w-0'}`}>
          <ChatInfoSidebar chat={chatInfo} />
        </div>

      </Stack>
    </RootStyle>
  );
}


