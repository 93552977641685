import React, { useEffect, useState, useRef } from 'react';
// import "./TopBar.css";
import { FiArrowLeft } from 'react-icons/fi';
import { MdOutlineGroupAdd, MdClear } from 'react-icons/md';
import EditPanel1 from 'src/components/_chats/staff/components/GroupEdit/Create';
import EditPanel2 from 'src/components/_chats/staff/components/GroupEdit/Create2';
import { useSelector } from 'src/redux/store';

// import Picture from "./Picture";
import { Badge, Drawer, Box, IconButton, Typography } from "@mui/material";
import moment from "moment";
import useAuth from 'src/hooks/useAuth';
import { setPanel } from 'src/redux/slices/staff';
import { useDispatch } from 'react-redux';

moment.locale('pt-br');

const ChatInternoHeaderDetail = ({ back, loading, chat }) => {
    const dispatch = useDispatch()
    const { user } = useAuth();
    const { room, onlineUsers, panel, newGroupUsers } = useSelector((state) => state.staff);
    const [open, setOpen] = React.useState(false);


    let other = {};

    chat.people && chat.people.forEach(person => {
        if (user.id !== person._id) other = person;
    });

    if (!other.firstName) {
        other = { ...other, firstName: '', lastName: '' };
    }

    const title = (chat.isGroup ? chat.title : `${other.firstName} ${other.lastName}`).substr(0, 22);

    useEffect(() => {
        dispatch(setPanel('editGroup'))
    }, []);




    const Online = ({ other }) => {

        const prevStatusRef = useRef(false);
        const [lastOnline, setLastOnline] = useState(null);

        useEffect(() => {

            if (prevStatusRef.current && onlineUsers.filter(u => u.id === other._id).length === 0) setLastOnline(moment().valueOf());
            prevStatusRef.current = onlineUsers.filter(u => u.id === other._id).length > 0;
        }, [onlineUsers]);

        if (onlineUsers.filter(u => u.id === other._id && u.status === 'busy').length > 0) return 'busy';
        if (onlineUsers.filter(u => u.id === other._id && u.status === 'online').length > 0) return 'online';
        if (onlineUsers.filter(u => u.id === other._id && u.status === 'away').length > 0) return 'away';
        if (lastOnline) return moment(lastOnline).format('dd/MM - h:mm A');
        return `ultima vizualização: ${other.lastOnline ? moment(other.lastOnline).format('DD/MM - HH:MM') : ''}`;
    };

    const getStatus = () => {
        if (room) { if (room.isGroup) return null }

        if (onlineUsers.filter(u => u.id === other._id && u.status === 'busy').length > 0) return 'ocupado';
        if (onlineUsers.filter(u => u.id === other._id && u.status === 'online').length > 0) return 'online';
        if (onlineUsers.filter(u => u.id === other._id && u.status === 'away').length > 0) return 'away';
        //return null;
    };

    const getParticipantes = () => {
        if (chat) {
            if (chat.people) {
                const participantes = chat.people;
                const listaParticipantes = participantes.map(item => item.firstName)
                const lista = listaParticipantes.toString();
                return lista;

            }

        }
    }

    const getPanel = () => {
        switch (panel) {
            case 'editGroup':
                return <EditPanel1 set chat={chat} continueEdit={handleContinueEdit} />
            case 'editGroup2':
                return <EditPanel2 chat={chat} />
            default:
                return <EditPanel1 chat={chat} continueEdit={handleContinueEdit} />;
        }
    }

    const handleContinueEdit = e => {
        e.preventDefault();
        if (newGroupUsers.length > 1) dispatch(setPanel('editGroup2'));

    }

    return (
        <>

            <Drawer anchor={'right'} open={open} onClose={() => setOpen(false)}>
                <Box className="min-w-[500px]">
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 3, mt: 3 }}>
                        <IconButton onClick={() => setOpen(false)}><MdClear /></IconButton>
                        <Typography>Editar Grupo</Typography>
                    </Box>

                    {getPanel()}
                </Box>
            </Drawer>


            <div className="flex h-72 p-3 bg-slate-100">
                <div className="text">
                    <div className="text-md font-bold">{title}{title.length > 22 && '...'}</div>
                    {/* <div className="message">{chat.isGroup ? `Group: ${chat.people.length} members` : <Online other={other} />}</div> */}
                    <div className="text-[10px]">{chat.isGroup && `${chat.people.length} participantes`} - {chat.isGroup ? getParticipantes() : <Online other={other} />}</div>
                </div>
                {chat && chat.isGroup === true && user.id === chat.people[0]._id && <IconButton onClick={() => setOpen(true)}> <MdOutlineGroupAdd /> </IconButton>}
            </div>
        </>
    );
}

export default ChatInternoHeaderDetail;
