import React from 'react';

const Automacoes = () => {
  return (
    <div className="flex h-screen bg-gray-900 text-white">
      {/* Left Panel */}
      <div className="w-2/3 p-6">
        <div className="border-2 border-blue-500 p-4 rounded-lg bg-gray-800 flex flex-col items-center">
          <div className="flex items-center space-x-3">
            <div className="bg-green-600 p-2 rounded-full">
              {/* Play Icon (Replace with your icon component if using icons) */}
              <svg className="w-6 h-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14.752 11.168l-6.596 3.845A1 1 0 017 14.153V9.847a1 1 0 011.156-.987l6.596 3.845a1 1 0 010 1.702z" />
              </svg>
            </div>
            <div>
              <p className="text-lg">Quando: Adicionar acionador</p>
              <p className="text-sm text-gray-400">Um evento que aciona a execução da regra</p>
            </div>
          </div>
          <button className="mt-4 bg-gray-700 p-2 rounded-lg w-full text-center">
            + Adicionar componente
          </button>
        </div>
      </div>

      {/* Right Panel */}
      <div className="w-1/3 p-6">
        {/* Search bar and Tabs */}
        <div className="mb-4">
          <input
            type="text"
            placeholder="Pesquisar acionadores"
            className="w-full p-2 mb-4 rounded bg-gray-800 text-white placeholder-gray-500 focus:outline-none"
          />
          <div className="flex space-x-3">
            <button className="bg-blue-700 px-4 py-2 rounded-lg">Todos os acionadores</button>
            <button className="bg-gray-700 px-4 py-2 rounded-lg">Acionadores do item</button>
            <button className="bg-gray-700 px-4 py-2 rounded-lg">DevOps</button>
            <button className="bg-gray-700 px-4 py-2 rounded-lg">Compass</button>
            {/* Additional tabs... */}
          </div>
        </div>

        {/* Recommended Section */}
        <div className="space-y-4">
          <h3 className="text-lg font-semibold">Recomendado</h3>
          <div className="grid grid-cols-2 gap-4">
            <div className="bg-gray-800 p-4 rounded-lg">
              <p className="text-base">Comentário do item excluído</p>
              <span className="bg-green-600 text-xs px-2 py-1 rounded">ITEM</span>
            </div>
            <div className="bg-gray-800 p-4 rounded-lg">
              <p className="text-base">Componente criado no Compass</p>
              <span className="bg-purple-600 text-xs px-2 py-1 rounded">NOVO</span>
            </div>
            {/* Additional recommended items... */}
          </div>

          {/* Other sections like 'Ações rápidas', 'Jira Service Management', etc. */}
          <h3 className="text-lg font-semibold">Acionadores do item</h3>
          <div className="grid grid-cols-2 gap-4">
            <div className="bg-gray-800 p-4 rounded-lg">
              <p className="text-base">Comentário do item excluído</p>
              <span className="bg-green-600 text-xs px-2 py-1 rounded">ITEM</span>
            </div>
            <div className="bg-gray-800 p-4 rounded-lg">
              <p className="text-base">Versão excluída</p>
              <span className="bg-purple-600 text-xs px-2 py-1 rounded">NOVO</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Automacoes;
